import styled from "styled-components";
import SwitchTab from "../../components/Tab/SwitchTab";
import theme from "../../theme";
import { breakpoint, WindowSize } from "../../utils/responsive";
import MyTypography from "../../components/Typography";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DeviceCard from "../../components/ProductCard/DeviceCard";
import PlanCard from "../../components/ProductCard/PlanCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Space from "../../components/Space";
import { scrollToHash } from "../../utils/common";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { gaSelectItem, gaViewItemList } from "../../utils/ga";
import { gtmSelectItem, gtmViewItemList } from "../../utils/gtm";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const PlanList = ({ plans, devices, companyId }) => {
  const { t } = useTranslation("home");
  let navigate = useNavigate();
  const isMobile = WindowSize() === "mobile";
  const [selected, setSelected] = useState(undefined);
  const TABS = [
    // { label: t("mobile"), value: "mobile" },
    { label: t("apple"), value: "apple" },
    { label: t("android"), value: "android" },
    { label: t("accessories"), value: "accessoriesGaming" },
    { label: t("lifestyle"), value: "homeLifestyle" },
  ];
  let query = useQuery();

  useLayoutEffect(() => {
    if (query.get("service")) {
      let service = query.get("service");

      if (TABS.map((tab) => tab.value).includes(service)) {
        scrollToHash("planList");
        setSelected(service);
      }
    } else {
      setSelected(TABS?.[0]?.value);
    }
  }, [query]);

  useEffect(() => {
    if (selected) {
      const selectedProductList = devices?.[selected] || [];
      const listInfo = { 
        item_list_id: companyId || "NA", 
        item_list_name: selected,   // TODO: confirm use selected or company name
      };

      gaViewItemList(selectedProductList, listInfo);
      gtmViewItemList(selectedProductList, listInfo);
    }
  }, [selected])

  const handleClickItem = (item, type, pos) => {
    const gaPayload = { ...item, sort: pos };
    gaSelectItem(gaPayload);
    gtmSelectItem(gaPayload);

    navigate(`/${type}/${item?._id}`)
  }

  return (
    <Container>
      <Wrapper>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: isMobile ? "0 40px" : undefined,
            textAlign: "center",
          }}
        >
          <MyTypography
            bold
            color={theme.black}
            fontSize="38"
            mFontSize="30"
            // onClick={gotoDetail}
          >
            {t("needDifferentPlan")}
          </MyTypography>
          <Space size="10px" />
          <MyTypography id="planList">{t("whateverYourNeed")}</MyTypography>
          <Space size="20px" />
        </div> */}
        <div id="planList"></div>
        <SwitchTab
          style={{ marginBottom: 60 }}
          data={TABS}
          setSelected={(index) => setSelected(TABS?.[index]?.value)}
          selected={TABS.findIndex((tab) => tab.value === selected)}
          navigate={navigate}
        />
        <Grid container justifyContent="center" columnSpacing={7} rowSpacing={'36px'}>
          {selected === 0 &&
            plans &&
            plans?.map((plan, idx) => {
              return (
                <PlanCardContainer item>
                  <PlanCard
                    type="animation"
                    data={plan}
                    handleClick={() => handleClickItem(plan, 'mobile', idx)}
                  />
                </PlanCardContainer>
              );
            })}
          {/* {selected === 1 &&
            recommendList &&
            recommendList?.map((item) => {
              return (
                <PlanCardContainer item key={`recommend-item-${item?._id}`}>
                  {
                    item?.itemType === "plan" ? (
                      <PlanCard
                        type="animation"
                        data={item}
                        handleClick={() => navigate(`/mobile/${item?._id}`)}
                      />
                    ) : (
                      <DeviceCard
                        data={item}
                        handleClick={() => navigate(`/device/${item?._id}`)}
                      />
                    )
                  }
                </PlanCardContainer>
              )
            })} */}
          {TABS.map((tab) => tab?.value).includes(selected) &&
            devices && 
            devices?.[selected]?.length > 0 &&
            devices?.[selected]?.map((device, idx) => {
              return (
                <PlanCardContainer item key={`device-item-${device?._id}`}>
                  <DeviceCard
                    data={device}
                    handleClick={() => handleClickItem(device, 'device', idx)}
                  />
                </PlanCardContainer>
              );
            })}
        </Grid>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px 0px 100px 0px;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${theme.maxWidth.desktop};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PlanCardContainer = styled(Grid)`
  display: flex;
  align-items: end;

  @media ${breakpoint.mobile} {
    && {
      margin-bottom: 60px;
    }
  }

  @media ${breakpoint.desktop} {
    // && {
    //   margin-right: 75px;
    // }
  }
`;

export default PlanList;
