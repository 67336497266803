import { useTranslation } from "react-i18next";
import Form from "./Form";
import ContactInformation from "./ContactInformation"
import DeliveryInformation from "./DeliveryInformation"
import { useFormikContext } from "formik";

const DeliveryContactInformation = (props) => {
    const {
        viewOnly,
        setSkipValidate,
        configData,
        calendarData,
        allowDelivery,
        allowPickup,
    } = props;

    const { values } = useFormikContext();

    const { t } = useTranslation(["checkout", "error"]);

    return (
        <Form title={t("deliveryMethods")}>
            <DeliveryInformation
                viewOnly={viewOnly}
                setSkipValidate={setSkipValidate}
                configData={configData}
                calendarData={calendarData}
                allowDelivery={allowDelivery}
                allowPickup={allowPickup}
            />
            <ContactInformation viewOnly={viewOnly} isSameAsCustomerInfo={values?.contact_isSameAsCustomerInfo} />
        </Form>
    )
}

export default DeliveryContactInformation;