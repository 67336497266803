import { useState } from "react";
import RemarkIcon from "../../assets/ic-remark.svg"
import styled from "styled-components";
import Typography from "../Typography";
import SimpleDialog from "../CommonDialog/SimpleDialog";
import MyTypography from "../Typography";

const Container = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 15px;
  width: fit-content;
  justify-content: center;
  align-items: center;
`;

const InfoButton = ({ label, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Container
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <img src={RemarkIcon} style={{ marginRight: 10 }} />
        <Typography color="dirtyYellow">{label}</Typography>
      </Container>

      {isOpen && (
        <SimpleDialog isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
          <MyTypography>{content}</MyTypography>
        </SimpleDialog>
      )}
    </div>
  );
};

export default InfoButton;
