import { useMemo, useState } from "react";
import styled from "styled-components";
import { Grid, Divider } from "@mui/material";
import PlanCard from "../components/ProductCard/PlanCard";
import PlanDetailCard from "../components/ProductCard/PlanDetailCard";
import PageHeader from "../components/Header";
import Typography from "../components/Typography";
import StepGroup from "../components/StepGroup";
import Form from "../pageComponents/mobile/Form";
import InfoButton from "../components/Plan/InfoButton";
import { useTranslation } from "react-i18next";
import { ResponsiveUI, breakpoint } from "../utils/responsive";
import { fetchCartItem, fetchPlanById, addToCart, updateCartItem } from "../apis";
import { useQueries } from "@tanstack/react-query";
import LoadingIndicator from "../components/LoadingIndicator";
import { useParams, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import Space from "../components/Space";
import { WindowSize } from "../utils/responsive";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(Grid)`
  && {
    height: 100%;
  }
`;
const FormContainer = styled(Grid)``;
const FormHeader = styled(Grid)`
  && {
    padding: 30px 40px;
  }
`;

const PageContainer = styled.div`
  @media ${breakpoint.desktop} {
    margin: 40px 0px;
  }

  @media ${breakpoint.mobile} {
    margin: 40px 0px;
  }
`;

const PlanCardContainer = styled.div`
  @media ${breakpoint.desktop} {
    margin-top: -100px;
    margin-left: 100px;
    margin-right: -50px;
  }
  @media ${breakpoint.mobile} {
    margin-top: -70px;
    display: flex;
    justify-content: center;
  }
`;

const STEP_LIST = [
  {
    id: 1,
    isActive: true,
    isCompleted: false,
  },
  {
    id: 2,
    isActive: false,
    isCompleted: false,
  },
  {
    id: 3,
    isActive: false,
    isCompleted: false,
  },
  {
    id: 4,
    isActive: false,
    isCompleted: true,
  },
];

const Mobile = () => {
  const { t } = useTranslation("mobile");
  const [steps, setSteps] = useState(STEP_LIST);
  const { id } = useParams();
  const isMobile = WindowSize() === "mobile";
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
	const editId = searchParams.get('editId');

  const [planData, cartPayload] = useQueries({
    queries: [
      {
        queryKey: ["plan", id],
        queryFn: () => fetchPlanById(id),
      },
      {
				queryKey: ['cartId', editId],
				queryFn: () => {
					if (!editId) return;
					return fetchCartItem(editId);
				},
			}
    ],
  });

  const handleAddToCart = async (payload) => {
    const result = editId ? await updateCartItem(editId, payload) : await addToCart(payload);
    if (result && result?.msg === 'success') navigate("/shoppingcart");
    else console.log('==error', result)
  }

  if (planData?.isLoading) {
    return <LoadingIndicator />;
  }

  if (planData?.isError || planData?.data?.result?.err) {
    // console.log("error - ", planData?.isError);
    return <Navigate to="/404" />;
  }

  return (
    <div>
      <PageHeader>{t("mobile")}</PageHeader>
      <PageContainer>
        <Grid container direction="column">
          <Content container direction="row">
            <Grid item xs={12} md="auto">
              <PlanCardContainer>
                <PlanDetailCard
                  style={{ position: "relative", top: -60 }}
                  data={planData?.data?.data}
                />
              </PlanCardContainer>
            </Grid>
            <Grid item xs>
              <FormContainer container direction="column">
                <ResponsiveUI
                  mobile={<Space size="100px" />}
                  desktop={<div />}
                />
                <StepGroup data={steps} />
                <Divider />
                <Form
                  setSteps={setSteps}
                  steps={steps}
                  data={planData?.data?.data}
                  selected={cartPayload?.data?.data}
                  onSubmit={handleAddToCart}
                />
              </FormContainer>
            </Grid>
          </Content>
          <div style={{ margin: isMobile ? "50px 30px" : "50px 100px" }}>
            <InfoButton
              label={t("termsAndConditions")}
              title={t("termsAndConditions")}
              content={"hello tnc"}
            />
            <InfoButton
              label={t("remark")}
              title={t("remark")}
              content={"hello remark"}
            />
          </div>
        </Grid>
      </PageContainer>
    </div>
  );
};

export default Mobile;
