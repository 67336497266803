import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ResponsiveUI, WindowSize } from "../../utils/responsive";
import IconArrowRight from "../../assets/ic-bannerArrowRight.svg";
import IconArrowLeft from "../../assets/ic-bannerArrowLeft.svg"
import _ from "lodash";
import { gaViewPromotion } from "../../utils/ga";
import { gtmViewPromotion } from "../../utils/gtm";
import { useTranslation } from "react-i18next";

export default function SimpleSlider({ lang="en", data, onClickBanner }) {
  const isMobile = WindowSize() === "mobile";

  const { i18n } = useTranslation();

  var settings = {
    dots: isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,

    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  function CustomPrevArrow({ onClick }) {
    const handleOnClick = () => {
      onClick();
    };

    // TODO - hide page dot
    return (
      <ResponsiveUI
        desktop={
          <PrevArrow
            onClick={handleOnClick}
            //   isFirstSlide={currentSlide == 0}
          >
            <img src={IconArrowLeft} />
          </PrevArrow>
        }
        mobile={<div />}
      />
    );
  }

  function CustomNextArrow({ onClick }) {
    // const slideCount = data?.length;
    const handleOnClick = () => {
      onClick();
    };
    return (
      <ResponsiveUI
        desktop={
          <NextArrow
            onClick={handleOnClick}
            // isLastSlide={currentSlide == slideCount - 1}
          >
            <img src={IconArrowRight} />
          </NextArrow>
        }
        mobile={<div />}
      />
    );
  }

  const handleClickBanner = (item) => {
    // TODO: confirm if no destination page, onClick go to '/' or do nothing
    const destination = item?.urlPath?.[lang] || null;
    if (!destination) return;
    
    onClickBanner && onClickBanner(item);
    window.open(destination, "_blank");
  }

  const handleSendGAEvent = (bannerIndex) => {
    const item = data?.[bannerIndex];

    const gaPayload = { 
      promotion_id: item?._id || "NA",
      destination_page_url: item?.urlPath?.[i18n.language] || "NA" 
    };

    gaViewPromotion(gaPayload);
    gtmViewPromotion(gaPayload);
  }

  return (
    <StyledSlider 
      {...settings}
      onInit={() => handleSendGAEvent(0)}
      beforeChange={(currentSlide) => {
        const nextSlideIndex = currentSlide === data.length - 2 ? 0 : currentSlide + 1;
        handleSendGAEvent(nextSlideIndex)
      }}
    >
      {data &&
        data.map((item, idx) => {
          const itemClickable = !_.isEmpty(item?.urlPath);

          if (item.animationType === "static") {
            const imageUrl = {
              en: isMobile && item?.mobileImageUrlEn ? item?.mobileImageUrlEn : item?.desktopImageUrlEn,
              zh: isMobile && item?.mobileImageUrlZh ? item?.mobileImageUrlZh : item?.desktopImageUrlZh,
            }
            return (
              <div onClick={() => itemClickable && handleClickBanner(item)} key={`static-banner-${idx}`}>
                <img alt={`Banner ${idx}`} src={imageUrl?.[lang]} clickable={itemClickable} />
              </div>
            )
          }
          
          // TODO: other animation type
          else return (<div></div>)
        })}
    </StyledSlider>
  );
}

const NextArrow = styled.div`
  top: 50%;
  right: 0;
  position: absolute;
  margin-right: 15px;
  z-index: 100;
  cursor: pointer;
  height: 55px;
  width: 55px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  //   display: ${(props) => (props.isLastSlide ? "none" : "block")};
`;

const PrevArrow = styled(NextArrow)`
  left: 0;
  margin-left: 15px;
`;

const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 5%;
  }

  .slick-dots li button:before {
    color: #fff;
    font-size: 9px;
  }

  .slick-dots li.slick-active button:before {
    color: #fedd05;
  }

  .slick-slide img {
    display: block;
    width: 100%;
    height: auto;
    cursor: ${props => props?.clickable ? "pointer" : "default"};
  }
`;
