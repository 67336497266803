import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const getAccessToken = async () => {
  return localStorage.getItem("accessToken");
};

const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

axios.interceptors.request.use(async (request) => {
  try {
    const token = await getAccessToken();
    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  } catch (e) {
    return request;
  }
});

const refreshAuthLogic = async (failedRequest) => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const result = await refreshAccessToken({ refresh_token: refreshToken });

    if (result && result.msg === "success") {
      localStorage.setItem("accessToken", result.data.accessToken);
      localStorage.setItem("refreshToken", result.data.refreshToken);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + result.data.accessToken;
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    console.log("refresh user error - ", e);
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    window.location = "/";
  }
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);

const request = (param) => {
  return axios({
    ...param,
  })
    .then((res) => res.data)
    .catch(async (error) => {
      console.log("debug", error);
      return error?.response?.data || error?.response?.status;
    });
};

const fetchHomeBannerList = (companyId) => {
  if (companyId) {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/banner?page=1&limit=20&company=${companyId}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/banner?page=1&limit=20`,
    });
  }
};

const fetchRecommendList = (companyId) => {
  if (companyId) {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/recommend?&company=${companyId}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/recommend`,
    });
  }
};

const fetchPlan = (companyId) => {
  if (companyId) {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/plan?&company=${companyId}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/plan`,
    });
  }
};

const fetchPlanById = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/plan/${id}`,
  });
};

const fetchDevice = (companyId) => {
  if (companyId) {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/product?&company=${companyId}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/product`,
    });
  }
};

const fetchDeviceProduct = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/product/${id}`,
  });
};

const addToCart = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/cart`,
    data: body,
  });
};

const updateCart = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/cart`,
    data: body,
  });
};

const deleteCart = (id) => {
  return request({
    method: "delete",
    url: `${API_ENDPOINT}/cart/${id}`,
  });
};

const fetchCart = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/cart`,
  });
};

const fetchCartItem = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/cart/${id}`,
  });
};

const updateCartItem = (id, body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/cart/${id}`,
    data: body,
  });
};

const prepareCheckout = () => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote`,
  });
};

const fetchCheckout = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/quote`,
  });
};

const updateQuote = (body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/quote`,
    data: body,
  });
};

const updateQuoteTnc = (body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/quote/acceptTncs`,
    data: body,
  });
};

const processPayment = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/checkout`,
    data: body,
  });
};

const addProfileAddress = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/address`,
    data: body,
  });
};

const updateProfileAddress = (body, id) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/user/address/${id}`,
    data: body,
  });
};

const deleteAddress = (id) => {
  return request({
    method: "delete",
    url: `${API_ENDPOINT}/user/address/${id}`,
  });
};

const fetchLuckyNumber = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/numberPool`,
  });
};

const holdLuckyNumber = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/numberPool/holdNewNumbers`,
    data: body,
  });
};

const searchLuckyNumber = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/numberPool/lucky`,
    data: body,
  });
};

const requestPortinOtp = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/requestPortInOtp`,
    data: body,
  });
};

const verifyPortinOtp = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/verifyPortInOtp`,
    data: body,
  });
};

const addNumberToPlan = (body, id) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/addNumbers/${id}`,
    data: body,
  });
};

const requestLoginOtp = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/requestLoginOTP`,
    data: body,
  });
};

const otpLogin = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/loginWithOTP`,
    data: body,
  });
};

const refreshAccessToken = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/oauth/token`,
    data: body,
  });
};

const otpLogout = () => {
  const refreshToken = getRefreshToken();
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/logout`,
    data: { refresh_token: refreshToken },
  });
};

const fetchUserInfo = (token) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/user`,
  });
};

const updateUserInfoContent = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user`,
    data: body,
  });
};

const sendContactUs = (body) => {
  return request({
    method: "post",
    data: body,
    url: `${API_ENDPOINT}/contactUs`,
  });
};

const fetchTutorial = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/tutorial`,
  });
};

const fetchUserOrders = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/order`,
  });
};

const fetchUserOrderById = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/order/${id}`,
  });
};

const fetchTNC = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/tnc`,
  });
};

const fetchDeliveryConfig = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/delivery`,
  });
};

const fetchCalendarConfig = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/publicHoliday`,
  });
};

const retrievePaymentStatus = (body, id) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/order/transaction/${id}`,
    data: body,
  });
};

const repurchaseByOrderId = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/cart/addFromOrder/`,
    data: body,
  });
};

const updateCoupon = (body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/quote/applyPromoCode`,
    data: body,
  });
}

export {
  getAccessToken,
  fetchHomeBannerList,
  fetchRecommendList,
  fetchPlan,
  fetchPlanById,
  fetchDevice,
  fetchDeviceProduct,
  addToCart,
  fetchCart,
  fetchCartItem,
  updateCart,
  updateCartItem,
  deleteCart,
  prepareCheckout,
  fetchCheckout,
  updateQuote,
  updateQuoteTnc,
  processPayment,
  addProfileAddress,
  updateProfileAddress,
  deleteAddress,
  fetchLuckyNumber,
  holdLuckyNumber,
  searchLuckyNumber,
  requestPortinOtp,
  verifyPortinOtp,
  addNumberToPlan,
  requestLoginOtp,
  otpLogin,
  refreshAccessToken,
  otpLogout,
  fetchUserInfo,
  updateUserInfoContent,
  sendContactUs,
  fetchTutorial,
  fetchUserOrders,
  fetchUserOrderById,
  fetchTNC,
  fetchDeliveryConfig,
  fetchCalendarConfig,
  retrievePaymentStatus,
  repurchaseByOrderId,
  updateCoupon,
};
