import styled from "styled-components";
import CommonCheckbox from "../Common/CommonCheckbox";

const BubbleCheckbox = ({ label, disabled, circle, checked, onChange }) => {
  return (
    <div>
      {disabled ? (
        <DisableContaienr>
          <CommonCheckbox
            label={label}
            disabled
            circle={circle}
            checked={checked}
            onChange={onChange}
          />
        </DisableContaienr>
      ) : (
        <Container checked={checked}>
          <CommonCheckbox
            label={label}
            circle={circle}
            checked={checked}
            onChange={onChange}
          />
        </Container>
      )}
    </div>
  );
};

const Container = styled.div`
  height: auto;
  width: 80%;
  padding: 15px 20px;
  box-shadow: ${(props) =>
    props.checked ? "0 0 10px 0 #dbbd00" : "0 0 10px 0 #c9c9c9"};
  border-radius: 20px;
`;

const DisableContaienr = styled.div`
  height: auto;
  width: 80%;
  padding: 15px 20px;
  box-shadow: 0 0 10px 0 #c9c9c9;
  background-color: #f2f2f2;
  opacity: 0.5;
  border-radius: 20px;
`;

export default BubbleCheckbox;
