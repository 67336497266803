import { Form, FormikProvider, useFormik } from "formik";
import MyButton from "../../../components/Button";
import Space from "../../../components/Space";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { updateQuoteTnc } from "../../../apis";
import { appearGlobalError } from "../../../requests/globalRequest";
import { useContext } from "react";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import { processPayment } from "../../../apis";
import DevicePriceBar from "../../../components/BottomBar/DevicePriceBar";
import _ from "lodash";
import { formatQuoteProductForGA } from "../../../utils/common";
import { gaBeginCheckout } from "../../../utils/ga";
import { gtmBeginCheckout } from "../../../utils/gtm";

const getInitialValues = (data) => {
  const { userInfo, contactUserInfo, deliveryInfo } = data?.userForm;
  const initialValues = {
    order_list: data?.items || [],
    customer_title: userInfo?.title || "",
    customer_engName: userInfo?.fullName?.en || "",
    customer_chiName: userInfo?.fullName?.zh || "",
    // customer_idType: userInfo?.idType || "",
    // customer_idNum: userInfo?.idNumber || "",
    // customer_dateOfBirth:
    //   userInfo?.birthday || dayjs(Date.now()).format("DD/MM/YYYY"),
    // customer_companyName: userInfo?.companyName || "",
    // customer_jobPosition: userInfo?.companyPosition || "",
    customer_email: userInfo?.email || "",
    customer_mobileNo: userInfo?.mobileNumber || "",
    customer_address1: userInfo?.mailingAddress[0] || "",
    customer_address2: userInfo?.mailingAddress[1] || "",
    customer_district: userInfo?.mailingDistrict || "",
    contact_title: contactUserInfo?.title || "",
    contact_engName: contactUserInfo?.fullName?.en || "",
    contact_chiName: contactUserInfo?.fullName?.zh || "",
    contact_email: contactUserInfo?.email || "",
    contact_mobileNo: contactUserInfo?.mobileNumber || "",
    delivery_method: deliveryInfo?.deliveryMethod || "pickup",
    delivery_addressId: deliveryInfo?.deliveryAddressSelectedId || "",
    delivery_address1: deliveryInfo?.deliveryAddress[0] || "",
    delivery_address2: deliveryInfo?.deliveryAddress[1] || "",
    delivery_district: deliveryInfo?.deliveryDistrict || "",
    delivery_date: deliveryInfo?.deliveryDate || "",
    delivery_time: deliveryInfo?.deliveryTime || "",
    payment_method: "creditCard",
    accepted_tnc: "",
  };

  return initialValues;
};

const PreviewForm = ({ data, children, acceptedTNC, setIsPreview }) => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { t } = useTranslation("checkout");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(data),
    onSubmit: async (payload, { setErrors }) => {
      console.log("submit tnc", acceptedTNC);

      if (
        data?.allProductOverallTncs.length > 0 &&
        (_.isEmpty(acceptedTNC.productTncs) ||
          _.findIndex(acceptedTNC.productTncs, ["accepted", false]) > -1)
      ) {
        setErrors({ accepted_tnc: "error:requiredAcceptTnc" });
      } else {
        try {
          //update tnc
          const tncResult = await updateQuoteTnc(acceptedTNC);

          if (tncResult && tncResult?.msg === "success") {
            const gaPayload = data?.items.map((item, index) => formatQuoteProductForGA({ ...item, sort: index }));

            // trigger begin_checkout ga event
            gaBeginCheckout(gaPayload, data?.promoCode);
            gtmBeginCheckout(gaPayload, data?.promoCode);
            
            //checkout
            let urlBase = window.location.origin;
            const checkoutResult = await processPayment({
              redirectUrl: `${urlBase}/processingPayment`,
            });

            if (checkoutResult && checkoutResult?.data?.redirect_url) {
              window.open(checkoutResult?.data?.redirect_url, "_self");
            } else {
              throw {
                code: checkoutResult?.result?.code,
                msg: checkoutResult?.result?.errorMessage,
              };
            }
          } else {
            throw {
              code: tncResult?.result?.code,
              msg: tncResult?.result?.errorMessage,
            };
          }
        } catch (e) {
          console.log("submit fail", e);
          appearGlobalError(globalDispatch, e?.code, e?.msg, {
            type: "fail",
          });
        }
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
        <Form style={{ maxWidth: "1440px"}}>
          <Container>{children}</Container>

          <PaymentButton>
            <MyButton type="secondary" onClick={() => setIsPreview(false)}>
              {t("back")}
            </MyButton>
            <Space width="20px" />
            <MyButton onClick={() => formik.submitForm()}>
              {t("payment")}
            </MyButton>
          </PaymentButton>

          <DevicePriceBar
            totalPrice={data?.calculatedOrderPrice}
            onClick={() => formik.submitForm()}
            isCheckout
            titleShowTotal
          />
        </Form>
      </div>
    </FormikProvider>
  );
};

const Container = styled.div`
  margin: 0 50px 50px;
`;

const PaymentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  margin-right: 50px;
`;

export default PreviewForm;
