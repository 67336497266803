import { useState } from 'react';
import MyButton from './Button';
import Typography from './Typography';
import styled from 'styled-components';
import theme from '../theme';
import { useTranslation } from 'react-i18next';

import ClearIcon from '@mui/icons-material/Clear';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`

const UploadContainer = styled.div`
    display: flex;
    border-raius: 8px !important;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .selector {
        flex: 1;
    
        border-radius: 0px !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        box-shadow: 0 0 6px 0 #c9c9c9;
        display: flex !important;
        justify-content: flex-start !important;

        background: #fff !important;
        color: #a7a7a7 !important;
        text-shadow: none !important;
        padding: 10px 30px !important;
    }
`

const FileSelector = styled(MyButton)``

const FileNameContainer = styled.div`
    display: flex;
    align-items: center;
`

const Button = styled(MyButton)`
    border-radius: 0px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    box-shadow: 0 0 6px 0 #c9c9c9;
    text-wrap: none !important; 
`

/** File Uploader with support multiple files upload. Will return an array with file in `OnNext`. */
const FileUpload = (props) => {
    const { 
        accept,
        multiple=false,
        onNext,
        data=null,
        disabled=false,
    } = props;

    const { t } = useTranslation('common');

    const [file, setFile] = useState(data);
    const [error, setError] = useState(false);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setError(false);
            // convert e.target.files to array
            const files = Array.from(e.target.files);
            setFile(files);
        }
    }

    const handleOnClickUpload = () => {
        if (!file) {
            return setError(true);
        }
        setError(false);
        onNext && onNext(file);
    }

    const prettifyFileName = (name) => {
        return (name.length > 20) ? name.slice(0, 20) + '...' : name;
    }

    return (
        <Container>
            <UploadContainer>
                {
                    file ? (
                        <FileNameContainer className='selector'>
                            <Typography style={{ fontSize: '18px', color: '#a7a7a7', fontWeight: '500' }}>
                                { multiple && file.length > 1 ? t('selectedMultiFiles', {fileNumber: file.length}) : prettifyFileName(file[0].name) }
                            </Typography>
                            { !disabled && <ClearIcon onClick={() => setFile(null)} style={{ marginLeft: 'auto', cursor: 'pointer' }} /> }
                        </FileNameContainer>
                    ) : (
                        <FileSelector component="label" className='selector' disabled={disabled}>
                            {t('selectFile')}
                            <input hidden accept={accept} multiple={multiple} type="file" onChange={handleFileChange} disabled={disabled} />
                        </FileSelector>
                    )
                }
                <Button onClick={handleOnClickUpload} disabled={disabled}>{t('uploadBtn')}</Button>
            </UploadContainer>
            <div>
                {error && <Typography style={{ fontSize: '12px', color: theme.mandatory }}>{t('noFileError')}</Typography>}
            </div>
        </Container>
    )
}

export default FileUpload;