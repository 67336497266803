import styled from "styled-components";
import theme from "../theme";
import CommonCheckbox from "./Common/CommonCheckbox"
import Typography from "./Typography";
import Price from "./Price/PriceText";
import PriceUnit from "./Price/PriceUnitText";
import OriginalPrice from "./Price/OriginalPrice";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SimpleDialog from "./CommonDialog/SimpleDialog";
import { useState } from "react";
import { GetWord, formatCurrency } from "../utils/common";

const Tag = styled.div`
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  background: ${theme.paleYellow};
  height: 28px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-size: ${theme.fontSize.smallTag};
    font-weight: bold;
  }
`;

const Container = styled.div`
  border-radius: 25px;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  box-shadow: ${(props) =>
    props?.selected ? "0 0 10px 0 #dbbd00" : "0 3px 10px 0 #c9c9c9"};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 140px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${(props) => !props?.isRecommend && `opacity: 0`};
`;

const PriceContainer = styled.div``;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

function Option({
  isRecommend,
  id,
  label,
  price,
  priceUnit,
  originalPrice,
  detail,
  selected,
  CheckboxProps,
  onClick,
}) {
  const { t, i18n } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container selected={selected} onClick={() => onClick(id)}>
      {/* <TagContainer isRecommend={isRecommend}>
        <Tag>Recommend</Tag>
      </TagContainer> */}
      <SubContainer style={{ padding: 15 }}>
        <CommonCheckbox checked={selected} label={label} {...CheckboxProps} />
        <div style={{ flex: 1 }} />
        <Bottom>
          {detail && (
            <Typography
              color="dirtyYellow"
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpen(true)}
            >
              {t("details")}
            </Typography>
          )}
          <PriceContainer>
            <Typography fontSize={theme.fontSize.title4} textAlign="right" condensed>
              + HK$ <Price fontSize={theme.fontSize.price}>{price}</Price>
              {priceUnit && (
                <PriceUnit fontSize={theme.fontSize.priceUnit}>
                  /{priceUnit}
                </PriceUnit>
              )}
            </Typography>
            {originalPrice && (
              <Typography
                color={theme.secondary}
                fontSize={theme.fontSize.originalPrice}
                textAlign="right"
                condensed
              >
                {t("originalPrice")}
                <OriginalPrice fontSize={theme.fontSize.originalPrice}>
                  HK${formatCurrency(originalPrice)}
                </OriginalPrice>
              </Typography>
            )}
          </PriceContainer>
        </Bottom>
      </SubContainer>

      {isOpen && (
        <SimpleDialog isOpen={isOpen} setIsOpen={setIsOpen} title={label}>
          <Typography>{GetWord(detail)}</Typography>
        </SimpleDialog>
      )}
    </Container>
  );
}

export default Option;

Option.propTypes = {
  isRecommend: PropTypes.bool,
  label: PropTypes.string,
  price: PropTypes.string,
  priceUnit: PropTypes.string,
  originalPrice: PropTypes.string,
  selected: PropTypes.bool,
  circle: PropTypes.bool,
};
