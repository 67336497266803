import styled from "styled-components";
import MyTypography from "../Typography";
import { Grid } from "@mui/material";
import { Divider } from "@mui/material";
import theme from "../../theme";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useState } from "react";
import RedditIcon from "@mui/icons-material/Reddit";
import ItemHeader from "./ItemHeader";
import ItemTableRow from "./ItemTableRow";

const ItemTable = ({
  colWidth,
  header,
  row,
  icon,
  onEdit,
  onDelete,
  showDetail,
  setShowDetail,
  renderDetail,
  orderRecord,
  iconNeedDotDecoration,
  itemId,
  editId,
  type,
  extraWidthForButton=false,
  useHtml=true,
}) => {
  return (
    <Grid container>
      <ItemHeader 
        header={header} 
        colWidth={colWidth} 
        onEdit={onEdit} 
        orderRecord={orderRecord} 
        itemId={itemId} 
        editId={editId} 
        type={type}
        extraWidthForButton={extraWidthForButton}
      />

      <Grid xs={12} item style={{ marginBottom: 30 }}>
        <Divider />
      </Grid>

      <ItemTableRow
        showDetail={showDetail}
        setShowDetail={setShowDetail}
        renderDetail={renderDetail}
        header={header}
        row={row}
        icon={icon}
        colWidth={colWidth}
        onDelete={onDelete}
        iconNeedDotDecoration={iconNeedDotDecoration}
        type={type}
        extraWidthForButton={extraWidthForButton}
        useHtml={useHtml}
      />
    </Grid>
  );
};

export default ItemTable;
