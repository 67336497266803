import styled from "styled-components";
import { breakpoint } from "../utils/responsive";
import { useState } from "react";
import AddIcon from "../assets/ic-add.svg";
import MinorIcon from "../assets/ic-minor.svg";
import DisabledMinusIcon from "../assets/ic-disabledMinus.svg";
import theme from "../theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
`;

const Button = styled.div`
  border-radius: 50%;
  color: ${(props) => (props.color ? props.color : "#333")};
  background: ${(props) => (props.bgColor ? props.bgColor : theme.yellow)};
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.disabled && "opacity: 0.5; cursor: not-allowed;"}
`;

const Input = styled.input`
  background-color: transparent;

  outline: none;
  font-weight: 600;
  color: #333;

  border: ${(props) => (props.showBorder ? `2px solid #c9c9c9` : "none")};
  border-radius: 5px;

  width: 70px;
  text-align: center;
  font-size: 38px;
`;

const NumberInput = ({
  value,
  buttonColor,
  buttonBgColor,
  onChange,
  min = 1,
  max = 99,
}) => {
  const handleOnAdd = () => {
    if (value + 1 <= max) onChange(value + 1);
  };
  const handleOnMinus = () => value > min && onChange(value - 1);
  const [isFocusInput, setFocusInput] = useState(false);
  const [isHoverInput, setHoveInput] = useState(false);

  const handleOnChange = (e) => {
    const newValue = Number(e.target.value);

    if (newValue) {
      if (newValue >= min && newValue <= max) {
        onChange(newValue);
      }
    } else {
      onChange(min);
    }
  };

  const valueIsMin = value <= min;
  const valueIsMax = value >= max;

  return (
    <Wrapper>
      <div style={{ width: 36, height: 36 }}>
        <Button
          onClick={handleOnMinus}
          color={valueIsMin ? theme.lightBlack : buttonColor}
          bgColor={valueIsMin ? theme.divider : buttonBgColor}
          disabled={valueIsMin}
        >
          <img src={valueIsMin ? DisabledMinusIcon : MinorIcon} />
        </Button>
      </div>
      <Input
        value={value}
        onChange={handleOnChange}
        showBorder={isFocusInput || isHoverInput}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        onMouseOver={() => setHoveInput(true)}
        onMouseLeave={() => setHoveInput(false)}
      />
      <div style={{ width: 36, height: 36 }}>
        <Button
          onClick={handleOnAdd}
          color={buttonColor}
          bgColor={buttonBgColor}
          disabled={valueIsMax}
        >
          <img src={AddIcon} />
        </Button>
      </div>
    </Wrapper>
  );
};

export default NumberInput;
