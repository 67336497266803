import styled from "styled-components";
import { Grid } from "@mui/material";
import UpIcon from "../../../assets/ic-upYellow.svg";
import DownIcon from "../../../assets/ic-downYellow.svg";
import MyTypography from "../../../components/Typography";
import { useState } from "react";
import CardBase from "../../../components/Card/CardBase";

const Form = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <CardBase style={{ marginBottom: "15px" }}>
      <Grid container spacing={4} style={{padding: "20px 30px 36px 30px", marginTop: "-18px"}} alignItems={"flex-start"}>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <MyTypography fontSize="24" bold>
              {title}
            </MyTypography>
            <div
              style={{ cursor: "pointer", zIndex: 2 }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <img src={isOpen ? UpIcon : DownIcon} />
            </div>
          </div>
        </Grid>

        {isOpen && children}
      </Grid>
    </CardBase>
  );
};

export default Form;
