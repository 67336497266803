import CustomerInformation from "../form/CustomerInformation";
import PaymentMethod from "../form/PaymentMethod";
import ContactInformation from "../form/ContactInformation";
import DeliveryInformation from "../form/DeliveryInformation";
import SupportingDoc from "../form/SupportingDoc";
import PreviewTermAndCondition from "../form/PreviewTermAndCondition";
import PreviewForm from "./PreviewForm";
import OrderItem from "../orderList/OrderItems";
import Coupon from "../Coupon";
import DeliveryContactInformation from "../form/DeliveryContactInformation";

const PreviewOrder = ({
  viewOnly,
  setIsPreview,
  data,
  acceptedTNC,
  setAcceptedTNC,
}) => {

  return (
    <PreviewForm
      data={data}
      acceptedTNC={acceptedTNC}
      setIsPreview={setIsPreview}
    >
      <OrderItem />
      { data?.promoCode && <Coupon data={data} isViewOnly={viewOnly} /> }
      <CustomerInformation viewOnly={viewOnly} />
      {/* <ContactInformation viewOnly={viewOnly} /> */}
      {/* <DeliveryInformation
        viewOnly={viewOnly}
        allowDelivery={data?.isAllowedDelivery}
        allowPickup={data?.isAllowedPickup}
      /> */}
      <DeliveryContactInformation
        viewOnly={viewOnly}
        allowDelivery={data?.isAllowedDelivery}
        allowPickup={data?.isAllowedPickup}
      />
      <PaymentMethod viewOnly={viewOnly} />
      {/* <SupportingDoc viewOnly={viewOnly} /> */}
      <PreviewTermAndCondition
        data={data}
        acceptedTNC={acceptedTNC}
        setAcceptedTNC={setAcceptedTNC}
      />
    </PreviewForm>
  );
};
export default PreviewOrder;
