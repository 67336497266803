import { useContext } from "react";
import styled from "styled-components";
import { Form, FormikProvider, useFormik } from "formik";
import MyTextField from "../components/TextField";
import MyButton from "../components/Button";
import { Grid, Link } from "@mui/material";
import Typography from "../components/Typography";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import MyTypography from "../components/Typography";
import Checkbox from "../components/Checkbox";
import ImageContactUsBg from "../assets/image-contactUsBg.png";
import IconMailboxBubble from "../assets/ic-mailboxBubble.svg";
import Space from "../components/Space";
import { sendContactUs } from "../apis";
import { GlobalContextStore } from "../context/GlobalProvider";
import { appearGlobalError } from "../requests/globalRequest";
import { getMobileNumYUP, getOnlyChineseEnglishStringYUP } from "../utils/common";
import theme from "../theme";
import { gaSignUpSubmit } from "../utils/ga";
import { gtmSignUpSubmit } from "../utils/gtm";

const InputField = (props) => {
  const { id, title, placeholder } = props;
  return (
    <MyTextField
      id={id}
      title={title}
      placeholder={placeholder}
      labelProps={{ fontWeight: "bold" }}
    />
  )
}

const ContactUs = () => {
  const { i18n, t } = useTranslation("contactUs");
  const { globalDispatch } = useContext(GlobalContextStore);

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      companyEmail: "",
      mobile: "",
    },
    validationSchema: Yup.object({
      name: getOnlyChineseEnglishStringYUP(),
      companyName: getOnlyChineseEnglishStringYUP(),
      companyEmail: Yup.string()
        .email("contactUs:pleaseEnterCompanyEmail")
        .required("contactUs:pleaseEnterCompanyEmail"),
      mobile: getMobileNumYUP(),
      agreeTNC: Yup.boolean().required("required").oneOf([true], "required")
    }),
    onSubmit: async (payload) => {
      console.log("submit contact us", payload);

      gaSignUpSubmit(); // TODO: confirm when to trigger - onClick submit button or after form submit success
      gtmSignUpSubmit();
      const result = await sendContactUs(payload);
        if (result) {
          appearGlobalError(
            globalDispatch,
            t("formSentSuccess"),
            t("csTeamContact"),
            { type: "success" }
          )
        } else {
          appearGlobalError(
            globalDispatch,
            t("formSentFailed"),
            { type: "error" }
          )
        }
    },
  });

  const TNCLabel = () => {
    return (
      <Typography fontSize={14}>
        {t("tnc1")}
        <Link 
          color={theme.dirtyYellow} 
          href={`https://www.hkt.com/privacy-statement?locale=${i18n?.language || "en"}`} 
          underline="none" 
          style={{ fontWeight: "bold" }} 
          target="_blank" 
          rel="noopener"
        >
          {t("tnc")}
        </Link>
        {t("tnc2")}
      </Typography>
    )
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Container>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            // style={{ marginTop: 30 }}
            style={{ paddingTop: 50 }}
          >
            <Grid md={3} />
            <Grid item xs={12} md={6}>
              <Space size="3px" />
              <MyTypography fontSize="24" bold textAlign="center">
                {t("title")}
              </MyTypography>
              <Space size="23px" />
              <MyTypography fontSize="16" textAlign="center">
                {t("subTitle")}
              </MyTypography>
            </Grid>
            <Grid item container md={3} xs={12} justifyContent={{ xs: 'flex-end', md: 'flex-start' }}>
              <img src={IconMailboxBubble} />
            </Grid>
            <Grid md={3} />
            <Grid item xs={12} md={3}>
              <InputField
                id="name"
                title={t("name")}
                placeholder="Alan Chan Tai Man"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputField
                id="companyName"
                title={t("companyName")}
                placeholder="ABC Company"
              />
            </Grid>
            <Grid md={3} />

            <Grid md={3} />
            <Grid item xs={12} md={3}>
              <InputField
                id="companyEmail"
                title={t("companyEmail")}
                placeholder="chantaiman@abc.com.hk"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputField
                id="mobile"
                title={t("mobile")}
                placeholder="61234567"
              />
            </Grid>
            <Grid md={3} />

            <Grid item xs={12}>
              <Space size="24px" />
            </Grid>

            <Grid item xs={12} md={3}>
              <Checkbox 
                id={'agreeTNC'} 
                label={<TNCLabel />} 
                style={{
                  alignItems: "flex-start",
                }}
                checkboxStyle={{
                  padding: 0,
                }}
                typographyProps={{
                  fontSize: "14",
                  fontWeight: "normal !important",
                  color: "#666",
                }}
              />
              <Space size="15px" />
              <MyButton fullWidth onClick={formik.handleSubmit}>{t("submit")}</MyButton>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </FormikProvider>
  );
};

const Container = styled.div`
  background-image: url(${ImageContactUsBg});
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0px 30px 50px 30px;
`;

export default ContactUs;
