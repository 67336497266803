import SpecialFeeContent from "../../../components/OrderItems/SpecialFeeContent";
import { useTranslation } from "react-i18next";

import ShippingFeeIcon from "../../../assets/ic-shippingFee.svg";
import DiscountIcon from "../../../assets/ic-discount.svg";

const SpecialOrder = (props) => {
    const {
        type="discount",
        data=null,
        cardBaseStyle=null,
        onDelete,
        extraWidthForButton=false,
    } = props;

    const { t } = useTranslation("orderContentItem");

    return (
        <SpecialFeeContent
            sectionTitle={type === "delivery" ? t("shippingFee") : t("discountCode")}
            icon={type === "delivery" ? ShippingFeeIcon : DiscountIcon}
            type={type}
            data={data}
            cardBaseStyle={cardBaseStyle}
            onDelete={onDelete}
            extraWidthForButton={extraWidthForButton}
            useHtml={false}
            // iconNeedDotDecoration={true}
        />
    )
}

export default SpecialOrder;