import { useState, useEffect, useContext } from "react";
import PlanList from "../pageComponents/home/PlanList";
import Banner from "../components/Banner";
import {
  fetchHomeBannerList,
  fetchPlan,
  fetchDevice,
  fetchRecommendList,
} from "../apis";
import { useQueries } from "@tanstack/react-query";
import LoadingIndicator from "../components/LoadingIndicator";
import PlanBenefit from "../pageComponents/home/PlanBenefit";
import CompanyRegister from "../pageComponents/home/CompanyRegister";
import GreetingDialog from "../pageComponents/home/greetingDialog";
import { GlobalContextStore } from "../context/GlobalProvider";
import _ from "lodash";
import { gaSelectPromotion } from "../utils/ga";
import { gtmSelectPromotion } from "../utils/gtm";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { globalState } = useContext(GlobalContextStore);
  const companyId = globalState?.userInfo?.company?._id;

  const { i18n } = useTranslation();

  const [bannerData, deviceData] = useQueries({
    queries: [
      {
        queryKey: [`homeBanners-${companyId}`],
        queryFn: () => fetchHomeBannerList(companyId),
      },
    //   {
    //     queryKey: ["planList"],
    //     queryFn: fetchPlan,
    //   },
      {
        queryKey: [`deviceList-${companyId}`],
        queryFn: () => fetchDevice(companyId),
      },
    ],
  });

  const [hintDialogIsOpen, setHintDialogIsOpen] = useState(false);

  const handleClickBanner = (item) => {
    const gaPayload = { promotion_id: item?._id || "NA", destination_page_url: item?.urlPath?.[i18n.language] || "NA" };
    gaSelectPromotion(gaPayload);
    gtmSelectPromotion(gaPayload);
  }

  if (bannerData?.isLoading || deviceData?.isLoading) {
    return <LoadingIndicator />;
  }

  if (bannerData?.isError || deviceData?.isError) {
    //TODO - api error handle
    console.log("error - ", bannerData?.isError);
  }
  return (
    <div style={{ overflow: "hidden" }}>
      {hintDialogIsOpen && false && (
        <GreetingDialog isOpen={hintDialogIsOpen} setIsOpen={setHintDialogIsOpen} />
      )}
      <Banner 
        // FIXME: banner data type
        data={bannerData?.data?.data?.filter(item => item?.animationType === 'static')}
        onClickBanner={handleClickBanner}
        lang={i18n.language}
      />
      <PlanBenefit />
      {/* <CompanyRegister /> */}
      <PlanList
        // plans={planData?.data?.data}
        devices={deviceData?.data?.data}
        companyId={companyId}
      />
    </div>
  );
};

export default Home;
