import styled from "styled-components";
import NumberInput from "../../../../components/NumberInput";
import YellowBubbleCheckbox from "../../../../components/Checkboxs/YellowBubbleCheckbox";
import MyTypography from "../../../../components/Typography";
import Space from "../../../../components/Space";
import { Grid } from "@mui/material";
import DatePicker from "../../../../components/DateTimePicker/DatePicker";
import { useState, useEffect } from "react";
import NewNumberDialog from "./NewNumberDialog";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/Select";
import dayjs from "dayjs";

const TIME_SLOT = [
  {
    title: "9am - 1pm",
    value: "am",
  },
  {
    title: "2pm - 6pm",
    value: "pm",
  },
];

const NewNumber = ({ name, totalPlanNum, idx }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(`${name}`);
  const { values } = useFormikContext();
  const [isOpenNemNum, setIsOpenNewNum] = useState(false);

  useEffect(() => {
    const orderValue = values.order_list[idx];

    helpers.setValue({
      ...field.value,
      order_newNumQty: orderValue?.newNumbers ? orderValue?.newNumbers?.length : 0,
      order_newNumList: orderValue?.newNumbers,
      order_newNumbersEffectiveDate: orderValue?.newNumberEffectiveDate || dayjs(Date.now()).format("DD/MM/YYYY"),
      order_newNumbersEffectiveTime:  orderValue?.newNumberEffectiveTime || "",
    });
  }, []);

  const handleNumberOnChange = (number) =>
    helpers.setValue({ ...field.value, order_newNumQty: number });

  return (
    <Container>
      <Quantity>
        <MyTypography bold>{t("newNumber")}</MyTypography>
        <NumberInput
          min={0}
          max={totalPlanNum}
          value={field?.value?.order_newNumQty}
          onChange={handleNumberOnChange}
        />
      </Quantity>
      {field?.value?.order_newNumQty > 0 && (
        <div>
          <MyTypography fontSize="18" fontWeight="500">
            {t("chooseYourNewNumber")}
          </MyTypography>
          <Space size="20px" />
          <Grid container spacing={4}>
            {field?.value?.order_newNumList?.map((num) => {
              return (
                <Grid item xs={4}>
                  <YellowBubbleCheckbox label={num?.number} checked={true} selected />
                </Grid>
              );
            })}
          </Grid>
          <Space size="25px" />
          <ChangeNumberButton onClick={() => setIsOpenNewNum(true)}>
            {t("changeNumber")}
          </ChangeNumberButton>
          <Space size="50px" />
          <DatePicker
            id={`${name}.order_newNumbersEffectiveDate`}
            title={t("effectiveDateTime")}
          />
          <Space size="10px" />
          <Select
            id={`${name}.order_newNumbersEffectiveTime`}
            placeholder={t("selectOption")}
            options={TIME_SLOT}
            required
          />
        </div>
      )}

      <NewNumberDialog
        name={name}
        isOpen={isOpenNemNum}
        setIsOpen={setIsOpenNewNum}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 500px;
  padding: 20px 20px 20px 0px;
  border-right: 1px solid #bababa;
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const ChangeNumberButton = styled(MyTypography)`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;

export default NewNumber;
