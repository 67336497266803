import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { zhHK } from '@mui/x-date-pickers/locales';
import "dayjs/locale/en";
import "dayjs/locale/zh-hk";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Space from "../Space";
import MyTypography from "../Typography";
import theme from "../../theme";
import { useField } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
import { useTranslation } from "react-i18next";
dayjs.extend(customParseFormat);

export default function DateTimePicker({
  title,
  required,
  id,
  viewOnly,
  minDate,
  helperText,
  shouldDisableDate,
}) {
  const { t, i18n } = useTranslation("error");
  const [field, meta, helpers] = useField(id);

  return (
    <div>
      {title && (
        <MyTypography
          style={{
            display: "inline-block",
          }}
        >
          {title}
        </MyTypography>
      )}
      {required && (
        <MyTypography
          color={theme.orange}
          style={{ marginLeft: 5, marginRight: 5, display: "inline-block" }}
        >
          *
        </MyTypography>
      )}
      <Space size="8px" />
      {viewOnly ? (
        <MyTypography style={{ fontSize: "18px", color: theme.lightBlack }}>
          {field.value}
        </MyTypography>
      ) : (
        <LocalizationProvider 
          dateAdapter={AdapterDayjs} 
          localeText={i18n?.language === "zh" ? zhHK.components.MuiLocalizationProvider.defaultProps.localeText : undefined}
          adapterLocale={i18n?.language === "zh" ? dayjs.locale("zh-hk") : dayjs.locale("en")}
        >
          <DatePicker
            value={dayjs(field.value, "DD/MM/YYYY")}
            onChange={(value) => {
              helpers.setValue(dayjs(value).format("DD/MM/YYYY"));
            }}
            dayOfWeekFormatter={(day, weekday) => `${weekday.format(i18n?.language === "zh" ? 'dd' : 'ddd')}`}
            minDate={minDate}
            shouldDisableDate={shouldDisableDate}
            format="DD/MM/YYYY"
            slotProps={{
              textField: {
                readOnly: true,
                style: { width: "100%" },
                error: !_.isEmpty(meta.error),
                helperText: meta.error && t(meta?.error),
                sx: {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#fff",
                      boxShadow: "0 0 6px 0 #c9c9c9",
                      borderRadius: "10px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#fff",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#fff",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${theme.error3}`,
                      boxShadow: `${theme.error3} 0 0 6px 0`,
                    }
                  },
                  "& .MuiFormHelperText-root": {
                    "&.Mui-error": {
                      color: theme.error2,
                    }
                  }
                },
              },
              dialog: {
                sx: {
                  "& .MuiDialog-paper": {
                    borderRadius: "25px",
                  }
                }
              },
              actionBar: {
                sx: {
                  "& .MuiButton-root": {
                    color: "#000",
                  },
                }
              },
              day: {
                sx: {
                  "&:hover.MuiPickersDay-root": {
                    backgroundColor: "#FFFBDE",
                  },
                  "&:focus.MuiPickersDay-root": {
                    backgroundColor: "#FFFBDE",
                  },
                  "&.MuiPickersDay-root.Mui-selected": {
                    backgroundColor: "#FFFBDE",
                    color: "#000",
                  }
                }
              },
            }}
          />
        </LocalizationProvider>
      )}
    </div>
  );
}
