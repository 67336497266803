import React, { useState, useContext, useMemo } from "react";
import styled from "styled-components";
import TextButton from "../TextButton";
import Button from "../Button";
import Typography from "../Typography";
import { GlobalContextStore } from "../../context/GlobalProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ReactComponent as MenuIcon } from "../../assets/ic-menu.svg";
import { ReactComponent as CloseIcon } from "../../assets/ic-close.svg";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconShoppingCart from "../../assets/ic-shoppingCart.svg";
import IconMyAccount from "../../assets/ic-myAccount.svg";
import Logo from "../Logo";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { GetWord } from "../../utils/common";
import ShoppingBagZh from "../../assets/ic-1010ShoppingBag-zh.svg";
import ShoppingBagEn from "../../assets/ic-1010ShoppingBag-en.svg";
import IconLogin from "../../assets/ic-login.svg";
import IconLogout from "../../assets/ic-logout.svg";
import IconProfile from "../../assets/ic-myProfile.svg";
import IconHelp from "../../assets/ic-help.svg";
import { ReactComponent as IconMobilePlan } from "../../assets/ic-mobilePlan.svg";
import { ReactComponent as IconRecommend } from "../../assets/ic-recommend.svg";
import { ReactComponent as IconElectronicDevice } from "../../assets/ic-electronicDevice.svg";
import Space from "../Space";
import _ from "lodash";

const StyledTypography = styled(Typography)`
  font-weight: 500;
  text-align: center;
  color: ${theme.lightBlack};
  font-size: 18px;
`

const CustomButton = styled(Button)`
  && {
    margin-right: 20px;
    width: 200px;
    height: 45px;
  }
`;

const StyledMenu = ({ top=78, height="fit-content", children, ...props }) => {
  return (
    <Menu
      PaperProps={{
        sx: {
          top: `${top}px !important`,
        },
        style: {
          width: "100%",
          maxWidth: "100%",
          height: height,
          left: 0,
          right: 0,
        },
      }}
      id="menu-appbar"
      keepMounted
      anchorOrigin={{ vertical: "top", horizontal: 'left' }}
      transformOrigin={{ vertical: "top", horizontal: 'left' }}
      marginThreshold={0}
      {...props}
    >
      {children}
    </Menu>
  )
}

function MenuBar(props) {
  const {
    handleHelp, 
    handleChangeLanguage, 
    handleCart,
    handleClickMenuItem,
    handleClickMenuParentItem,
    handleClickMenuSubItem,
    menuItem,
    userMenuItem,
    consentHeight,
  } = props;

  const { t, i18n } = useTranslation(["navBar", "home"]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorLogin, setAnchorLogin] = useState(null);
  const [isExpand, setIsExpand] = useState('');

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setAnchorLogin(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOnClickParentItem = (itemId) => {
    if (isExpand !== itemId) setIsExpand(itemId);
    else setIsExpand('');
  }

  return (
    <React.Fragment>
      <AppBar position="static" sx={{ backgroundColor: "#fff", color: "#000", zIndex: 999, minHeight: "78px", position: "relative" }}>
        <Toolbar variant="dense" style={{ padding: "7px 10px", display: "flex", justifyContent: "space-between" }}>
          <Logo style={{ textDecoration: "none", padding: "9px 30px 7px 10px", cursor: 'pointer' }} onClick={() => handleClickMenuItem({ id: 'website-logo', href: '/' })} />
          <div>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleCart}
            >
              <img src={IconShoppingCart} />
            </IconButton>

            <IconButton
              onClick={() => {
                setAnchorLogin((prev) => !prev);
                setAnchorElUser(null);
              }}
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <img src={IconMyAccount} />
            </IconButton>

            <IconButton
              onClick={anchorElUser ? handleCloseUserMenu : handleOpenUserMenu}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              { anchorElUser ? <CloseIcon style={{ width: "22px" }} /> : <MenuIcon style={{ width: "22px" }} /> }
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {/* Menu for My Account */}
      <StyledMenu
        top={78+consentHeight}
        anchorEl={anchorLogin}
        open={Boolean(anchorLogin)}
        onClose={() => setAnchorLogin(null)}
      >
        {
          userMenuItem.map((item) => {
            return (
              <MenuItem
                key={`mobile-menu-item-${item.id}`}
                onClick={() => 
                  handleClickMenuSubItem(
                    { id: "my-account", child: userMenuItem }, 
                    item,
                    setAnchorLogin(null),
                  )
                }
              >
                <StyledTypography>
                  <TextButton
                    color={theme.lightBlack}
                    startIcon={item?.icon || null}
                  >
                    {t(`topBar.${item?.label}`)}
                  </TextButton>
                </StyledTypography>
              </MenuItem>
            )
          })
        }
        <Space size={30} />
      </StyledMenu>

      {/* Menu for navigation */}
      <StyledMenu
        height="100%"
        top={78+consentHeight}
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {
          menuItem.map((parent) => {
            if (parent?.child && parent?.child?.length > 0) {
              const isCurrentExpand = isExpand === parent.id;

              return (
                <div>
                  <MenuItem
                    key={`mobile-menu-parent-${parent.id}`}
                    onClick={() => handleClickMenuParentItem(parent, handleOnClickParentItem(parent.id))}
                    style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}
                  >
                    <StyledTypography>
                      {t(`navBar:topBar.${parent?.label}`)}
                    </StyledTypography>
                    {isCurrentExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </MenuItem>

                  {isCurrentExpand && (
                    parent.child.map((item, index) => (
                      <MenuItem
                        key={`mobile-menu-sub-item-${item.id}`}
                        onClick={() => handleClickMenuSubItem(parent, item, handleCloseUserMenu)}
                      >
                        <TextButton color={theme.navSubItem} startIcon={item.icon} fontWeight={500}>
                          {t(`home:${item.id}`)}
                        </TextButton>
                      </MenuItem>
                    ))
                  )}
                </div>
              )
            } else {
              return (
                <MenuItem
                  key={`mobile-menu-item-${parent.id}`}
                  onClick={() => handleClickMenuItem(parent, handleCloseUserMenu)}
                  style={{ padding: "20px" }}
                >
                  <StyledTypography>
                    {t(`navBar:topBar.${parent?.label}`)}
                  </StyledTypography>
                </MenuItem>
              )
            }
          })
        }

        <MenuItem
          key={"lang"}
          onClick={handleChangeLanguage}
          style={{ padding: "20px" }}
        >
          <StyledTypography>
            {t("navBar:topBar.mobileLang")}
          </StyledTypography>
        </MenuItem>

        <div
          style={{
            display: "flex",
            padding: "6px 20px",
            // marginTop: "20px",
          }}
        >
          <CustomButton
            startIcon={<img src={IconHelp} />}
            onClick={handleHelp}
          >
            {t("topBar.contactUs")}
          </CustomButton>
        </div>
      </StyledMenu>
    </React.Fragment>
  );
}

export default MenuBar;
