import styled from "styled-components";
import { Grid } from "@mui/material";
import Typography from "../../../components/Typography";
import theme from "../../../theme";
import ClientInfo from "./ClientInfo";
import ContactInfo from "./ContactInfo";
import ShippingAddress from "./ShippingAddress";
import { breakpoint } from "../../../utils/responsive";

const getInitialValue = (data) => {
    // console.log('==data', data)
    const { email, userInfo, shippingAddresses, contactUserInfo=undefined, deliveryInfo } = data;
    return {
        clientInfo: {
            title: userInfo?.title || '',
            name: {
                en: userInfo?.fullName?.en || '',
                zh: userInfo?.fullName?.zh || ''
            },
            idType: userInfo?.idType || '',
            idNumber: userInfo?.idNumber || '',
            dateOfBirth: userInfo?.birthday || '',
            companyName: userInfo?.companyName || '',
            position: userInfo?.companyPosition || '',
            // TODO: confirm api
            mailingAddress: {
                addresses: [{
                    address: userInfo?.mailingAddress || '',
                    district: userInfo?.mailingDistrict || '',
                }],
            },
            mobile: userInfo?.mobileNumber || '',
            email: userInfo?.email || email || '',
        },
        contactInfo: [
            {
                title: contactUserInfo?.title || '',
                name: {
                    en: contactUserInfo?.fullName?.en || '',
                    zh: contactUserInfo?.fullName?.zh || ''
                },
                email: contactUserInfo?.email || '',
                mobile: contactUserInfo?.mobileNumber || '',
            },
        ],
        shippingAddress: {
            addresses: (shippingAddresses || []).map(item => ({
                id: item?._id || '',
                address: item?.address || '',
                district: item?.district || '',
            })),
            isSameAsMailingAddress: deliveryInfo?.isSameAsMailingAddress || false,
        },
    }
}

const AccountInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;

    @media ${breakpoint.mobile} {
        padding: 0 30px;
        width: calc(100% - 60px);   // TODO
    }
`

const AccountInfoComponent = (props) => {
    const { data, globalDispatch, onAfterAction=null } = props;

    const initialData = getInitialValue(data);

    return (
        <AccountInfoContainer>
            <ClientInfo data={initialData.clientInfo} onAfterAction={onAfterAction} />
            <ContactInfo data={initialData.contactInfo} onAfterAction={onAfterAction} />
            <ShippingAddress data={initialData.shippingAddress} globalDispatch={globalDispatch} onAfterAction={onAfterAction} />
        </AccountInfoContainer>
    )

}

export default AccountInfoComponent;