import { useContext, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import dayjs from "dayjs";
import StepContainer from "../StepContainer";
import { useTranslation } from "react-i18next";
import { addNumberToPlan, processPayment } from "../../../apis";
import _ from "lodash";
import { appearGlobalError } from "../../../requests/globalRequest";
import { GlobalContextStore } from "../../../context/GlobalProvider";

const getInitialValues = (data) => {
  const initialValues = {
    order_list: data?.items || [],
    coupon_code: data?.promoCode || "",
  };

  return initialValues;
};

const processBody = (data) => {
  // TODO - data checking
  // TODO - only product flow
  const body = data?.map((item) => {
    let mnpList = [];

    if (item.order_mnpNumbers) {
      mnpList = item?.order_mnpNumbers.map((nums) => {
        let temp = {
          effectiveDate: nums.effectiveDate,
          effectiveTime: nums.effectiveTime,
          networkProvider: nums.networkProvider,
          simType: nums.simType,
          realNameRegistered: nums.realNameRegistered,
          acceptedPrepaidTnc: nums.acceptedPrepaidTnc,
          acceptedPostpaidTnc: nums.acceptedPostpaidTnc,
        };

        if (nums.simType === "postpaid") {
          delete temp.acceptedPrepaidTnc;
          delete temp.realNameRegistered;
        } else {
          delete temp.acceptedPostpaidTnc;
          if (nums.realNameRegistered !== true) {
            delete temp.acceptedPrepaidTnc;
          }
        }

        return temp;
      });
    }

    let newNumList = {};

    if (item.order_newNumQty > 0) {
      newNumList = {
        newNumberPoolIds: item.order_newNumList
          ? item.order_newNumList.map((item) => item._id)
          : [],
        newNumbersEffectiveDate: item.order_newNumbersEffectiveDate,
        newNumbersEffectiveTime: item.order_newNumbersEffectiveTime,
      };
    }

    return {
      ...newNumList,
      orderId: item?._id,
      isAutoRenewal: item?.order_isAutoRenewal || false,
      mnpNumbers: mnpList,
    };
  });

  return body;
};

const OrderListForm = ({ children, data, step, setStep, refetch, setIsPreview, autoSave, setAutoSave }) => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { t } = useTranslation("checkout");

  useEffect(() => {
    if (autoSave[2]) {
      formik.submitForm();
    }
  }, [autoSave]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(data),
    onSubmit: async (payload) => {
      console.log("submit order list", payload);
      setAutoSave({...autoSave, 1: true});
      
      if (_.findIndex(payload.order_list, ["type", "plan"]) > -1) {
        const numberBody = processBody(
          _.filter(payload.order_list, ["type", "plan"])
        );
        console.log("save order list", numberBody);

        let result = await Promise.all(
          numberBody.map(async (item) => {
            return addNumberToPlan(item, item.orderId);
          })
        );

        console.log("update quote", result);
        //TODO - error handle
        if (result) {
          refetch();
        }
      } else {
        setIsPreview(true);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepContainer
          step={step}
          setStep={setStep}
          currentStep={step[1]}  //TODO - hardcode for device only
          title={t("orderInformation")}
          lastStep
          onSave={() => formik.submitForm()}
          isLastStep={true}
        >
          {children}
        </StepContainer>
      </Form>
    </FormikProvider>
  );
};

export default OrderListForm;
