import { useState, useContext, useMemo } from "react";
import styled from "styled-components";
import TextButton from "../TextButton";
import Button from "../Button";
import Typography from "../Typography";
import DesktopMenuBar from "./desktop";
import MobileMenuBar from "./mobile";
import { ResponsiveUI } from "../../utils/responsive";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContextStore } from "../../context/GlobalProvider";
import { updateUserInfo } from "../../requests/globalRequest";
import { logout } from "../../requests/globalRequest";
import _ from "lodash";

import { ReactComponent as IconLogin } from "../../assets/ic-login.svg";
import { ReactComponent as IconLogout } from "../../assets/ic-logout.svg";
import { ReactComponent as IconProfile } from "../../assets/ic-myProfile.svg";
import { gaNavigationBar } from "../../utils/ga";
import { gtmNavigationBar } from "../../utils/gtm";

function MenuBar(props) {
  const { i18n } = useTranslation("navBar");
  const { globalState, globalDispatch } = useContext(GlobalContextStore);
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = !_.isEmpty(globalState?.userInfo);

  const [hottestOfferRef, setHottestOfferRef] = useState(null);

  const handleTriggerGoogleEvent = (href, menu_category, sub_menu_category="NA") => {
    const current_page_url = window.location.href;
    const destination_page_url = href ? (href.includes("http") ? href : (window.location.origin + href)) : "NA";

    const data = {
      page_url: current_page_url,
      destination_page_url,
      menu_category,
      sub_menu_category,
    };

    gaNavigationBar(data);
    gtmNavigationBar(data);
  }

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleNavigateToLogin = () => {
    handleTriggerGoogleEvent("/login", "login")
    navigate("/login", { replace: true, state: { from: location }, search: location?.search || "" });
  }

  const handleHelp = () => {
    const targetUrl = `https://www.1010corporate.com/${i18n.language === "zh" ? "tc" : "en"}/ces#enquiry`
    handleTriggerGoogleEvent(targetUrl, "contact-us")
    window.open(targetUrl, "_blank");
  };

  const handleChangeLanguage = () => {
    const newLang = i18n.language === "en" ? "zh" : "en";

    // check if current page is '/en' or '/tc'
    if (newLang === 'en' &&
      (window.location.pathname === "/" || window.location.pathname === "/tc" || window.location.pathname === "/tc/")) {
      handleNavigate(`/${newLang}`, { replace: true });

    } else if (newLang === 'zh' &&
      (window.location.pathname === "/" || window.location.pathname === "/en" || window.location.pathname === "/en/")) {
      handleNavigate(`/tc`, { replace: true });

    } else {
      localStorage.setItem("language", newLang);
      i18n.changeLanguage(newLang);
    }
    
    handleTriggerGoogleEvent(window.location.href, "change-language", newLang)
  };

  const handleLogout = () => {
    // logout ga event handle by handleClickMenuSubItem function
    logout();
  };

  const handleClickMenuItem = (item, onNext=null) => {
    const { id, href } = item;

    handleTriggerGoogleEvent(href, id)
    
    if (href) {
      handleNavigate(href, { replace: true });
    }

    onNext && onNext();
  }

  const handleClickMenuParentItem = (item, onNext=null) => {
    const childId = item.child.map(child => child.id);
    handleTriggerGoogleEvent(null, item.id, childId)

    onNext && onNext();
  }

  const handleClickMenuSubItem = (parent, child, onNext=null) => {
    handleTriggerGoogleEvent(child?.href || null, parent.id, child.id)

    if (child?.href) {
      handleNavigate(child?.href, { replace: true })
    } else if (child?.onClick) {
      child.onClick();
    }

    onNext && onNext();
  }

  const handleClickShoppingCart = () => {
    handleClickMenuItem({ id: "shopping-cart", href: "/shoppingcart" })
  }

  const MenuItem = [
    { 
      id: "hottest-offer",
      label: 'hottest',
      child: [
        { id: "apple", href: "/?service=apple" },
        { id: "android", href: "/?service=android" },
        { id: "accessories", href: "/?service=accessoriesGaming" },
        { id: "lifestyle", href: "/?service=homeLifestyle" },
      ],
      anchorElRef: hottestOfferRef,
      onExpand: (event) => setHottestOfferRef((prev) => (prev ? null : event.currentTarget)),
      onClose: () => setHottestOfferRef(null),
    },
    {
      id: "shopping-guide",
      href: "/shoppingGuide",
      label: 'shoppingGuide',
    }
  ]

  const UserMenuItem = useMemo(() => {
    return isLogin ? [
      {
        id: 'profile',
        label: 'myAccount',
        href: '/profile',
        icon: <IconProfile width={25} />,
      },
      {
        id: 'logout',
        label: 'logout',
        onClick: handleLogout,
        icon: <IconLogout width={25} />,
      }
    ] : [
      {
        id: 'login',
        label: 'login',
        onClick: handleNavigateToLogin,
        icon: <IconLogin width={25} />,
      }
    ]
  }, [isLogin])

  return (
    <ResponsiveUI
      desktop={
        <DesktopMenuBar
          menuItem={MenuItem}
          userMenuItem={UserMenuItem}
          handleChangeLanguage={handleChangeLanguage}
          handleHelp={handleHelp}
          handleCart={handleClickShoppingCart}
          handleClickMenuItem={handleClickMenuItem}
          handleClickMenuParentItem={handleClickMenuParentItem}
          handleClickMenuSubItem={handleClickMenuSubItem}
          {...props}
        />
      }
      mobile={
        <MobileMenuBar
          menuItem={MenuItem}
          userMenuItem={UserMenuItem}
          handleChangeLanguage={handleChangeLanguage}
          handleHelp={handleHelp}
          handleCart={handleClickShoppingCart}
          handleClickMenuItem={handleClickMenuItem}
          handleClickMenuParentItem={handleClickMenuParentItem}
          handleClickMenuSubItem={handleClickMenuSubItem}
          consentHeight={globalState?.showCookieConsent ? globalState?.cookieConsentHeight : 0}
          {...props}
        />
      }
    />
  );
}

export default MenuBar;
