import { useState, useEffect } from "react";
import Field from "./Field";
import QuantiyField from "./QuantityField";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik } from "formik";
import AlertDialog from "../../components/CommonDialog/AlertDialog";
import _ from "lodash";
import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import TotalPriceBar from "../../components/BottomBar/DevicePriceBar";

// const mapStateToProps = (state, editId) => editId ? state.order_list.find(o => o.id === editId) : null;

const getInitialValues = (data, selected) => {
  const initialValues = {
    plan: data?._id || "",
    contractPeriod: selected?.options?.contractPeriodId || "",
    freeVas: selected?.freeVas || [],
    paidVas: selected?.paidVas || [],
    quantity: selected?.quantity || 1,
  };

  return initialValues;
};

const MobileForm = ({ setSteps, steps, data, selected, onSubmit }) => {
  const { t } = useTranslation("mobile");
  const [isOpen, setIsOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: getInitialValues(plan, mapStateToProps(orderState, router.query.editId)),
    initialValues: getInitialValues(data, selected),
    onSubmit: async (payload) => {
      console.log("add mobile", payload);

      let body = {
        ...payload,
        options: { contractPeriodId: payload.contractPeriod },
      };
      delete body.contractPeriod;

      onSubmit && onSubmit(body);
    },
  });

  const onTitleClick = (id, isRequired, val) => {
    if (isRequired && _.isEmpty(val)) {
      setIsOpen(true);
    } else {
      const newSteps = steps.map((item) => {
        if (item.id === id - 1 && val) {
          return {
            ...item,
            isCompleted: val ? !_.isEmpty(val) : item?.isCompleted,
            isActive: false,
          };
        } else {
          return { ...item, isActive: item.id === id };
        }
      });

      setSteps(newSteps);
    }
  };

  const handleFindTotalPrice = (value) => {
    const { contractPeriod, freeVas, paidVas, quantity = 1 } = value;

    let total = 0;
    if (contractPeriod) {
      const contractPeriodPrice = data?.contractPeriodDetails.find(
        (item) => item._id === contractPeriod
      ).discountedPrice;

      total += contractPeriodPrice || 0;
    }

    if (freeVas) {
      freeVas.forEach((item) => {
        const freeVasPrice = data?.freeVas.find(
          (i) => i._id === item
        ).discountedPrices;
        total += freeVasPrice || 0;
      });
    }

    if (paidVas) {
      paidVas.forEach((item) => {
        const paidVasPrice = data?.paidVas.find(
          (i) => i._id === item
        ).discountedPrice;
        total += paidVasPrice || 0;
      });
    }

    setTotalPrice(total * quantity);
  };

  useEffect(() => {
    handleFindTotalPrice(formik.values);

    if (!_.isEmpty(formik.values.contractPeriod)) setDisableSubmit(false);
  }, [formik.values]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <FieldContainer>
          <Field
            id="contractPeriod"
            title={t("contractPeriod")}
            options={data?.contractPeriodDetails}
            stepId={1}
            steps={steps}
            onTitleClick={onTitleClick}
            isRequired
          />
          <Field
            id="freeVas"
            title={t("freeService")}
            options={data?.freeVas}
            stepId={2}
            steps={steps}
            onTitleClick={onTitleClick}
            multiple
          />
          <Field
            id="paidVas"
            title={t("businessService")}
            options={data?.paidVas}
            stepId={3}
            steps={steps}
            onTitleClick={onTitleClick}
            multiple
          />
          <QuantiyField
            id="quantity"
            stepId={4}
            steps={steps}
            setSteps={setSteps}
            onTitleClick={onTitleClick}
            min={data?.quantityMin}
            max={data?.quantityMax}
          />
        </FieldContainer>

        <TotalPriceBar
          disabled={disableSubmit}
          totalPrice={totalPrice}
          onClick={() => formik.submitForm()}
        />
      </Form>

      <AlertDialog
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        title={t("itemsNotSelected")}
        content={`${t("pleaseSelect")} ${t("contractPeriod")}`}
        btnLabel="Ok"
        type="alert"
      />
    </FormikProvider>
  );
};

const FieldContainer = styled.div`
  @media ${breakpoint.desktop} {
    padding: 28px 0px 28px 50px;
  }

  @media ${breakpoint.mobile} {
    margin-top: 20px;
  }
`;

export default MobileForm;
