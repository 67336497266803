import styled from "styled-components";
import SimpleDialog from "./SimpleDialog";
import MyTypography from "../Typography";
import MyButton from "../Button";
import theme from "../../theme";
import FailIcon from "../../assets/ic-alertFail.svg";
import WarningIcon from "../../assets/ic-alertWarning.svg";
import UploadIcon from "../../assets/ic-alertUpload.svg";
import SuccessIcon from "../../assets/ic-alertSuccess.svg"
import Space from "../Space";
import { breakpoint } from "../../utils/responsive";

const getIcon = (type) => {
  switch (type) {
    case "fail":
      return FailIcon;
    case "warning":
      return WarningIcon;
    case "upload":
      return UploadIcon;
    case "success":
      return SuccessIcon;
    default:
      return WarningIcon;
  }
};
const AlertDialog = ({ isOpen, setIsOpen, type, title, content, btnLabel, titleStyle, contentStyle, onNext, handleClose, onClick }) => {
  const handleOnClickButton = () => {
    onClick && onClick();
    if (onNext) onNext();
    else handleClose && handleClose();
  }
  
  return (
    <SimpleDialog isOpen={isOpen} setIsOpen={setIsOpen} handleClose={handleClose}>
      <Container>
        <MyTypography fontSize="24" bold style={{whiteSpace: "pre-line", ...titleStyle}}>
          {title}
        </MyTypography>

        <AlertIcon src={getIcon(type)} />

        <MyTypography fontSize="18" color="lightBlack" textAlign="center" style={{ ...contentStyle }}>
          {content}
        </MyTypography>

        <Space size="40px" />
        <MyButton onClick={handleOnClickButton} style={{ width: 300 }}>
          {btnLabel}
        </MyButton>
      </Container>
    </SimpleDialog>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AlertIcon = styled.img`
  height: 97px;
  width: 97px;
  margin-top: 23px;
  margin-bottom: 15px;
`;

export default AlertDialog;
