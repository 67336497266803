import { useFormik, Form, FormikProvider } from "formik";
import dayjs from "dayjs";
import StepContainer from "../StepContainer";
import { useTranslation } from "react-i18next";
import { updateQuote } from "../../../apis";
import * as Yup from "yup";
import { appearGlobalError } from "../../../requests/globalRequest";
import { useContext, useEffect } from "react";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import _ from "lodash";
import { next } from "../../../pages/Checkout";
import {
  getMobileNumYUP,
  getOnlyChineseEnglishStringYUP,
  getOnlyChineseStringYUP,
  getOnlyEnglishStringYUP,
} from "../../../utils/common";
import { useLocalStorageFormData } from "../../../hooks/useLocalStorageFormData";

const getInitialValues = (data, user=null) => {
  const { userInfo, contactUserInfo, deliveryInfo } = data?.userForm;

  const initialValues = {
    // customer_title: userInfo?.title || "",
    customer_engName: userInfo?.fullName?.en || "",
    // customer_chiName: userInfo?.fullName?.zh || "",
    // customer_idType: userInfo?.idType || "",
    // customer_idNum: userInfo?.idNumber || "",
    // customer_dateOfBirth:
    //   userInfo?.birthday || dayjs(Date.now()).format("DD/MM/YYYY"),
    // customer_companyName: userInfo?.companyName || "",
    // customer_jobPosition: userInfo?.companyPosition || "",
    customer_email: userInfo?.email || user?.email || "",
    customer_mobileNo: userInfo?.mobileNumber || "",
    customer_address1: userInfo?.mailingAddress[0] || "",
    customer_address2: userInfo?.mailingAddress[1] || "",
    customer_district: userInfo?.mailingDistrict || "",
    contact_isSameAsCustomerInfo: contactUserInfo?.isSameAsCustomerInfo || false,
    // contact_title: contactUserInfo?.title || "",
    contact_engName: contactUserInfo?.fullName?.en || "",
    // contact_chiName: contactUserInfo?.fullName?.zh || "",
    contact_email: contactUserInfo?.email || "",
    contact_mobileNo: contactUserInfo?.mobileNumber || "",
    delivery_method:
      deliveryInfo?.deliveryMethod ||
      (data?.isAllowedPickup ? "pickup" : "delivery"),
    delivery_isSameAsMailingAddress:
      deliveryInfo?.isSameAsMailingAddress || false,
    delivery_addressId: deliveryInfo?.deliveryAddressSelectedId || "",
    delivery_address1: deliveryInfo?.deliveryAddress?.[0] || "",
    delivery_address2: deliveryInfo?.deliveryAddress?.[1] || "",
    delivery_district: deliveryInfo?.deliveryDistrict || "",
    delivery_date: deliveryInfo?.deliveryDate || "",
    delivery_time: deliveryInfo?.deliveryTime || "",
    payment_method: "creditCard",
  };

  return initialValues;
};

const processUserInfo = (data) => {
  const isSameAsMailingAddress = data.delivery_isSameAsMailingAddress;
  const isSameAsCustomerInfo = data.contact_isSameAsCustomerInfo;

  // TODO
  let body = {
    userForm: {
      userInfo: {
        // title: data.customer_title,
        fullName: {
          en: data.customer_engName,
          // zh: data.customer_chiName,
        },
        // idType: data.customer_idType,
        // idNumber: data.customer_idNum,
        // birthday: data.customer_dateOfBirth,
        // companyName: data.customer_companyName,
        // companyPosition: data.customer_jobPosition,
        email: data.customer_email,
        mailingAddress: [data.customer_address1, data.customer_address2],
        mailingDistrict: data.customer_district,
        mobileNumber: data.customer_mobileNo,
      },
      contactUserInfo: {
        // title: isSameAsCustomerInfo ? data?.customer_title : data.contact_title,
        fullName: {
          en: isSameAsCustomerInfo ? data?.customer_engName : data.contact_engName,
          // zh: isSameAsCustomerInfo ? data?.customer_chiName : data.contact_chiName,
        },
        email: isSameAsCustomerInfo ? data?.customer_email : data.contact_email,
        mobileNumber: isSameAsCustomerInfo ? data?.customer_mobileNo : data.contact_mobileNo,
      },
      paymentMethod: data.payment_method,
      deliveryInfo: {
        deliveryMethod: data.delivery_method, // delivery, pickup
        deliveryAddressSelectedId: isSameAsMailingAddress
          ? ""
          : data.delivery_addressId,
        deliveryAddress: isSameAsMailingAddress
          ? [data.customer_address1, data.customer_address2]
          : [data.delivery_address1, data.delivery_address2],
        deliveryDistrict: isSameAsMailingAddress
          ? data.customer_district
          : data.delivery_district,
        deliveryDate: data.delivery_date,
        deliveryTime: data.delivery_time,
        isSameAsMailingAddress: isSameAsMailingAddress,
      },
    },
  };

  if (data.delivery_method === "pickup")
    delete body.userForm.deliveryInfo.isSameAsMailingAddress;

  return body;
};

const validationSchema = Yup.object().shape({
  // customer_title: Yup.string().required("error:required"),
  customer_engName: getOnlyEnglishStringYUP(),
  // customer_chiName: getOnlyChineseStringYUP(),
  customer_email: Yup.string()
    .email("error:invalidEmail")
    .required("error:required"),
  customer_mobileNo: getMobileNumYUP(),
  customer_address1: Yup.string().required("error:required"),
  customer_address2: Yup.string().required("error:required"),
  customer_district: Yup.string().required("error:required"),
  contact_isSameAsCustomerInfo: Yup.boolean(),
  // contact_title: Yup.string().when('contact_isSameAsCustomerInfo', {
  //   is: false,
  //   then: (schema) => schema.required("error:required")
  // }),
  contact_engName: Yup.string().when('contact_isSameAsCustomerInfo', {
    is: false,
    then: (schema) => getOnlyEnglishStringYUP()
  }),
  // contact_chiName: Yup.string().when('contact_isSameAsCustomerInfo', {
  //   is: false,
  //   then: (schema) => getOnlyChineseStringYUP()
  // }),
  contact_email: Yup.string().when('contact_isSameAsCustomerInfo', {
    is: false,
    then: (schema) => schema
      .email("error:invalidEmail")
      .required("error:required")
  }),
  contact_mobileNo: Yup.string().when('contact_isSameAsCustomerInfo', {
    is: false,
    then: (schema) => getMobileNumYUP()
  }),
  delivery_time: Yup.string().required("error:required"),
  delivery_date: Yup.string().required("error:required"),
  delivery_isSameAsMailingAddress: Yup.boolean(),
  delivery_address1: Yup.string().when('delivery_isSameAsMailingAddress', {
    is: false,
    then: (schema) => schema.required("error:required")
  }),
});

const getMinDate = (data, deliveryType) => {
  const keyPath =
    deliveryType === "pickup"
      ? "delivery.estimatePickupDay"
      : "delivery.estimatedDeliveryDay";
  const find = _.find(data, ["keyPath", keyPath]);
  return find ? dayjs(Date.now()).add(find.value, "day") : dayjs(Date.now());
};

const CheckoutForm = ({
  children,
  data,
  configData,
  step,
  setStep,
  refetch,
  skipValidate,
  setSkipValidate,
  autoSave,
  setAutoSave,
}) => {
  const { t } = useTranslation("checkout");
  const { globalState, globalDispatch } = useContext(GlobalContextStore);

  useEffect(() => {
    if (skipValidate) {
      formik.submitForm();
    }
  }, [skipValidate]);

  useEffect(() => {
    if (autoSave[1]) {
      formik.submitForm();
    }
  }, [autoSave]);

  // useEffect(() => {
  //   if (_.isEmpty(formik?.values?.delivery_date) && configData) {
  //     let minDate = getMinDate(configData, formik?.values?.delivery_method);
  //     formik.setFieldValue("delivery_date", minDate);
  //   }
  // }, []);

  const initialValues = getInitialValues(data, globalState?.userInfo);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: skipValidate ? null : validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload, { setErrors }) => {
      const body = processUserInfo(payload);
      console.log("submit userInfo", body);

      let minDate = getMinDate(configData, payload.delivery_method);
      if (
        !skipValidate &&
        minDate.isAfter(dayjs(payload.delivery_date, "DD/MM/YYYY"), "day") 
      ) {
        setErrors({ delivery_date: "error:invalidDate" });
      } else {
        try {
          const result = await updateQuote(body);
          console.log("update quote", result);
          if (result && result?.msg === "success") {
            setSkipValidate(false);
            setAutoSave({ ...autoSave, 1: false });
            next(2, step, setStep, data);
            refetch();
          } else {
            throw {
              code: result?.result?.code,
              msg: result?.result?.errorMessage,
            };
          }
        } catch (e) {
          console.log("save userinfo error - ", e);
          appearGlobalError(globalDispatch, e?.code, e?.msg, {
            type: "fail",
          });
        }
      }
    },
  });

  const { formValues } = useLocalStorageFormData({ 
    formik, 
    initialValues, 
    formKey: "checkoutFormV1",
  })

  useEffect(() => {
    // TODO: only trigger setValues when different from initialValues
    if (!_.isEmpty(formValues)) {
      formik.setValues(formValues);
    }
  }, [formValues])

  //check if form validate not pass
  useEffect(() => {
    const { isValid, isValidating, isSubmitting } = formik;
    if (!isValid && !isValidating && isSubmitting) {
      const newSteps = step.map((item) => {
        if (item.id === 1) return { ...item, isCompleted: false };
        else return item;
      });

      setStep(newSteps);
    }
  }, [formik?.isSubmitting, formik?.isValid, formik?.isValidating]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepContainer
          step={step}
          setStep={setStep}
          currentStep={step[0]} //TODO - hardcode for device only
          title={t("customerInformation")}
          onSave={() => formik.submitForm()}
        >
          {children}
        </StepContainer>
      </Form>
    </FormikProvider>
  );
};

export default CheckoutForm;
