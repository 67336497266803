import styled from "styled-components";
import { useState, useEffect } from "react";
import SimpleDialog from "../../../../components/CommonDialog/SimpleDialog";
import YellowBubbleCheckbox from "../../../../components/Checkboxs/YellowBubbleCheckbox";
import { Grid } from "@mui/material";
import MyTextField from "../../../../components/TextField";
import MyTypography from "../../../../components/Typography";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import CommonTextField from "../../../../components/Common/CommonTextField";
import MyButton from "../../../../components/Button";
import _ from "lodash";
import { scrollToHash } from "../../../../utils/common";
import {
  fetchLuckyNumber,
  holdLuckyNumber,
  searchLuckyNumber,
} from "../../../../apis";
import { fetchWithTimeout } from "../../../../utils/common";

const NewNumberDialog = ({ isOpen, setIsOpen, name }) => {
  const { t } = useTranslation(["checkout", "common"]);
  const [field, meta, helper] = useField(name);
  const [keyword, setKeyword] = useState();
  const [tempNumList, setTempNumList] = useState([]);
  const [selectedNumList, setSelectedNumList] = useState([]);
  const [error, setError] = useState(false);
  const [noResult, setNoResult] = useState(false);

  useEffect(() => {
    const getLuckyNumber = async () => {
      setNoResult(false);
      const res = await fetchLuckyNumber();

      setTempNumList([...field?.value?.order_newNumList, ...res]);
      return res;
    };

    if (isOpen) {
      getLuckyNumber();

      if (field?.value?.order_newNumList) {
        setSelectedNumList(field?.value?.order_newNumList);
      }
    }
  }, [isOpen]);

  const fetchSearchResult = async (num) => {
    try {
      if (num) {
        setNoResult(false);
        const { data } = await fetchWithTimeout(
          searchLuckyNumber({ number: num }),
          2000
        );

        if (data.length < 1) setNoResult(true);
        setTempNumList([...selectedNumList, ...data]);
      }
    } catch (e) {
      console.error("search number error - ", e);
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    const reg = new RegExp(/^\d+$/);

    if (val === "") {
      setKeyword("");
      fetchSearchResult("6");
    }

    if (reg.test(val)) {
      setKeyword(val);

      if (val.length >= 2) {
        fetchSearchResult(val);
      }
    }
  };

  const handleSelect = (num) => {
    setSelectedNumList([...selectedNumList, num]);
  };

  const handleDelete = (num) => {
    setSelectedNumList(selectedNumList.filter((val) => val !== num));
  };

  const handleSave = async () => {
    if (selectedNumList.length === field.value.order_newNumQty) {
      try {
        const body = {
          newNumberPoolIds: selectedNumList.map((item) => item._id),
        };
        const result = await holdLuckyNumber(body);

        if (result) {
          helper.setValue({
            ...field.value,
            order_newNumList: selectedNumList,
          });
          setIsOpen(false);
          setError(false);
        }
      } catch (e) {
        console.log("hold nubmer error", e);
      }
    } else {
      setError(true);
    }
  };

  return (
    <SimpleDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t("changeNumber")}
    >
      <Grid container spacing={3} id="newNumberDialog">
        {selectedNumList.map((num) => {
          return (
            <Grid item xs={6}>
              <YellowBubbleCheckbox
                label={num?.number}
                selected
                checked={true}
                onChange={(e) => handleDelete(num)}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          {error && (
            <MyTypography color="error">
              {t("warnPleaseChooseNumber", {
                num: field.value.order_newNumQty,
              })}
            </MyTypography>
          )}
        </Grid>
        <Grid item xs={12}>
          <CommonTextField
            placeholder="輸入心儀數字搜屬號碼"
            value={keyword}
            handleChange={(e) => handleSearch(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <MyTypography>請輸入至少兩位數字以搜索你的心儀號碼。</MyTypography>
        </Grid>
        <Grid item xs={12}>
          {noResult && (
            <MyTypography color="error">{t("noMatchingResult")}</MyTypography>
          )}
        </Grid>
        {tempNumList.map((num) => {
          return (
            <Grid item xs={6}>
              <YellowBubbleCheckbox
                label={num?.number}
                onChange={() => handleSelect(num)}
                checked={selectedNumList.indexOf(num) > -1}
                disabled={selectedNumList.indexOf(num) > -1}
              />
            </Grid>
          );
        })}

        <Grid container item xs={12} justifyContent="center">
          <MyButton type="secondary" style={{ width: "50%" }}>
            {t("refreshNumber")}
          </MyButton>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <MyButton style={{ width: "50%" }} onClick={handleSave}>
            {t("common:save")}
          </MyButton>
        </Grid>
      </Grid>
    </SimpleDialog>
  );
};
export default NewNumberDialog;
