import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DetailButton from "../../../components/Buttons/DetailButton";
import Form from "./Form";
import CardBase from "../../../components/Card/CardBase";
import MyTypography from "../../../components/Typography";

const SupportingDoc = () => {
  const { t } = useTranslation("checkout");
  return (
    <Form title={t("supportingDocument")}>
      <Grid item xs>
        <Container>
          <MyTypography>{t("supportingDocumentRemark")}</MyTypography>
          {/* <DetailButton content="hi" /> */}
        </Container>
      </Grid>
    </Form>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default SupportingDoc;
