import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import MyTypography from "../../../components/Typography";
import CardBase from "../../../components/Card/CardBase";
// import TextField from "../../../components/TextField";
import TextField from "../../../components/FormField/FormTextField";
import CheckboxGroup from "../../../components/CheckboxGroup";
import { Divider } from "@mui/material";
import Select from "../../../components/Select";
import { useTranslation } from "react-i18next";
import Form from "./Form";
import Checkbox from "../../../components/Checkbox";
import { useFormikContext } from "formik";

const ContactInformation = ({ viewOnly, isSameAsCustomerInfo }) => {
  const { t } = useTranslation(["checkout", "error"]);

  const ContactInfoForm = () => {
    return (
      <>
      {/* <Grid item xs={12} md={4} alignSelf={"stretch"}>
        <CheckboxGroup
          id="contact_title"
          title={t("title")}
          options={[
            { id: 1, value: "mr", label: t("mr") },
            { id: 2, value: "mrs", label: t("mrs") },
            { id: 3, value: "miss", label: t("miss") },
          ]}
          viewOnly={viewOnly}
          row
          required
        />
      </Grid> */}
      <Grid item xs={12} md={4} alignSelf={"stretch"}>
        <TextField
          id="contact_engName"
          placeholder={"Chan Tai Man"}
          title={t("englishFullName")}
          // subTitle={t("sameAsID")}
          viewOnly={viewOnly}
          required
        />
      </Grid>
      {/* <Grid item xs={12} md={4} alignSelf={"stretch"}>
        <TextField
          id="contact_chiName"
          placeholder={"陳大文"}
          title={t("chineseFullName")}
          subTitle={t("sameAsID")}
          viewOnly={viewOnly}
          required
        />
      </Grid> */}

      <Grid item xs={12} md={4} alignSelf={"stretch"}>
        <TextField
          id="contact_email"
          placeholder={t("email")}
          title={t("email")}
          viewOnly={viewOnly}
          required
        />
      </Grid>

      <Grid item xs={12} md={4} alignSelf={"stretch"}>
        <TextField
          id="contact_mobileNo"
          placeholder={t("mobileNo")}
          title={t("mobileNo")}
          viewOnly={viewOnly}
          required
        />
      </Grid>
      </>
    )
  }

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MyTypography fontSize="24" bold>
          {t("contactInformation")}
        </MyTypography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {
          !viewOnly && (
            <Grid item xs={12}>
              <Checkbox
                id="contact_isSameAsCustomerInfo"
                label={t("sameAsCustomerInfo")}
              />
            </Grid>
          )
        }
        {
          (!isSameAsCustomerInfo || viewOnly) && (
            <Grid item xs={12} container spacing={2}>
              <ContactInfoForm />
            </Grid>
          )
        }
      </Grid>
    </>
  );
};

export default React.memo(ContactInformation);
