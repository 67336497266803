import styled from "styled-components";
import AnimatedCard from "../AnimatedCard";
import BasicPlanInfo from "./BasicPlanInfo";
import Button from "../Button";
import TextButton from "../TextButton";
import CardTag from "../CardTag";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AdbIcon from "@mui/icons-material/Adb";
import theme from "../../theme";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const data2 = {
  _id: "646c4832352232a120be6d4e",
  status: "published",
  promotionTitle: {
    en: "Back to School Sale",
    zh: "返校優惠",
  },
  serviceName: {
    en: "Student Plan",
    zh: "學生配套",
  },
  mainImage: "/images/plan1.jpg",
  planName: {
    en: "Student Plus",
    zh: "學生增強版",
  },
  dataUsage: "20GB",
  discountedPrice: 25,
  originalPrice: 40,
  planId: "SP001",
  cardFront: [
    {
      image: "/images/plan1.jpg",
      description: {
        en: "Plan Features",
        zh: "方案特點",
      },
      sort: 1,
      _id: "646c4832352232a120be6d4f",
    },
    {
      image: "/images/plan1.jpg",
      description: {
        en: "Terms and Conditions",
        zh: "條款及細則",
      },
      sort: 2,
      _id: "646c4832352232a120be6d50",
    },
  ],
  cardBack: [
    {
      image: "/images/plan1.jpg",
      description: {
        en: "Extra Services",
        zh: "額外服務",
      },
      sort: 1,
      _id: "646c4832352232a120be6d51",
    },
  ],
  planDetails: [
    {
      description: {
        en: "Monthly Fee",
        zh: "月費",
      },
      sort: 1,
      _id: "646c4832352232a120be6d52",
    },
    {
      description: {
        en: "Data Usage",
        zh: "數據用量",
      },
      sort: 2,
      _id: "646c4832352232a120be6d53",
    },
  ],
  planDetailsInclude: [
    {
      description: {
        en: "Included in Plan",
        zh: "方案包含",
      },
      sort: 1,
      _id: "646c4832352232a120be6d54",
    },
  ],
  contractPeriodDetails: [
    {
      promotionTitle: {
        en: "12-Month Promotion",
        zh: "12個月優惠",
      },
      period: "12 months",
      discountedPrice: 25,
      originalPrice: 40,
      _id: "646c4832352232a120be6d55",
    },
    {
      promotionTitle: {
        en: "24-Month Promotion",
        zh: "24個月優惠",
      },
      period: "24 months",
      discountedPrice: 20,
      originalPrice: 35,
      _id: "646c4832352232a120be6d56",
    },
  ],
  quantityDescription: {
    en: "Quantity",
    zh: "數量",
  },
  quantityMin: 1,
  quantityMax: 5,
  freeVas: ["6158d7a15f1d9f2650e5b5c6"],
  freeVasMax: 1,
  paidVas: ["6158d7a15f1d9f2650e5b5c7"],
  tncs: ["6158d7a15f1d9f2650e5b5c8"],
  recommandPlans: ["6158d7a15f1d9f2650e5b5c9"],
  recommandProducts: ["6158d7a15f1d9f2650e5b5ca"],
  documents: [
    {
      key: "studentId",
      docType: "ID Card",
      docValue: "required",
      _id: "646c4832352232a120be6d57",
    },
    {
      key: "studentCard",
      docType: "Student Card",
      docValue: "optional",
      _id: "646c4832352232a120be6d58",
    },
  ],
  publishStartDate: "2023-04-01T00:00:00.000Z",
  publishEndDate: "2026-04-30T23:59:59.000Z",
  createdAt: "2023-05-23T04:59:30.802Z",
  updatedAt: "2023-05-23T04:59:30.802Z",
  __v: 0,
};

export const FeatureListItem = ({ icon, description }) => {
  return (
    <ListItem>
      <ListItemAvatar sx={{ minWidth: 36 }}>{icon}</ListItemAvatar>
      <ListItemText disableTypography sx={{ fontSize: theme.fontSize.body3 }}>
        {description}
      </ListItemText>
    </ListItem>
  );
};

const PlanCard = ({ type, handleClick, data, ...props }) => {
  const { t, i18n } = useTranslation(["home", "common"]);

  return (
    <AnimatedCard
      {...props}
      tag={data?.promotionTitle}
      renderFront={({ flip }) => {
        return (
          <>
            <BasicPlanInfo
              yellowTag={data?.flipCardLabel}
              icon={data?.flipCardIconImageUrl}
              title={data?.planName}
              subTitle={data?.localData}
              originalPrice={data?.originalPrice}
              discountedPrice={data?.discountedPrice}
              quantityDesc={t("common:month")}
            />
            <Divider />
            <List>
              {data &&
                data?.cardFront.map((item) => {
                  return (
                    <FeatureListItem
                      icon={<img src={item.imageUrl} />}
                      description={item.description[i18n.language]}
                    />
                  );
                })}
            </List>
            <div style={{ flex: 1 }} />
            <Button
              color="black"
              style={{ margin: `0px 20px` }}
              onClick={handleClick}
            >
              {t("selectPlan")}
            </Button>
            <TextButton
              textColor="#746b2f"
              style={{ width: "fit-content", alignSelf: "center" }}
              onClick={() => {
                flip && flip();
              }}
            >
              {t("viewPlan")}
            </TextButton>
          </>
        );
      }}
      renderBack={({ flip }) => {
        return (
          <>
            <CardTag>{t("mobilePhonePlan")}</CardTag>
            <List>
              {data &&
                data?.cardBack.map((item) => {
                  return (
                    <FeatureListItem
                      icon={<img src={item.imageUrl} />}
                      description={item.description[i18n.language]}
                    />
                  );
                })}
            </List>
            <TextButton
              textColor="#746b2f"
              style={{ width: "fit-content", alignSelf: "center" }}
              onClick={() => {
                flip && flip();
              }}
            >
              {t("hidePlan")}
            </TextButton>
          </>
        );
      }}
    />
  );
};

export default PlanCard;
