import StepTitle from "../../components/Plan/StepTitle";
import styled from "styled-components";
import Typography from "../../components/Typography";
import NumberInput from "../../components/NumberInput";
import Button from "../../components/Button";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { useNavigate } from "react-router-dom";

const NumberInputContainer = styled.div`
  border-radius: 25px;
  height: 120px;
  padding: 10px 20px;
  margin: 20px 0px;
  display: flex;
  align-items: center;

  @media ${breakpoint.desktop} {
    flex-direction: row;
    box-shadow: 0 3px 10px 0 #c9c9c9;
    justify-content: space-between;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    box-shadow: 0 0 10px 0 #dbbd00;
    justify-content: space-evenly;
  }
`;

const Container = styled.div`
  @media ${breakpoint.desktop} {
    margin: 0px 50px;
    margin-right: 100px;
  }

  @media ${breakpoint.mobile} {
    margin: 0px 30px;
  }
`;

const AddtoCardButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const QuantiyField = ({ step, stepId, id, setSteps, steps, onTitleClick, min, max }) => {
  const currentStep = _.find(steps, ["id", stepId]);
  const { t } = useTranslation(["mobile", "common"]);
  const navigate = useNavigate();
  const isMobile = WindowSize() === "mobile";
  const [field, meta, helpers] = useField(id);
  const { submitForm } = useFormikContext();

  const handleNumberOnChange = (number) => helpers.setValue(number);

  return (
    <div>
      <StepTitle
        title={t("quantity")}
        stepId={stepId}
        onClick={onTitleClick}
        currentStep={currentStep}
      />
      {currentStep?.isActive && (
        <Container>
          <NumberInputContainer>
            <Typography bold>{t("chooseNumber")}</Typography>
            <NumberInput value={field.value} onChange={handleNumberOnChange} min={min} max={max} />
          </NumberInputContainer>
          <AddtoCardButton>
            <Button
              color="black"
              type="submit"
              style={{ width: isMobile ? "100%" : 200 }}
              onClick={() => submitForm()}
            >
              {t("common:addToCart")}
            </Button>
          </AddtoCardButton>
        </Container>
      )}
    </div>
  );
};

export default QuantiyField;
