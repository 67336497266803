import { useEffect } from "react";
import styled from "styled-components";
import { Divider } from "@mui/material";
import NewNumber from "../newNumber";
import ExistingNumber from "../existingNumber";
import CommonCheckbox from "../../../../components/Common/CommonCheckbox"
import Space from "../../../../components/Space";
import { useFormikContext, useField } from "formik";
import { useTranslation } from "react-i18next";

const PlanDetail = ({ name, idx }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(`${name}.order_isAutoRenewal`);
  const { values } = useFormikContext();
  const totalPlanNum = values.order_list[idx].quantity;

  useEffect(() => {
    const isAutoRenewal = values.order_list[idx]?.isAutoRenewal;
    if (typeof isAutoRenewal === "boolean") {
      helpers.setValue(isAutoRenewal);
    } else {
      helpers.setValue(false);
    }
  }, []);

  return (
    <Container>
      <Divider />
      <Content>
        <NewNumber name={`${name}`} totalPlanNum={totalPlanNum} idx={idx}/>
        <ExistingNumber name={`${name}`} totalPlanNum={totalPlanNum} idx={idx}/>
      </Content>
      <Divider />
      <Space size="20px" />
      <CommonCheckbox
        label={t("autoRenewal")}
        checked={field.value}
        onChange={(e) => {
          helpers.setValue(e.target.checked);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  hegiht: auto;
  margin-top: 40px;
`;

const Content = styled.div`
  display: flex;
`;

export default PlanDetail;
