import CommonCheckbox from "./Common/CommonCheckbox";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const Checkbox = ({ disabled, id, label, ...props }) => {
  const { t } = useTranslation("error");
  const [field, meta, helpers] = useField(id);

  return (
    <CommonCheckbox
      key={`checkbox-${id}`}
      label={label || ""}
      onChange={(e) => {
        helpers.setValue(e.target.checked);
      }}
      checked={field?.value}
      disabled={disabled}
      error={meta?.error && t(meta?.error)}
      {...props}
    />
  );
};

export default Checkbox;
