import { useState } from "react";
import styled from "styled-components";
import Typography from "../Typography";
import CardTag from "../CardTag";
import theme from "../../theme";
import Price from "../Price/PriceText";
import PriceUnit from "../Price/PriceUnitText";
import { Divider } from "@mui/material";
import OriginalPrice from "../Price/OriginalPrice";
import MobileIcon from "../../assets/ic-mobilePlanCard.svg";
import DeviceIcon from "../../assets/ic-devicePlanCard.svg";
import { useTranslation } from "react-i18next";
import { GetWord, formatCurrency } from "../../utils/common";
import Icon from "../Icon";
import Space from "../Space";
import { ReactComponent as ZoomInIcon } from "../../assets/ic-zoomLens.svg";
import SimpleDialog from "../CommonDialog/SimpleDialog";
import _ from "lodash";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 14px 20px 28px;
  height: 38%;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 14px 20px 28px;
`;

const TrimText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 10px;
`;

const ZoomInImageContainer = styled.div`
  text-align: center;
  max-height: calc(100vh - 200px);
`;

const StyledCardTag = styled(CardTag)`
  max-width: 200px;
`;

const BasicPlanInfo = ({
  yellowTag,
  icon, //TODO - api return image url
  title,
  subTitle,
  originalPrice,
  discountedPrice,
  quantityDesc,
  enableZoomInIcon = false,
}) => {
  const { t, i18n } = useTranslation("common");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <>
      {!_.isEmpty(_.trim(yellowTag?.["en"])) && !_.isEmpty(_.trim(yellowTag?.["zh"])) ? (
        <StyledCardTag>{GetWord(yellowTag)}</StyledCardTag>
      ) : (
        <Space size="34px" />
      )}
      <Header>
        <HeaderLeft>
          <Typography bold fontSize={theme.fontSize.title4}>
            {GetWord(title)}
          </Typography>
          <TrimText color={theme.grey} bold fontSize="24" condensed>
            {GetWord(subTitle)}
          </TrimText>
        </HeaderLeft>
        {icon ? (
          <IconContainer>
            <Icon
              size={52}
              borderRadius={15}
              style={{ marginTop: -15 }}
              img={icon}
            >
              <img
                src={icon}
                style={{ width: 45, height: 45, borderRadius: 15 }}
              />
            </Icon>
            {enableZoomInIcon && (
              <ZoomInIcon
                onClick={() => setDialogIsOpen(true)}
                style={{ cursor: "pointer", marginTop: "15px" }}
              />
            )}
          </IconContainer>
        ) : (
          <img src={DeviceIcon} style={{ width: 80, height: 80 }} />
        )}
      </Header>
      <Divider />
      <PriceContainer>
        <Typography fontSize={24} condensed>
          HK$<Price fontSize={36}>{formatCurrency(discountedPrice)}</Price>
          <PriceUnit>/{quantityDesc}</PriceUnit>
        </Typography>
        {originalPrice && (
          <Typography color="secondary" fontSize={theme.fontSize.subTitle2}>
            {t("originalPrice")}
            <OriginalPrice textDecoration={"normal"}>
              {" "}
              HK$ {formatCurrency(originalPrice)}
            </OriginalPrice>
          </Typography>
        )}
      </PriceContainer>
      {dialogIsOpen && (
        <SimpleDialog
          isOpen={dialogIsOpen}
          setIsOpen={setDialogIsOpen}
          hasSpace={false}
          containerStyle={{ overflow: "hidden" }}
        >
          <ZoomInImageContainer>
            <img
              src={icon}
              alt={icon?.alt || "Product Image"}
              style={{ maxWidth: "100%", maxHeight: "inherit" }}
            />
          </ZoomInImageContainer>
        </SimpleDialog>
      )}
    </>
  );
};

const IconContainer = styled.div`
  width: 80px;
  // height: 120px;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
`;

export default BasicPlanInfo;
