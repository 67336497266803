import styled from "styled-components";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "../Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as DialogCloseIcon } from "../../assets/ic-dialogClose.svg";
import { breakpoint, WindowSize } from "../../utils/responsive";

const Container = styled.div`
  padding: 0 50px 56px 50px;
  overflow: auto;
  margin-top: 56px;
`;

const Closebutton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  cursor: pointer;

  @media ${breakpoint.desktop} {
    right: -35px;
    top: -52px;
  }

  @media ${breakpoint.mobile} {
    top: -60px;
    right: 0px;
  }
`;

const SimpleDialog = ({
  isOpen,
  setIsOpen,
  title,
  children,
  handleClose,
  titleProp = undefined,
  _renderTitle = undefined,
  hasSpace=true,
  containerStyle=null,
}) => {
  const isMobile = WindowSize() === "mobile";

  const onClose = () => {
    if(handleClose) handleClose()

    if(setIsOpen) setIsOpen(false)
  }
    
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      // style={{zIndex: 9999}}
      fullScreen={isMobile}
      sx={{
        "& .MuiDialog-container": {
          alignItems: isMobile ? "flex-end" : "center",
        }
      }}
      PaperProps={{
        style: {
          borderRadius: isMobile ? "0px 60px 0px 0px" : "0px 50px 0px 50px",
          overflowY: "unset",
          maxHeight: isMobile ? "90%" : "85%",
          width: isMobile ? "100%" : "fit-content",
          minWidth: isMobile ? "100%" : "650px",
          justifyContent: "center",
        },
      }}
    >
      <ButtonContainer onClick={onClose}>
        <Closebutton>
          <DialogCloseIcon />
        </Closebutton>
      </ButtonContainer>
      <Container style={{ ...containerStyle }}>
        {_renderTitle ? (
          _renderTitle()
        ) : titleProp ? (
          titleProp
        ) : (
          <Typography fontSize="24" bold>{title}</Typography>
        )}
        { hasSpace && <div style={{ height: 20 }} /> }
        {children}
      </Container>
    </Dialog>
  );
};

export default SimpleDialog;
