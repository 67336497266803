import { useState, useEffect, useRef, useContext, useMemo } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Header from "../pageComponents/device/Header";
import SKUForm from "../pageComponents/device/Form";
import ProductDetails from "../pageComponents/device/ProductDetails";
import ProductImages from "../pageComponents/device/ProductImages";
import ProductSpec from "../pageComponents/device/ProductSpec";
import { useTranslation } from "react-i18next";
import theme from "../theme";
import { breakpoint, size, pageMargin } from "../utils/responsive";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fetchCartItem, fetchDeviceProduct, addToCart, updateCartItem } from "../apis";
import { useParams, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import LoadingIndicator from "../components/LoadingIndicator";
import { GetWord } from "../utils/common";
import { appearGlobalError } from "../requests/globalRequest";
import { GlobalContextStore } from "../context/GlobalProvider";
import { gaAddToCart, gaOutOfStock, gaViewItem } from "../utils/ga";
import { gtmAddToCart, gtmOutOfStock, gtmViewItem } from "../utils/gtm";

const themeForBreakpoint = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: size.desktop,
			lg: size.largeDesktop,
			xl: 1536,
		},
	},
});

const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	
	@media ${breakpoint.desktop} {
		margin: 30px;
		margin-bottom: 50px;
	}

	@media ${breakpoint.mobile} {
		margin: 30px 0;
		margin-bottom: 50px;
	}
`;

const PageWrapper = styled.div`
	width: 100%;
	max-width: 1440px;
`

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 60px;

  @media ${breakpoint.mobile} {
	margin: 0 30px;
	margin-bottom: 30px;
  }
`;

const TabContentContainer = styled.div`
  overflow-wrap: anywhere;

  img, video {
	max-width: 100%;
	max-height: auto;
  }

  @media ${breakpoint.mobile} {
    margin: 0 30px;
  }
`;

const ProductInfo = ({ data }) => {
	//TODO: handle empty data
	return (
		<TabContentContainer>
			<div dangerouslySetInnerHTML={{ __html: GetWord(data) }} />
		</TabContentContainer>
	);
};

const Device = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const editId = searchParams.get('editId') || null;
	const isEdit = !!editId;

	const { t } = useTranslation(["device", "error"]);

	const { globalDispatch } = useContext(GlobalContextStore);
	const productDetailsRef = useRef(null);
	const [selectedVariant, setSelectedVariant] = useState(null);

	const [deviceQuery, cartQuery] = useQueries({
		queries: [
			{
				querykey: ["device", id],
				queryFn: () => fetchDeviceProduct(id),
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['cartId', editId],
				queryFn: () => fetchCartItem(editId),
				enabled: isEdit,
			}
		],
	});

	const deviceData = deviceQuery?.data?.data || undefined;
	const cartData = cartQuery?.data?.data || undefined;

	useEffect(() => {
		if (deviceData) {
			gaViewItem(deviceData);
			gtmViewItem(deviceData);
		}
	}, [deviceData])

	// console.log('==deviceData', deviceData);
	// console.log('==cartData', cartData);

	const onClickRefButton = () => {
		productDetailsRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const handleLastOptionItemOnChange = (selectedOptions) => {
		if (deviceData?.productType === "standard") return;

		const variantItem = deviceData?.variationProducts.find((item) => {
			return item.options.every((option, index) => {
				const { optionKey, valueKey } = option;

				const queryOption = deviceData?.productOptions?.[index];
				const selectedOption = (queryOption.option?.optionKey === optionKey) ? 
											queryOption?.values.find((item) => item._id === selectedOptions[index].value[0])
												: null

				return selectedOption && selectedOption.value === valueKey;
			})
		})

		// Unknown error for cannot find related variantItem
		if (!variantItem) {
			return appearGlobalError(
				globalDispatch, 
				"Unexpected Error", 
				"",				// result?.result?.errorMessage, 
				{ type: "fail", onClick: () => navigate("/") }
			)
		}

		setSelectedVariant(variantItem);
	};

	const handleAddToCart = async (payload, gaProductPayload) => {
		const triggerOutOfStockGA = (result) => {
			if (result?.result?.code && result?.result?.code === "PRODUCT_OUT_OF_STOCK") {
				const gaPayload = {
					page_url: window.location.href,
					destination_page_url: "NA",
				}
				gaOutOfStock(gaPayload);
				gtmOutOfStock(gaPayload);
			}
		}

		const result = editId ? await updateCartItem(editId, payload) : await addToCart(payload);
		if (result && result?.msg === 'success') {
			gaAddToCart(gaProductPayload);
			gtmAddToCart(gaProductPayload);
			navigate("/shoppingcart");
		}
		else {
			console.log('==error', result)
			appearGlobalError(
				globalDispatch, 
				result?.result?.code ? t(result?.result?.code, { ns: "error" }) : "Error", 
				"",				// result?.result?.errorMessage, 
				{ 
					type: "fail", 
					onClose: () => {
						triggerOutOfStockGA(result);
					}
				}
			)
		}
	};

	if (deviceQuery?.isFetching) return <LoadingIndicator />;

	if (deviceQuery?.isError || !deviceData) {
		console.log("error - ", deviceQuery?.isError);
		return <Navigate to={'/'} />
	}

	return (
		<ThemeProvider theme={themeForBreakpoint}>
			<PageContainer>
				<PageWrapper>
					<UpperContainer>
						<Header label={t("electronicDevices")} productId={id} />
						<Grid
							container
							gap={"51.5px"}
							flexDirection={{ xs: "column", md: "row" }}
							flexWrap={"nowrap"}
						>
							<ProductImages images={selectedVariant?.imageUrls || deviceData?.imageUrls} isOptionSelected={selectedVariant} />
							<SKUForm
								device={deviceData}
								selectedVariant={selectedVariant}
								cartPayload={cartData}
								onClickInfoButton={onClickRefButton}
								handleLastOptionItemOnChange={handleLastOptionItemOnChange}
								onSubmit={handleAddToCart}
								isEdit={isEdit}
							/>
						</Grid>
					</UpperContainer>
					<ProductDetails
						productDetailsRef={productDetailsRef}
						tabs={[
							{
								label: t("productDetails"),
								render: () => (
									<ProductInfo data={deviceData?.description} />
								),
							},
							{
								label: t("productSpecification"),
								render: () => (
									<ProductInfo data={deviceData?.specification} />
								),
							},
						]}
					/>
				</PageWrapper>
			</PageContainer>
		</ThemeProvider>
	)
};

export default Device;
