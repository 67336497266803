import styled from "styled-components";
import { Grid } from "@mui/material";
import MyTypography from "../../../components/Typography";
import { Divider } from "@mui/material";
import Select from "../../../components/Select";
import BubbleSelect from "../../../components/Selects/BubbleSelect";
import { useTranslation } from "react-i18next";
import Form from "./Form";
import DatePicker from "../../../components/DateTimePicker/DatePicker";
import { useState, useContext, useEffect } from "react";
import EditBubbleCheckbox from "../../../components/Checkboxs/EditAddressCheckbox";
import AddIcon from "../../../assets/ic-add.svg";
import { breakpoint } from "../../../utils/responsive";
import { useField, useFormikContext } from "formik";
import DistrictSelct from "../../../components/Selects/DistrictSelect";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import EditAddressDialog from "../userInfo/EditAddressDialog";
import Space from "../../../components/Space";
import dayjs from "dayjs";
import _ from "lodash";
import { disableSunday } from "../../../utils/common";
import Checkbox from "../../../components/Checkbox";
import theme from "../../../theme";
import { WindowSize } from "../../../utils/responsive";

const PICKUP_LIST = [
  {
    _id: "pickup_cwd",
    title: "cwbSelfPick",
    value: "cwbSelfPick",
    address: ["香港銅鑼灣商業客戶服務中心", "XXX樓XX室"],
    district: "wanChai",
  },
];

const TIME_SLOT = [
  {
    title: "9am - 1pm",
    value: "am",
  },
  {
    title: "2pm - 6pm",
    value: "pm",
  },
];

const DeliveryInformation = ({
  viewOnly,
  setSkipValidate,
  configData,
  calendarData,
  allowDelivery = false,
  allowPickup = false,
}) => {
  const { t } = useTranslation(["checkout", "error"]);
  const [isOpen, setIsOpen] = useState(false);
  const [disablePm, setDisablePm] = useState(false);
  const { globalState, globalDispatch } = useContext(GlobalContextStore);
  const [field, meta, helper] = useField("delivery_method");
  const { values, errors, setValues, submitForm } = useFormikContext();

  const isMobile = WindowSize() === "mobile";

  //disable pm timeslot for delivery only support am on sat
  useEffect(() => {
    if (dayjs(values.delivery_date, "DD/MM/YYYY").day() === 6) {
      setDisablePm(true);
      if (values.delivery_time === "pm")
        setValues({
          ...values,
          delivery_time: "",
        });
    } else setDisablePm(false);
  }, [values.delivery_date]);

  // default choose address if only one address
  useEffect(() => {
    if (_.isEmpty(values.delivery_address1)) {
      const defaultAddr =
        values.delivery_method === "pickup"
          ? PICKUP_LIST[0]
          : globalState?.userInfo?.shippingAddresses?.length === 1
          ? globalState?.userInfo?.shippingAddresses[0]
          : null;

      if (!_.isEmpty(defaultAddr)) {
        setValues({
          ...values,
          delivery_addressId: defaultAddr?._id,
          delivery_address1: defaultAddr?.address[0],
          delivery_address2: defaultAddr?.address[1],
          delivery_district: defaultAddr?.district,
        });
      }
    }
  }, []);

  const handleChangeMethod = (isChanged) => {
    //dont clear field when select same option
    if (isChanged) {
      setValues({
        ...values,
        delivery_addressId: "",
        delivery_address1: "",
        delivery_address2: "",
        delivery_district: "",
        delivery_date: "",
      });
    }
  };

  const handleAutoSubmit = () => {
    setSkipValidate(true);
  };

  const _renderError = () => {
    return errors?.delivery_address1 ? (
      <Grid item xs={12} marginTop={"-20px"}>
        <MyTypography fontSize={12} color={theme.error2}>
          {t(errors?.delivery_address1)}
        </MyTypography>
      </Grid>
    ) : null;
  };

  const _renderPickUp = () => {
    return viewOnly ? (
      _renderPreviewAddress()
    ) : (
      <Grid container item xs={12} spacing={2}>
        {PICKUP_LIST.map((item) => {
          return (
            <Grid item xs={12} md={4}>
              <EditBubbleCheckbox
                disableEdit
                handleAutoSubmit={handleAutoSubmit}
                checked={field.value === "pickup"}
                addressId={item._id}
                addressOne={item.address[0]}
                addressTwo={item.address[1]}
                district={item.district}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const _renderDelivery = () => {
    return viewOnly ? (
      _renderPreviewAddress()
    ) : (
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Checkbox
            id="delivery_isSameAsMailingAddress"
            label={t("sameAsAddress")}
          />
        </Grid>
        {!values.delivery_isSameAsMailingAddress && (
          <Grid container item spacing={2}>
            {globalState?.userInfo?.shippingAddresses?.map((item) => {
              return (
                <Grid item xs={12} md={4}>
                  <EditBubbleCheckbox
                    handleAutoSubmit={handleAutoSubmit}
                    addressId={item._id}
                    setIsOpen={setIsOpen}
                    addressOne={item.address[0]}
                    addressTwo={item.address[1]}
                    district={item.district}
                    handleDelete={() => submitForm()}
                  />
                </Grid>
              );
            })}

            {(!globalState?.userInfo?.shippingAddresses ||
              globalState?.userInfo?.shippingAddresses?.length < 3) && (
              <Grid item xs={12} md={4}>
                <AddNewAddress
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <img src={AddIcon} />
                  <Space size="20px" />
                  <MyTypography fontSize="16">
                    {t("addNewAddress")}
                  </MyTypography>
                </AddNewAddress>
              </Grid>
            )}
          </Grid>
        )}

        <EditAddressDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAutoSubmit={handleAutoSubmit}
        />
      </Grid>
    );
  };

  const _renderPreviewAddress = () => {
    return (
      <div style={{ marginLeft: 30, marginTop: 10 }}>
        <MyTypography fontSize="16">
          {t("address")}
          <span style={{ color: theme.orange, marginLeft: "5px" }}>*</span>
        </MyTypography>

        <Space size="8px" />
        <MyTypography fontSize="18">{values?.delivery_address1}</MyTypography>
        <MyTypography fontSize="18">{values?.delivery_address2}</MyTypography>
        <Space size="30px" />
        <MyTypography fontSize="16">
          {t("district")}
          <span style={{ color: theme.orange, marginLeft: "5px" }}>*</span>
        </MyTypography>
        <Space size="8px" />
        <MyTypography fontSize="18">
          {t(values?.delivery_district)}
        </MyTypography>
      </div>
    );
  };

  const disableHoliday = (date, excludeSat = false) => {
    const formatedDate = dayjs(date).format("DD/MM/YYYY");
    const day = date.day();
    const nonShipDay = day === 0 || calendarData?.includes(formatedDate);
    const nonWorkingDay = day === 0 || day === 6 || calendarData?.includes(formatedDate);

    return excludeSat ? nonWorkingDay : nonShipDay;
  };

  const getMinData = () => {
    const keyPath =
      field.value.indexOf("pickup") !== -1
        ? "delivery.estimatePickupDay"
        : "delivery.estimatedDeliveryDay";
    let remain = _.find(configData, ["keyPath", keyPath])?.value;
    let minDate = dayjs(Date.now());

    while (remain > 0){
      minDate = minDate.add(1, "day");

      if(!disableHoliday(minDate, true)){
        remain--;
      }
    }
    
    return minDate;
  };

  const getAvailableDeliveryMethod = () => {
    const options = [];
    if (allowPickup) {
      options.push({
        id: "pickup",
        label: t("cwbSelfPick"),
        tag: t("freeCashCoupon"),
        value: "pickup",
      });
    }

    if (allowDelivery) {
      options.push({ id: "delivery", label: t("delivery"), value: "delivery" });
    }

    return options;
  };

  return (
    <>
      <Grid item xs>
        <BubbleSelect
          viewOnly={viewOnly}
          id="delivery_method"
          onChange={handleChangeMethod}
          options={getAvailableDeliveryMethod()}
          bubbleWidth={"350px"}
          disableUnchecked
          verticalAlign={isMobile}
          typographyProps={{
            style: {
              whiteSpace: "pre-line",
            }
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MyTypography fontSize="24" bold>
          {t("shippingAddress")}
        </MyTypography>
      </Grid>

      {field.value.indexOf("pickup") !== -1
        ? _renderPickUp()
        : field.value.indexOf("delivery") !== -1
        ? _renderDelivery()
        : null}

      {_renderError()}

      <Grid item xs={12}>
        <DatePicker
          id="delivery_date"
          title={t("timeOfReceipt")}
          viewOnly={viewOnly}
          minDate={getMinData()}
          shouldDisableDate={disableHoliday}
          required
        />
        {!viewOnly && <Space size="15px" />}
        <Select
          id="delivery_time"
          options={disablePm ? [TIME_SLOT[0]] : TIME_SLOT}
          viewOnly={viewOnly}
          required
          hintText={t("selectTime")}
        />
      </Grid>
    </>
  );
};

const AddNewAddress = styled.div`
  cursor: pointer;
  border: 2px dashed #c9c9c9;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${breakpoint.desktop} {
    height: 98%;
    min-height: 180px;
  }

  @media ${breakpoint.mobile} {
    height: 200px;
  }
`;

export default DeliveryInformation;
