import styled from "styled-components";
import MyTypography from "../Typography";
import { Grid, Divider } from "@mui/material";
import theme from "../../theme";
import UpIcon from "../../assets/ic-upYellow.svg";
import DownIcon from "../../assets/ic-downYellow.svg";
import DeleteIcon from "../../assets/ic-delete.svg";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import Space from "../Space";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import CardWrapper from "../Card/CardWrapper";
import ItemFieldDetails from "./ItemFieldDetails";

const ItemTableRow = ({
  showDetail,
  setShowDetail,
  renderDetail,
  colWidth,
  icon,
  row,
  header,
  onDelete,
  iconNeedDotDecoration = false,
  type,
  extraIconWidth=false,
  extraWidthForButton=false,
  useHtml,
}) => {
  const { t } = useTranslation(["orderContentItem", "common"]);

  const isHtml = (value) => {
    return /<[a-z][\s\S]*>/i.test(value);
  }

  const _renderDesktop = () => {
    const IconWidth = "90px";
    const DeleteIconWidth = "7%";

    return (
      <div
        style={{
          display: "flex",
          width: icon ? "100%" : undefined,
          marginBottom: 30,
          padding: "0px 50px",
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: icon ? "flex-start" : "center",
          }}
        >
          {colWidth.map((item, index) => {
            const isMainTitle = index === 0 && icon;
            const extraIconSpace = index === 0 && (icon || extraIconWidth);

            return (
              <div
                style={{
                  width: item,
                  display: "flex",
                  gap: '30px',
                }}
              >
                {extraIconSpace && (
                  <div style={{ width: IconWidth }}>
                    {icon && (
                      iconNeedDotDecoration ? (
                        <Icon size={90} borderRadius={30}>
                          {icon}
                        </Icon>
                      ) : (<div>{icon}</div>)
                    )}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <MyTypography
                    fontSize={isMainTitle ? 22 : (index === 0 ? 16 : 18)}
                    bold={isMainTitle}
                    color={isMainTitle ? "#6a6a6a" : undefined}
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    {isHtml(row[index]) && useHtml ? <ItemFieldDetails item={row[index]} /> : row[index]}
                  </MyTypography>
                  {isMainTitle && setShowDetail && renderDetail && (
                    <div style={{ width: 'fit-content' }}>
                      <Space size={'20px'} />
                      <DetailButton onClick={() => setShowDetail(!showDetail)}>
                        {showDetail ? (
                          <img src={UpIcon} />
                        ) : (
                          <img src={DownIcon} />
                        )}
                        <Space width="5px" />
                        <MyTypography color={theme.dirtyYellow}>
                          {t("details")}
                        </MyTypography>
                      </DetailButton>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {(onDelete || extraWidthForButton) && (
          <div
            style={{
              width: DeleteIconWidth,
            }}
          >
            {onDelete && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                  // flexWrap: 'wrap',
                }}
                onClick={onDelete}
              >
                <img src={DeleteIcon} alt={"Delete"} style={{ marginRight: 5 }} />
                <MyTypography fontSize="18" bold>
                  {t("delete", { ns: "common" })}
                </MyTypography>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const _renderMobile = () => {
    if (header) {
      return (
        <CardWrapper style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {icon &&
              <>
              {iconNeedDotDecoration ? (
                <div style={{ maxWidth: "90px", marginRight: '20px' }}>
                  <Icon size={70} borderRadius={25}>
                    {icon}
                  </Icon>
                </div>
              ) : (
                <div>{icon}</div>
              )}
              </>
            }
            <MyTypography fontSize="22" color="#6a6a6a" bold>
              {row[0]}
            </MyTypography>
          </div>
          <Space size="38px" />
          {row.map((item, idx) => {
            if (idx !== 0) {
              return (
                <MobileItem style={{ marginBottom: 16 }}>
                  <MyTypography fontSize="16" color="#333" style={{ whiteSpace: "nowrap" }}>
                    {header[idx]}
                  </MyTypography>
                  <MyTypography fontSize="16" color="#333" textAlign="right">
                    {isHtml(item) ? <ItemFieldDetails item={item} /> : item}
                  </MyTypography>
                </MobileItem>
              );
            }
          })}

          {setShowDetail && renderDetail && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DetailButton onClick={() => setShowDetail(!showDetail)}>
                {showDetail ? <img src={UpIcon} /> : <img src={DownIcon} />}
                <Space width="5px" />
                <MyTypography fontSize="16" color={theme.dirtyYellow} bold>
                  {t("details")}
                </MyTypography>
              </DetailButton>
              <Space size="20px" />
            </div>
          )}

        </CardWrapper>
      );
    } else {
      return (
        <CardWrapper style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 24 }}>
          <MobileItem style={{ flexDirection: "column", gap: '8px' }}>
            <MyTypography fontSize="16" color="#333">
              {row?.[0]}
            </MyTypography>
            <MyTypography fontSize="16" color="#333">
              {type === 'product' ? row?.[4] : row?.[1]}
            </MyTypography>
          </MobileItem>
        </CardWrapper>
      );
    }
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
};

const ItemImage = styled.div`
  box-shadow: 0 3px 10px 0 #c9c9c9;
  background-color: #fff;
  height: 90px;
  width: 90px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

const MobileItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default ItemTableRow;
