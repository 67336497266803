import PageHeader from "../components/Header";
import { useEffect, useState } from "react";
import StepTab from "../components/Tab/StepTab";
import { Divider } from "@mui/material";
import Step3 from "../pageComponents/checkout/OrderList";
import Step2 from "../pageComponents/checkout/UserInfo";
import Step1 from "../pageComponents/checkout/TNC";
import { useTranslation } from "react-i18next";
import StepGroup from "../components/StepGroup";
import Space from "../components/Space";
import PreviewOrder from "../pageComponents/checkout/previewOrder";
import { useQueries } from "@tanstack/react-query";
import { fetchCheckout, fetchDeliveryConfig, fetchTNC, fetchCalendarConfig } from "../apis";
import LoadingIndicator from "../components/LoadingIndicator";
import CheckoutForm from "../pageComponents/checkout/CheckoutForm";
import { useLocation, useNavigate } from "react-router-dom";
import TotalPriceBar from "../components/BottomBar/TotalPriceBar";
import DevicePriceBar from "../components/BottomBar/DevicePriceBar";
import { gaAddPaymentInfo, gaAddShippingInfo } from "../utils/ga";
import { gtmAddShippingInfo } from "../utils/gtm";
import { formatQuoteProductForGA } from "../utils/common";

// const STEP_LIST = [
//   {
//     id: 1,
//     isActive: false,
//     isCompleted: false,
//   },
//   {
//     id: 2,
//     isActive: false,
//     isCompleted: false,
//   },
//   {
//     id: 3,
//     isActive: true,
//     isCompleted: false,
//   },
// ];

const DEVICE_ONLY_STEP_LIST = [
  {
    id: 1,
    isActive: true,
    isCompleted: false,
    onSaveAction: (data) => {
      const gaPayload = data?.items.map((item, index) => formatQuoteProductForGA({ ...item, sort: index })) || [];
      gaAddShippingInfo(gaPayload);
      gtmAddShippingInfo(gaPayload);
    },
  },
  {
    id: 2,
    isActive: false,
    isCompleted: true,
    // onSaveAction: gaAddPaymentInfo,
  },
];

export const next = (id, step, setStep, data={}) => {
  const newSteps = step.map((item) => {
    if (item.id === id - 1) {
      if (item.isActive !== (item.id === id)) {
        item?.onSaveAction && item.onSaveAction(data);
      }
      return { ...item, isActive: item.id == id, isCompleted: true };
    }
    else return { ...item, isActive: item.id === id };
  });
  setStep(newSteps);
};

const Checkout = () => {
  const { t } = useTranslation("checkout");
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(DEVICE_ONLY_STEP_LIST);
  const [isPreview, setIsPreview] = useState(false);
  const [autoSave, setAutoSave] = useState({
    1: false,
    2: false,
  });
  const [acceptedTNC, setAcceptedTNC] = useState({
    savePersonalInformation: [],
    directSaleThisServices: [],
    directSaleMyClubHKT: [],
    productTncs: [],
  });
  const [quoteData, tncData, configData, calendarData] = useQueries({
    queries: [
      {
        queryKey: ["fetchQuote"],
        queryFn: fetchCheckout,
      },
      {
        queryKey: ["fetchTnc"],
        queryFn: fetchTNC,
      },
      {
        queryKey: ["fetchDeliveryConfig"],
        queryFn: fetchDeliveryConfig,
      },
      {
        queryKey: ["fetchCalendarConfig"],
        queryFn: fetchCalendarConfig,
      },
    ],
  });

  useEffect(() => {
    if (!location?.state?.enableAccess) {
      navigate("/shoppingcart");
    }
  }, []);

  if (quoteData.isLoading || tncData.isLoading || configData.isLoading || calendarData.isLoading) {
    return <LoadingIndicator />;
  }

  if (quoteData.isError || tncData.isError || configData.isError || calendarData?.isError) {
    //TODO - api error handle
    console.log(
      "error - ",
      quoteData.errors,
      tncData.errors,
      configData.erorrs
    );
  }

  return (
    //TODO
    <div>
      <PageHeader>
        {isPreview ? t("preivewOrder") : t("registerAndPayment")}
      </PageHeader>
      <Space size="30px" />
      {isPreview ? (
        <PreviewOrder
          viewOnly={isPreview}
          setIsPreview={setIsPreview}
          data={quoteData?.data?.data}
          acceptedTNC={acceptedTNC}
          setAcceptedTNC={setAcceptedTNC}
        />
      ) : (
        <div style={{ marginBottom: "50px" }}>
          <StepGroup data={step} />
          <Divider />
          {/* <Step1 setStep={setStep} step={step} /> */}
          <Step2
            setStep={setStep}
            step={step}
            data={quoteData?.data?.data}
            tncData={tncData?.data?.data}
            configData={configData?.data?.data}
            calendarData={calendarData?.data?.data}
            refetch={quoteData?.refetch}
            acceptedTNC={acceptedTNC}
            setAcceptedTNC={setAcceptedTNC}
            autoSave={autoSave}
            setAutoSave={setAutoSave}
          />
          <Step3
            setStep={setStep}
            step={step}
            data={quoteData?.data?.data}
            refetch={quoteData?.refetch}
            setIsPreview={setIsPreview}
            autoSave={autoSave}
            setAutoSave={setAutoSave}
          />

          <DevicePriceBar
            totalPrice={quoteData?.data?.data?.calculatedOrderPrice}
            onClick={() => {
              // setAutoSave({ ...autoSave, 2: true })
              setIsPreview(true)
            }}
            isCheckout
            disabled={step.some((i) => i.isCompleted === false)}
            titleShowTotal
          />
        </div>
      )}
    </div>
  );
};

export default Checkout;
