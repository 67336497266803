import styled from "styled-components";
import SimpleDialog from "../../../../components/CommonDialog/SimpleDialog";
import MyTypography from "../../../../components/Typography";
import MyButton from "../../../../components/Button";
import MyTextField from "../../../../components/TextField";
import Space from "../../../../components/Space";
import CommonCheckbox from "../../../../components/Common/CommonCheckbox"
import Select from "../../../../components/Select";
import YellowBubbleCheckbox from "../../../../components/Checkboxs/YellowBubbleCheckbox";
import { Grid } from "@mui/material";
import { useState } from "react";
import DatePicker from "../../../../components/DateTimePicker/DatePicker";
import SwitchButton from "../../../../components/SwitchButton";
import { Reddit } from "@mui/icons-material";
import { useField, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import WarnText from "../../../../components/Text/WarnText";
import { INITIAL_PORTIN } from ".";
import { verifyPortinOtp, requestPortinOtp } from "../../../../apis";

const TIME_SLOT = [
  {
    title: "9am - 1pm",
    value: "am",
  },
  {
    title: "2pm - 6pm",
    value: "pm",
  },
];

const DEFAULT_TNC = {
  isOpen: false,
  title: "",
  content: "",
};

const ExistingNumberDialog = ({ isOpen, setIsOpen, name }) => {
  const { t } = useTranslation(["checkout", "common"]);
  const [isOpenTNC, setIsOpenTNC] = useState(DEFAULT_TNC);
  const [field, meta, helpers] = useField(name);

  const PROVIDER_LIST = [
    {
      title: t("cmhk"),
      value: "cmhk",
    },
    {
      title: t("htcl"),
      value: "htcl",
    },
    {
      title: t("smt"),
      value: "smt",
    },
    {
      title: t("others"),
      value: "others",
    },
  ];

  const onChange = (fieldId, val) => {
    helpers.setValue({ ...field.value, [fieldId]: val });
  };

  const handleClose = () => {
    helpers.setValue(INITIAL_PORTIN);
  };

  const handleRequestOtp = async () => {
    try {
      const result = await requestPortinOtp({ number: field?.value?.number });
      if (result) {
        //TODO - set send msg success
      }
    } catch (e) {
      console.log("requestOtp error - ", e);
      //TODO - error handle
    }
  };

  const handleVerifyOtp = async () => {
    //TODO - test
    //TODO - set send msg success
    setIsOpen(false);
    // try {
    //   const result = await verifyPortinOtp({
    //     number: field?.value?.number,
    //     otp: field?.value?.otp,
    //   });
    //   if (result && result?.data?.verified === true) {
    //     //TODO - set send msg success
    //     setIsOpen(false);
    //   }
    // } catch (e) {
    //   console.log("requestOtp error - ", e);
    //   //TODO - error handle
    // }
  };
  return (
    <SimpleDialog
      title={t("mobileNumberPorted")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleClose={handleClose}
    >
      <div style={{ display: "flex" }}>
        <MyTextField
          id={`${name}.number`}
          placeholder={t("enterMobileNumber")}
        />
        <Space width="30px" />
        <MyButton onClick={handleRequestOtp}>{t("verificationCode")}</MyButton>
      </div>
      <Space size="15px" />
      <MyTextField
        id={`${name}.otp`}
        placeholder={t("enterVerificationCode")}
        title={t("verificationCode")}
      />
      <Space size="15px" />
      <CommonCheckbox label={t("failToProvideCode")} />
      <Space size="15px" />
      <Select
        id={`${name}.networkProvider`}
        title={t("currentNetworkOperator")}
        placeholder={t("selectOption")}
        options={PROVIDER_LIST}
        required
      />
      <Space size="30px" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YellowBubbleCheckbox
            label={t("postpaidService")}
            circle
            checked={field.value?.simType === "postpaid"}
            onChange={() => onChange("simType", "postpaid")}
          />
        </Grid>
        <Grid item xs={6}>
          <YellowBubbleCheckbox
            label={t("prepaidSIM")}
            circle
            checked={field.value?.simType === "prepaid"}
            onChange={() => onChange("simType", "prepaid")}
          />
        </Grid>
      </Grid>

      <Space size="30px" />

      {field.value?.simType === "postpaid" ? (
        <SwitchButton
          title="TNC"
          checked={field.value?.acceptedPostpaidTnc === true}
          onClick={() => {
            onChange("acceptedPostpaidTnc", !field.value?.acceptedPostpaidTnc);
          }}
        />
      ) : (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <YellowBubbleCheckbox
                label={t("common:yes")}
                circle
                checked={field.value?.realNameRegistered === true}
                onChange={() => onChange("realNameRegistered", true)}
              />
            </Grid>
            <Grid item xs={6}>
              <YellowBubbleCheckbox
                label={t("common:no")}
                circle
                checked={field.value?.realNameRegistered === false}
                onChange={() => onChange("realNameRegistered", false)}
              />
            </Grid>
          </Grid>
          <Space size="20px" />
          {field.value?.realNameRegistered ? (
            <SwitchButton
              title="TNC2"
              checked={field.value?.acceptedPrepaidTnc === true}
              onClick={() => {
                onChange(
                  "acceptedPrepaidTnc",
                  !field.value?.acceptedPrepaidTnc
                );
              }}
            />
          ) : (
            <WarnText label="warning text" />
          )}
        </div>
      )}

      <Space size="20px" />
      <DatePicker
        id={`${name}.effectiveDate`}
        title={t("effectiveDateTime")}
        required
      />
      <Space size="10px" />
      <Select
        id={`${name}.effectiveTime`}
        placeholder={t("selectOption")}
        options={TIME_SLOT}
        required
      />
      <Space size="30px" />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <MyButton style={{ width: "80%" }} onClick={handleVerifyOtp}>
          {t("common:save")}
        </MyButton>
      </div>

      {isOpenTNC.isOpen && (
        <SimpleDialog
          isOpen={isOpenTNC.isOpen}
          handleClose={() => setIsOpenTNC(DEFAULT_TNC)}
          title={"hi"}
        >
          <MyTypography>{"content"}</MyTypography>
        </SimpleDialog>
      )}
    </SimpleDialog>
  );
};

export default ExistingNumberDialog;
