import styled from "styled-components";
import MyTypography from "../Typography";
import StepGroup from "../StepGroup";
import { Grid } from "@mui/material";

const StepTab = ({ title, steps }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ padding: "40px 100px" }}
    >
      <Grid item>
        <MyTypography fontSize="24" bold color="#868475">
          {title}
        </MyTypography>
      </Grid>
      <Grid item>
        <StepGroup data={steps} />
      </Grid>
    </Grid>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 100px;
`;

export default StepTab;
