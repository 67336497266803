import styled from "styled-components";
import { Grid } from "@mui/material";
import { Divider } from "@mui/material";
import MyTypography from "../Typography";
import MobileIcon from "../../assets/ic-mobilePlanCard.svg";
import DeleteIcon from "../../assets/ic-delete.svg";
import ItemTable from "./ItemTable";
import PlanDetail from "../../pageComponents/shoppingcart/PlanDetail";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveUI } from "../../utils/responsive";
import { GetWord, formatCurrency } from "../../utils/common";
import _ from "lodash";
import CardBase from "../Card/CardBase";
import CardWrapper from "../Card/CardWrapper";

const PlanContent = ({
  renderDetail,
  orderRecord,
  onEdit,
  onDelete,
  remarkProps,
  iconNeedDotDecoration,
  data,
  idx,
  cardBaseStyle,
  extraWidthForButton=false,
}) => {
  const { t } = useTranslation(["orderContentItem", "common"]);
  const [showDetail, setShowDetail] = useState(true);

  const getContract = () => {
    const contractPeriodId = data?.options?.contractPeriodId;
    const contractInfo = data?.plan?.contractPeriodDetails;

    return _.find(contractInfo, ["_id", contractPeriodId]);
  };

  return (
    <CardBase style={{ ...cardBaseStyle }}>
      <ItemTable
        type={"plan"}
        editId={data?._id}
        itemId={data?.plan?._id}
        colWidth={["45%", "11%", "11%", "11%", "11%", "11%"]}
        header={[
          t("item", { index: idx + 1 }),
          t("selectedDetails"),
          t("contractPeriod"),
          t("quantity"),
          t("monthlyFee"),
          t("total"),
        ]}
        row={[
          GetWord(data?.plan?.planName),
          GetWord(data?.plan?.localData),
          `${getContract()?.period} ${t("common:months")}`,
          data?.quantity,
          `HK$ ${formatCurrency(data?.discountedSubtotal / data?.quantity, 1)}`,
          `HK$ ${formatCurrency(data?.discountedSubtotal, 1)}`,
        ]}
        icon={<img src={MobileIcon} />}
        onEdit={onEdit}
        onDelete={onDelete}
        setShowDetail={setShowDetail}
        showDetail={showDetail}
        renderDetail={renderDetail}
        orderRecord={orderRecord}
        iconNeedDotDecoration={iconNeedDotDecoration}
        extraWidthForButton={extraWidthForButton}
      />
      {showDetail && renderDetail}
      {onDelete && (
        <ResponsiveUI
          mobile={
            <>
              <div style={{ display: 'flex' }}>
                <Divider style={{ width: "100%" }} />
              </div>
              <DeleteButton onClick={() => onDelete && onDelete()}>
                <img src={DeleteIcon} style={{ marginRight: 5 }} />
                <MyTypography fontSize="18" bold>
                  {t("delete", { ns: "common" })}
                </MyTypography>
              </DeleteButton>
            </>
          }
          desktop={<div />}
        />
      )}
      {remarkProps !== undefined && remarkProps}
    </CardBase>
  );
};

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 8px 0 #9b9b9b;
  border-radius: 25px;
  margin-bottom: 30px;

  overflow: hidden;
  padding: 20px 30px;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 25px 0;
`;

export default PlanContent;
