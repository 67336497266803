import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoint } from "../utils/responsive";
import { fetchTutorial } from "../apis";
import { useQueries } from "@tanstack/react-query";

import PageHeader from "../components/Header";
import Tab from "../components/Tab";
import LoadingIndicator from "../components/LoadingIndicator";

import TabContainer from "../pageComponents/shoppingGuide/TabContainer";

const Container = styled.div`
    padding: 50px 100px;

    @media ${breakpoint.mobile} {
        padding: 30px 0;
    }
`

const ShoppingGuide = () => {
    const { t, i18n } = useTranslation("shoppingGuide");

    const [tutorialData] = useQueries({
        queries: [
            {
                queryKey: ["tutorial"],
                queryFn: fetchTutorial,
            },
        ],
    });

    if (tutorialData?.isLoading) {
        return <LoadingIndicator />;
    }
    
    if (tutorialData?.isError) {
        //TODO - api error handle
        console.log("error - ", tutorialData?.isError);
    }

    const findDataByKey = (key) => {
        if (tutorialData?.data?.data?.length > 0) {
            return tutorialData?.data?.data?.find((item) => item?._id === key);
        } else return [];
    }

    return (
        <div>
            <PageHeader>{t("shoppingGuide")}</PageHeader>
            <Container>
                <Tab 
                    tabs={[
                        { label: t("account"), render: () => <TabContainer locale={i18n.language} key={'account'} data={findDataByKey('account')}  /> },
                        { label: t("delivery"), render: () => <TabContainer locale={i18n.language} key={'delivery'} data={findDataByKey('delivery')}  /> },
                        { label: t("payment"), render: () => <TabContainer locale={i18n.language} key={'payment'} data={findDataByKey('payment')}  /> },
                    ]}
                    tabStyle={{ fontSize: "16px" }}
                    tabGap={"35px"}
                />
            </Container>
        </div>
    )
}



export default ShoppingGuide;