import { createContext, useReducer } from "react";
import GlobalReducer from "../reducers/globalReducer";
import { useState, useEffect } from "react";
import { fetchUserInfo, getAccessToken } from "../apis";
import { UPDATE_USER_INFO, UPDATE_INIT, SHOW_COOKIE_CONSENT } from "../actions/globalAction";
import { getCookie } from "../utils/common";

const globalInitState = {
  globalError: null,
  userInfo: {},
  init: false,
  showCookieConsent: false,
  cookieConsentHeight: 0,
};

export const GlobalContextStore = createContext(globalInitState);

const GlobalProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(GlobalReducer, {
    ...globalInitState,
  });

  useEffect(() => {
    try {
      const fetcher = async () => {
        const accessToken = await getAccessToken();

        if (accessToken) {
          const result = await fetchUserInfo();
          if (result) {
            globalDispatch({
              type: UPDATE_USER_INFO,
              userInfo: result?.data,
              init: true,
            });
          }
        } else {
          globalDispatch({ type: UPDATE_INIT, init: true });
        }
      };

      fetcher();
    } catch (e) {
      console.log("fetch user info error", e);
    }
  }, []);

  /** Check whether showing cookie consent */
  useEffect(() => {
    const isUserAccepted = getCookie("cookieConsent") ? true : false;
    if (!isUserAccepted) {
      globalDispatch({ type: SHOW_COOKIE_CONSENT });
    }
  }, [])

  return (
    <GlobalContextStore.Provider value={{ globalState, globalDispatch }}>
      {children}
    </GlobalContextStore.Provider>
  );
};
export default GlobalProvider;
