import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import IconButton from '@mui/material/IconButton';
import { ReactComponent as KeyboardArrowLeftIcon } from '../../assets/ic-arrowLeft.svg';
import { ReactComponent as KeyboardArrowRightIcon } from '../../assets/ic-arrowRight.svg';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import useWindowSize from '../../hooks/useWindowSize';
import theme from '../../theme';
import { breakpoint } from '../../utils/responsive';
import { gaProductCategoryClick } from '../../utils/ga';
import { gtmProductCategoryClick } from '../../utils/gtm';

const Container = styled.div`
    display: flex; 
    flexDirection: row; 
    justify-content: flex-start;
    align-items: center;
    max-width: 100vw;
`

const CustomButton = styled.div`
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
`

const TabsContainer = styled(CustomButton)`
    display: flex; 
    flexDirection: row; 
    background-color: #fffad9;
    // overflow:auto;
    // overflow-x:hidden;

    @media ${breakpoint.desktop}{
        width: 50%;
    }

    @media ${breakpoint.mobile}{
        width: 100%;
    }
`

const TabContainer = styled(CustomButton)`
    color: #000;
    min-height: 40px;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 1;
    ${props => props.selected && `
        background: ${theme.blackGradientColor};
        color: #fff;
        text-shadow: 0.1em 0.1em 0.2em grey;
        // min-height: 40px;
    `}
`

const Label = styled(Typography)`
    text-align: center;
    font-size: ${theme.fontSize.textButton}px;
    font-weight: 600;
    
    ${props => props.selected && `
        && {
            color: #fff;
            text-shadow: 0.1em 0.1em 0.2em grey;
            font-size: ${theme.fontSize.textButton2}px;
        }
    `}

    @media ${breakpoint.mobile}{
        && {
            font-size: 14px;
        }
    }
`

function Tab({ data, style, selected, setSelected, navigate }) {

    const device = useWindowSize();

    const itemRef = useRef({})

    const scrollToPosition = (i) => {
        itemRef.current[i]?.scrollIntoView({ inline: "center", behavior: 'smooth' })
    }

    const executeLeftScroll = () => {
        const target = selected ? selected - 1 : 0;
        scrollToPosition(target)
        setSelected(target)
    }
    const executeRightScroll = () => {
        const max = data.length - 1;
        const target = selected >= max ? max : selected + 1;
        scrollToPosition(target)
        setSelected(target)
    }

    const handleOnListItemClick = (i) => {
        // GA tracking
        const current_page_url = window.location.href;
        const destination_page_url = new URL(current_page_url);
        destination_page_url.searchParams.set('service', data[i].value);
        const gPayload = {
            page_url: current_page_url,
            destination_page_url: destination_page_url.href,
        }
        gaProductCategoryClick(gPayload)
        gtmProductCategoryClick(gPayload)

        setSelected(i)
        navigate && navigate(`/?service=${data[i].value}`, { replace: true });
        scrollToPosition(i)
    }

    if (!device) return null

    if (device === 'desktop') {
        return (
            <TabsContainer style={style}>
                {
                    data.map((item, index) => {
                        const selectedTab = selected === index
                        return (
                            <TabContainer
                                key={index}
                                ref={i => itemRef.current[index] = i}
                                selected={selectedTab}
                                onClick={() => handleOnListItemClick(index)}
                            >
                                <Label selected={selectedTab}>{item?.label}</Label>
                            </TabContainer>
                        )
                    })
                }
            </TabsContainer>
        )
    }

    return (
        <Container style={style}>
            <IconButton aria-label="left" onClick={executeLeftScroll}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <TabsContainer>
                {
                    data.map((item, index) => {
                        const selectedTab = selected === index
                        return (
                            <TabContainer
                                key={index}
                                ref={i => itemRef.current[index] = i}
                                selected={selectedTab}
                                onClick={() => handleOnListItemClick(index)}
                            >
                                <Label selected={selectedTab}>{item?.label}</Label>
                            </TabContainer>
                        )
                    })
                }
            </TabsContainer>
            <IconButton aria-label="right" onClick={executeRightScroll}>
                <KeyboardArrowRightIcon />
            </IconButton>
        </Container>
    )
}

export default Tab

Tab.propTypes = {
    data: PropTypes.array.isRequired,
    mode: PropTypes.oneOf(["mobile", "desktop"])
};