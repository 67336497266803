import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import VIPHome from "./pages/VIPHome";
import Device from "./pages/Device";
import Mobile from "./pages/Mobile";
import Login from "./pages/Login";
import ShoppingCart from "./pages/ShoppingCart";
import Profile from "./pages/Profile";
import Checkout from "./pages/Checkout";
import ContactUs from "./pages/ContactUs";
import ShoppingGuide from "./pages/ShoppingGuide";
import NotFound from "./pages/NotFound";
import { GlobalContextStore } from "./context/GlobalProvider";
import { fetchUserInfo } from "./apis";
import { updateUserInfo } from "./requests/globalRequest";
import ProcessingPayment from "./pages/ProcessingPayment";
import _ from "lodash";
import LoadingIndicator from "./components/LoadingIndicator";
import { useTranslation } from "react-i18next";

function CustomRoutes({ isAuthenticated }) {
  const { i18n } = useTranslation("navBar");

  const { globalState, globalDispatch } = useContext(GlobalContextStore);

  const RedirectContactUs = ({ children }) => {
    // redirect to contact us for non-whitelist user after login

    const location = useLocation();
    if (location?.state?.from && location?.state?.from === "/login") {
      const auth = !_.isEmpty(globalState.userInfo);
      const whiteListUser = auth
        ? !_.isEmpty(globalState?.userInfo?.company)
        : false;

      if (auth && !whiteListUser) {
        return <Navigate to="/contactUs" replace />;
      }
    }
    return children;
  }

  const RequireAuth = ({ children }) => {
    const auth = !_.isEmpty(globalState.userInfo);
    const whiteListUser = auth
      ? !_.isEmpty(globalState?.userInfo?.company)
      : false;

    const location = useLocation();

    if (auth) {
      return whiteListUser ? (
        children
      ) : (
        <Navigate to="/contactUs" replace />
      )
    } else {
      return <Navigate to={"/login"} replace={true} state={{ from: location }} search={location?.search || ""} />
    }
  };

  const RequireVip = ({ vip, normal }) => {
    const auth = !_.isEmpty(globalState.userInfo);
    const vipUser = auth
      ? globalState?.userInfo?.company?.companyType === "vip"
      : false;
    return auth && vipUser ? vip : normal;
  };

  const RequireLang = (props) => {
    const { path, language} = props

    // const location = useLocation();
    
    if (i18n.language !== language) {
      localStorage.setItem("language", language);
      i18n.changeLanguage(language);
    }

    // let originPath = location.pathname.replace(`/${path}`, "");
    // if (originPath.length === 0) {
    //   originPath = '/'
    // }

    return <RedirectContactUs><RequireVip vip={<VIPHome />} normal={<Home />} /></RedirectContactUs>
    // return <Navigate to={'/'} state={{ from: location, ...location.state }} search={location?.search || ""} />
  };

  if (!globalState?.init) {
    return <LoadingIndicator />;
  }

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<RedirectContactUs><RequireVip vip={<VIPHome />} normal={<Home />} /></RedirectContactUs>}
      />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/contactUs" element={<ContactUs />} />
      <Route exact path="/shoppingGuide" element={<ShoppingGuide />} />
      <Route exact path="/404" element={<NotFound />} />

      <Route
        exact
        path="/mobile/:id"
        element={
          <RequireAuth>
            <Mobile />
          </RequireAuth>
        }
      />
      <Route
        path="/device/:id"
        element={
          <RequireAuth>
            <Device />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/shoppingcart"
        element={
          <RequireAuth>
            <ShoppingCart />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/checkout"
        element={
          <RequireAuth>
            <Checkout />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/processingPayment"
        element={
          <RequireAuth>
            <ProcessingPayment />
          </RequireAuth>
        }
      />
      <Route path="/en/" element={<RequireLang language={'en'} path={'en'} />} />
      <Route path="/tc/" element={<RequireLang language={'zh'} path={'tc'} />} />
      {/* <Route path="/en/" element={<Navigate to="/en" />} />
      <Route path="/tc/*" element={<Navigate to="/tc" />} /> */}
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}

export default CustomRoutes;
