import MyTypography from "../components/Typography";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const scrollToHash = (id) => {
  document
    .getElementById(id)
    .scrollIntoView({ inline: "center", behavior: "smooth" });
};

export const GetWord = (word) => {
  let lang = localStorage.getItem("language");
  if (lang === "zh" || lang === "en") return word?.[lang];
  else return word?.["en"];
};

export function formatCurrency(value = 0, precision = 1) {
  return value.toLocaleString("zh-HK", {
    minimumFractionDigits: value === 0 ? 0 : precision,
    maximumFractionDigits: precision,
  });
}

export function formatDate(date = null, format = "DD/MM/YYYY") {
  if (date === null || date === "" || date === undefined) return "—/—/——";
  return dayjs(date).format(format);
}

export function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

export function genRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

export const CommonPriceText = ({
  priceSize,
  currencySize,
  price,
  originalPrice,
}) => {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <MyTypography fontSize={currencySize}>HK$</MyTypography>
        <MyTypography fontSize={priceSize} bold>
          {price}
        </MyTypography>
        <MyTypography fontSize={currencySize}>/月</MyTypography>
      </div>
      {originalPrice && (
        <MyTypography fontSize="11" color="#a7a7a7">
          原價 <del>HK${originalPrice}</del>
        </MyTypography>
      )}
    </div>
  );
};

export const fetchWithTimeout = async (fetcher, timeLimit) => {
  let timeoutHandle;

  const timeoutPromise = new Promise((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () => reject(new Error("fetcher timeout call error")),
      timeLimit
    );
  });

  return Promise.race([fetcher, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

export const getMobileNumYUP = () => {
  return Yup.number()
    .typeError("error:invalidMobile")
    .test("len", "error:invalidMobile", (val) => val.toString().length === 8)
    .required("error:required");
};

export const getOnlyEnglishStringYUP = () => {
  const reg = /^[A-Za-z\s]+$/;
  return Yup.string()
    .matches(reg, "error:onlyEnglishLetters")
    .required("error:required");
};

export const getOnlyChineseEnglishStringYUP = () => {
  const reg = /[\u4e00-\u9fa5a-zA-Z]/g;

  return Yup.string()
    .matches(reg, "error:onlyChineseOrEnglishLetters")
    .required("error:required");
};

export const getOnlyChineseStringYUP = () => {
  // TODO: include space character?
  const reg = /^[\u4e00-\u9fa5]+$/;

  return Yup.string()
    .matches(reg, "error:onlyChineseLetters")
    .required("error:required");
}

export const disableSunday = (date) => {
  const day = date.day();

  return day === 0;
};

export const isValidForPayAgain = (status) => {
  return ["paymentFailure"].includes(status);
};

export const getItemColumnWidth = (type="product") => {
  if (type === "plan") return ["40%", "12%", "12%", "12%", "12%", "12%"];
  if (type === "product") return ["48%", "26%", "10%", "16%"];
  if (["delivery", "discount"].includes(type)) return  ["48%", "36%", "16%"]
  return ["45%", "20%", "15%", "20%"];
}

export const getCookie = (key) => {
  const item = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return item ? item.pop() : undefined;
}

export const setCookie = (props) => {
  const { key, value, maxAge=undefined } = props;

  let cookieString = `${key}=${value}; path=/;`;
  if (maxAge) cookieString += ` max-age=${maxAge};`;

  document.cookie = cookieString;
}

export const getGAFormattedProduct = (item, showDiscount=false) => {
  let formattedProduct = {
    item_id: item?.productSku || item?.id || item?._id || "NA", // TODO
    item_name: item?.productName?.en || item?.productName?.zh || "NA", // TODO
    index: !isNaN(item?.sort) ? item.sort : "NA",
    item_brand: item?.brandName?.en || item?.brandName?.zh || "NA",
    item_category: item?.productCategory || "NA",
    price: item?.variant
            ? item?.variant?.sellingPrice || item?.variant?.markedPrice || item?.variant?.originalPrice || 0
              : item?.sellingPrice || item?.markedPrice || item?.originalPrice || 0,
    quantity: item?.quantity || 1,

    item_variant: item?.variant ? item?.variant?.options.map((i) => i.valueKey).toString() : "NA",

    vas_id: item?.paidVas?.map((vas) => vas?.vasId || vas?._id) || "NA",
    freegift_id: item?.freeVas?.map((vas) => vas?.vasId || vas?._id) || "NA",
  }

  if (showDiscount) {
    const discount = item?.pricePerItem ? (item?.pricePerItem - item?.discountedPricePerItem) : (item?.variant?.markedPrice - formattedProduct?.price) || 0;
    formattedProduct["discount"] = discount && discount > 0 ? discount : 0;
  }

  Array(10).fill().forEach((e, i) => {
    if (item?.paidVas?.[i]) {
      const { name, price, discountedPrice, originalPrice } = item?.paidVas?.[i];
      formattedProduct[`vas_option${i + 1}`] = `${name?.en || name?.zh || "NA"},${price || discountedPrice || originalPrice || 0}`
    } else {
      formattedProduct[`vas_option${i + 1}`] = "NA";
    }

    if (item?.freeVas?.[i]) {
      const { name } = item?.freeVas?.[i];
      formattedProduct[`freegift_option${i + 1}`] = `${name?.en || name?.zh || "NA"}`
    } else {
      formattedProduct[`freegift_option${i + 1}`] = "NA";
    }
  })

  return formattedProduct;
}

export const getGAFormattedProductList = (products=[], listInfo={}, showDiscount=false) => {
  const payload = products.filter((item) => item?.type !== "delivery").map((product, index) => ({ 
    ...getGAFormattedProduct(product, showDiscount),
    index,
    ...listInfo
  }));

  return payload;
}

export const formatQuoteProductForGA = (item) => {
  return {
    ...item?.product,
    type: item?.type,
    quantity: item?.quantity,
    freeVas: item?.options?.freeVas || [],
    paidVas: item?.options?.paidVas || [],
    variant: item?.options?.variationProduct,
    sort: item?.sort,
    discountedSubtotal: item?.discountedSubtotal,
  }
}

export const getGATotalValue = (items) => {
  return items.reduce((acc, item) => {
    const { variant, sellingPrice, markedPrice, originalPrice, paidVas, quantity=1 } = item;

    const price = variant ? variant?.sellingPrice || variant?.markedprice || variant?.originalPrice : sellingPrice || markedPrice || originalPrice;
    const vasPrice = paidVas ? paidVas.reduce((acc, vas) => acc + (vas?.price || vas?.discountedPrice || vas?.originalPrice), 0) : 0;

    return acc + ((price + vasPrice) * quantity);
  }, 0);
}