import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyTypography from "../../components/Typography";
import SimpleDialog from "../../components/CommonDialog/SimpleDialog";
import Button from "../../components/Button";
import ShoppingBagZh from "../../assets/ic-1010ShoppingBag-zh.svg";
import ShoppingBagEn from "../../assets/ic-1010ShoppingBag-en.svg";
import { GetWord } from "../../utils/common";
import { WindowSize } from "../../utils/responsive";

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const GreetingDialog = (props) => {
    const { isOpen, setIsOpen } = props;

    const { t } = useTranslation(["home", "common"]);
    const isMobile = WindowSize() === "mobile";

    const Title = ({ text }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: isMobile ? undefined : '394px' }}>
                    <MyTypography fontSize={24} mfontSize={24} bold breakSpaces style={{ textAlign: 'center' }}>
                        {text}
                    </MyTypography>
                </div>
            </div>
        )
    }

    return (
        <SimpleDialog
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
            titleProp={<Title text={t("home:dialogText")} />}
        >
            <ContentContainer>
                <div style={{ marginBottom: '30px' }}>
                    <img src={GetWord({ zh: ShoppingBagZh, en: ShoppingBagEn })} alt="Welcome" style={{ width: '90px', height: '90px' }} />
                </div>
                <Button onClick={() => setIsOpen(e => !e)} fullWidth>{t('common:ok')}</Button>
            </ContentContainer>
        </SimpleDialog>
    )
}

export default GreetingDialog;