import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Typography from "../../../../components/Typography";
import theme from "../../../../theme";
import { ReactComponent as EditIcon } from "../../../../assets/ic-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/ic-delete.svg";

const IconWrapper = styled.div`
    cursor: pointer;
    height: fit-content;
`;

const FieldItem = ({ title="", value="" }) => {
    return (
        <Grid container item md flexDirection={"column"} gap={2} justifyContent={'space-between'}>
            <Typography color={theme.lightBlack} style={{ wordBreak: 'keep-all' }}>{title}</Typography>
            <Typography fontSize={18} color={theme.lightBlack} style={{ whiteSpace: 'nowrap' }}>{value}</Typography>
        </Grid>
    )
}

const InfoRecord = (props) => {
    const { data, type, onClickEdit=null, onClickDelete=null } = props;

    const { t } = useTranslation(['profile', 'common']);

    const isClientInfo = type === 'clientInfo';

    return (
        <Grid container flexDirection={{ xs: 'column-reverse', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'flex-end' }}>
            <Grid xs={10} item container spacing={'30px'}>
                {/* <FieldItem title={t('profile:accountProfile.title')} value={t(`common:${data?.title}`)} /> */}
                <FieldItem title={t('profile:accountProfile.name')} value={data?.name?.en} />
                {/* <FieldItem title={t('profile:accountProfile.chiFullName')} value={data?.name?.zh} /> */}
                <FieldItem title={t(isClientInfo ? 'profile:accountProfile.billingEmailAddress' : 'profile:accountProfile.email')} value={data?.email} />
                {/* <FieldItem title={t(isClientInfo ? 'profile:accountProfile.deliveryMobileNumber' : 'profile:accountProfile.phoneNo')} value={data?.mobile} /> */}
                <FieldItem title={t('profile:accountProfile.phoneNo')} value={data?.mobile} />
            </Grid>
            { (onClickEdit || onClickDelete) && (
                <Grid xs item container justifyContent={'flex-end'} columnGap={1}>
                    { onClickEdit && <IconWrapper><EditIcon onClick={() => onClickEdit && onClickEdit()} /></IconWrapper> }
                    { onClickDelete && <IconWrapper><DeleteIcon onClick={() => onClickDelete && onClickDelete()} /></IconWrapper> }
                </Grid>
            )}
        </Grid>
    )
}

export default InfoRecord;