import { useContext, useLayoutEffect, useEffect, useState } from "react";
import PageHeader from "../components/Header";
import styled from "styled-components";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useQueries } from "@tanstack/react-query";
import { breakpoint, size } from "../utils/responsive";
import { GlobalContextStore } from "../context/GlobalProvider";
import { Grid } from "@mui/material";
import Tab from "../components/Tab";
import AccountInfoComponent from "../pageComponents/profile/accountInfo";
import PendingDocument from "../pageComponents/profile/pendingDocument";
import OrderInfo from "../pageComponents/profile/orderInfo";
import { fetchUserOrders } from "../apis";
import LoadingIndicator from "../components/LoadingIndicator";

const themeForBreakpoint = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: size.desktop,
            lg: size.largeDesktop,
            xl: 1536,
        },
    },
});

const PageContainer = styled.div`
    display: flex;
    justify-content: center;

	@media ${breakpoint.desktop} {
		margin: 30px;
		margin-bottom: 50px;
	}

	@media ${breakpoint.mobile} {
		margin: 30px 0;
		margin-bottom: 50px;
	}
`;

const PageWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
`;

const Profile = () => {
    const { globalState, globalDispatch } = useContext(GlobalContextStore);
    const { t } = useTranslation('profile', { keyPrefix: 'title' });

    const [tab, setTab] = useState(0);

    const [orderData] = useQueries({
        queries: [
            {
                queryKey: ["fetchUserOrders"],
                queryFn: fetchUserOrders,
            },
        ],
    })

    useLayoutEffect(() => {
        // onLoad profile page check localStorage to remain profile tab active
        const profileTab = localStorage.getItem('profileTab');
        if (profileTab) {
            setTab(profileTab === 'accountInfo' ? 1 : 0);
            localStorage.removeItem('profileTab');
        }
    }, [])

    const handleAfterAction = () => {
        // After any action (e.g. edit/delete item), remain profile tab active
        localStorage.setItem('profileTab', 'accountInfo');
    }

    if (orderData.isLoading) {
        return <LoadingIndicator />;
    }

    if (orderData.isError) {
        //TODO - api error handle
        console.log("error - ", orderData.isError);
    }
    
    return (
        <ThemeProvider theme={themeForBreakpoint}>
            <PageHeader>{t('myAccount')}</PageHeader>{" "}
            <PageContainer>
                <PageWrapper>
                    <Tab 
                        // TODO: counting of tabitem
                        tabs={[
                            // { label: `${t('pendingDocument')} (${10})`, render: () => <PendingDocument /> },
                            { 
                                label: `${t('orderDetails')} (${orderData?.data?.data.length || 0})`, 
                                render: () => <OrderInfo data={orderData?.data?.data} globalDispatch={globalDispatch} onAfterAction={handleAfterAction} /> 
                            },
                            { 
                                label: t('accountProfile'), 
                                render: () => <AccountInfoComponent data={globalState?.userInfo} globalDispatch={globalDispatch} onAfterAction={handleAfterAction} /> 
                            },
                        ]}
                        initialTabIndex={tab}
                        tabStyle={{
                            fontSize: "18px",
                        }}
                        tabGap={"35px"}
                    />
                </PageWrapper>
            </PageContainer>
        </ThemeProvider>
    )

}

export default Profile;