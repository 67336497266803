import styled from "styled-components";
import Form from "./Form";
import { useTranslation } from "react-i18next";
import TNCItem from "../tnc/TncItem";
import { Divider } from "@mui/material";
import { Grid } from "@mui/material";
import MyTypography from "../../../components/Typography";
import _ from "lodash";
import Space from "../../../components/Space";
import { useEffect, useState } from "react";
import { GetWord } from "../../../utils/common";
import { breakpoint } from "../../../utils/responsive";

const PersonalInformationStatement = ({
  data,
  acceptedTNC,
  setAcceptedTNC,
}) => {
  const { t } = useTranslation("checkout");
  const [directSale, setDirectSale] = useState([]);

  useEffect(() => {
    if (data) {
      const filter = data?.filter((tnc) => {
        return (
          (tnc.keyPath === "tnc.directSaleThisServices" ||
            tnc.keyPath === "tnc.directSaleMyClubHKT") &&
          tnc.value
        );
      });

      setDirectSale(filter);
    }
  }, [data]);

  return (
    <Form title={t("personalInformationStatement")}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {data &&
        _.filter(data, ["keyPath", "tnc.savePersonalInformation"])?.map(
          (item) => {
            return (
              item.value && (
                <Grid item xs={12}>
                  <TNCItem
                    id={item?.value?._id}
                    keyPath={item?.keyPath?.replace("tnc.", "")}
                    title={item?.value?.title}
                    buttonLabel={item?.value?.shortDescription}
                    detail={item?.value?.description}
                    acceptedTNC={acceptedTNC}
                    setAcceptedTNC={setAcceptedTNC}
                  />
                </Grid>
              )
            );
          }
        )}

      {directSale.length > 0 && (
        <Grid item xs={12}>
          <Title>
            <MyTypography fontSize="20" bold>
              {GetWord(directSale?.[0]?.value?.title)}
            </MyTypography>
          </Title>

          {directSale?.map((item) => {
            return (
              item.value && (
                <TNCItem
                  id={item?.value?._id}
                  keyPath={item?.keyPath?.replace("tnc.", "")}
                  buttonLabel={item?.value?.shortDescription}
                  detail={item?.value?.description}
                  acceptedTNC={acceptedTNC}
                  setAcceptedTNC={setAcceptedTNC}
                  disableDivider={true}
                />
              )
            );
          })}
          <Divider />
        </Grid>
      )}

      <Grid item xs={12}>
        <MyTypography>{t("personalInfomationRemark")}</MyTypography>
      </Grid>
    </Form>
  );
};

const Title = styled.div`
  margin-bottom: 20px;
  /* margin-left: 20px; */

  @media ${breakpoint.mobile} {
    margin-left: 10px;
  }
`;

export default PersonalInformationStatement;
