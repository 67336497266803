import styled from "styled-components";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import MyTextField from "../../components/TextField";
import MyButton from "../../components/Button";
import Space from "../../components/Space";
import withNavigate from "../../hoc/withNavigate";
import { otpLogin } from "../../apis";
import { useContext } from "react";
import { GlobalContextStore } from "../../context/GlobalProvider";
import {
  appearGlobalError,
  updateUserInfo,
} from "../../requests/globalRequest";
import { useLocation } from "react-router-dom";
import { gaLoginClick, gaLoginFail } from "../../utils/ga";
import { gtmLoginClick, gtmLoginFail } from "../../utils/gtm";

const CheckEmail = ({ onResend, loginEmail, navigate }) => {
  const { t } = useTranslation("login");
  const { globalDispatch } = useContext(GlobalContextStore);

  const location = useLocation();

  const formik = useFormik({
    initialValues: { oneTimePassword: "" },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      oneTimePassword: Yup.string().required(t("errorInvalidInput")),
    }),
    onSubmit: async (payload) => {
      console.log("submit otp", payload);

      gaLoginClick();
      gtmLoginClick();

      try {
        const res = await otpLogin({
          email: loginEmail,
          otp: payload.oneTimePassword,
        });

        if (res && res.msg === "success") {
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          await updateUserInfo(globalDispatch);
          
          navigate({
            pathname: location?.state?.from?.pathname || "/",
            state: { from: "/login" },
            search: location?.state?.from?.search || "",
          })
        } else {
          throw {
            code: res.result.code,
            error: res.result.errorMessage,
          };
        }
      } catch (e) {
        console.log("login error - ", e?.code);
        if (e?.code === "INVALID_OTP") {
          formik.setErrors({ oneTimePassword: t("errorInvalidInput") });
        } else if(e?.code === "OTP_EXPIRED"){
          formik.setErrors({ oneTimePassword: t("errorResendOtp") });
        } else {
          appearGlobalError(globalDispatch, e?.code, e?.errorMessage, {
            type: "fail",
          });
        }

        gaLoginFail();
        gtmLoginFail();
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <MyTextField
          id="oneTimePassword"
          placeholder={t("otpPlaceholder")}
          title={t("oneTimePassword")}
        />
        <Space size="22px" />
        <MyButton
          onClick={onResend}
          type="secondary"
          size={"medium"}
          fullWidth
          fontSize={16}
        >
          {t("resendOtp")}
        </MyButton>
        <Space size="22px" />
        <MyButton
          onClick={() => formik.handleSubmit()}
          size={"medium"}
          fullWidth
          fontSize={16}
        >
          {t("continue")}
        </MyButton>
      </Form>
    </FormikProvider>
  );
};

export default withNavigate(CheckEmail);
