import styled from "styled-components";
import MyTypography from "../../components/Typography";
import PlanCard from "../../components/ProductCard/PlanCard";
import DeviceCard from "../../components/ProductCard/DeviceCard";
import { useNavigate } from "react-router-dom";
import Space from "../../components/Space";
import { breakpoint } from "../../utils/responsive";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const SuggestItem = ({ data }) => {
  const { t } = useTranslation("shoppingCart");
  let navigate = useNavigate();

  return (
    <Container>
      <MyTypography
        fontSize="32"
        bold
        textAlign="center"
        style={{ marginBottom: 30, padding: '0 30px' }}
      >
        {t("needAnythingElse")}
      </MyTypography>
      <Grid container justifyContent="center" spacing={7}>
        {data &&
          data.map((item) => {
            if (item?.itemType === "plan") {
              return (
                <PlanCardContainer item>
                  {/* <PlanCard
                    type="animation"
                    data={item}
                    handleClick={() => navigate(`/mobile/${item?._id}`)}
                  /> */}
                </PlanCardContainer>
              );
            } else {
              return (
                <PlanCardContainer item>
                  <DeviceCard
                    data={item}
                    handleClick={() => navigate(`/device/${item?._id}`)}
                  />
                </PlanCardContainer>
              );
            }
          })}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f7f7f7;
  padding: 50px 0px 100px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PlanCardContainer = styled(Grid)`
  @media ${breakpoint.mobile} {
    && {
      margin-bottom: 30px;
    }
  }
`;

export default SuggestItem;
