import OrderItem from "./OrderItem";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { repurchaseByOrderId } from "../../../apis";
import { useNavigate } from "react-router-dom";
import { appearGlobalError } from "../../../requests/globalRequest";
import { useTranslation } from "react-i18next";
import { GetWord } from "../../../utils/common";
import EmptyOrderHistory from "./EmptyOrderHistory";
import { breakpoint } from "../../../utils/responsive";

const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;

    @media ${breakpoint.mobile} {
        padding: 0 30px;
    }
`

const OrderInfo = (props) => {
    const { data=[] } = props;

    const { t } = useTranslation(['profile', 'common']);

    const navigate = useNavigate();

    const handlePayAgain = async (orderId) => {
        // console.log('==onClick pay again button', orderId)
        const res = await repurchaseByOrderId({ orderId: orderId })
        // console.log('==payAgain res', res)
        if (res && res?.msg === 'success') {
            if (res?.cartErrors && res.cartErrors.length > 0) {
                const errorMessage = res?.cartErrors?.map((item) => GetWord(item?.errorMessage)).join(', ')
                props?.globalDispatch && appearGlobalError(
                    props.globalDispatch,
                    t('common:theFollowingCannotAddToCart'),
                    errorMessage,
                    { type: "fail" },
                )
            }
            if (res?.data?.items && res.data?.items.length > 0) return navigate("/shoppingcart")
        } else {
            props?.globalDispatch && appearGlobalError(
                props.globalDispatch,
                res?.result?.code, 
				res?.result?.errorMessage, 
				{ type: "fail" },
            )
        }
    }

    if (data.length === 0) return (
        <EmptyOrderHistory />
    )
    else return (
        <OrderContainer>
            { [...data].reverse().map((item, index) => {
                const finalOrderIndex = data.length - index;
                return (
                    <div>
                        <OrderItem key={`myOrder-${finalOrderIndex}`} data={item} index={finalOrderIndex} defaultExpand={index === 0} onClickPayAgain={handlePayAgain} />
                    </div>
                )
            })}
        </OrderContainer>
    )
}

export default OrderInfo;