import styled from "styled-components";
import IconSmallWarn from "../../assets/ic-smallWarn.svg";
import MyTypography from "../Typography";
import Space from "../Space";

const WarnText = ({ label }) => {
  return (
    <Container>
      <img src={IconSmallWarn} />
      <Space width="5px" />
      <MyTypography>{label}</MyTypography>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

export default WarnText;
