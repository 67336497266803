import styled from "styled-components";
import Option from "../../components/Option";
import Typography from "../../components/Typography";
import StepTitle from "../../components/Plan/StepTitle";
import Space from "../../components/Space";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import Divider from "@mui/material/Divider";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { breakpoint, WindowSize } from "../../utils/responsive";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media ${breakpoint.desktop} {
    width: 100%;
    margin-top: 15px;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    margin-left: -60px;
    margin-top: 25px;
  }
`;

const Field = ({ title, id, options, stepId, steps, onTitleClick, multiple, isRequired }) => {
  const { t, i18n } = useTranslation("common");
  const isMobile = WindowSize() === "mobile";
  const [field, meta, helpers] = useField(id);
  const currentStep = _.find(steps, ["id", stepId]);

  const onOptionClick = (val) => {
    if (multiple) {
      if (field.value.includes(val)) {
        const newValue = field.value.filter((o) => o !== val);
        return helpers.setValue(newValue);
      }
      helpers.setValue([...field.value, val]);
    } else {
      helpers.setValue(val);
    }
  };

  return (
    <div>
      <StepTitle
        title={title}
        stepId={stepId}
        onClick={onTitleClick}
        currentStep={currentStep}
      />
      {currentStep?.isActive && (
        <div style={{ display: "flex", marginLeft: isMobile ? 50 : 70, marginRight: isMobile ? 20 : 100 }}>
          <Divider orientation="vertical" flexItem />
          <Space width="40px" />

          <Content>
            <Grid container direction="row" spacing={2}>
              {options && options.map((item) => {
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <Option
                      onClick={onOptionClick}
                      selected={
                        multiple
                          ? field.value?.includes(item?._id)
                          : item?._id === field.value
                      }
                      id={item?._id}
                      label={item?.promotionTitle?.[i18n.language]}
                      isRecommend={item.recommend}
                      price={item.discountedPrice}
                      priceUnit="M"
                      originalPrice={item.originalPrice}
                      detail={item?.detail}
                      CheckboxProps={{
                        circle: true,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              <Button
                color="black"
                style={{ width: isMobile ? "100%" : 200 }}
                onClick={() => onTitleClick(stepId + 1, isRequired, field.value)}
              >
                {t("nextStep")}
              </Button>
            </div>
          </Content>
        </div>
      )}
    </div>
  );
};

export default Field;
