import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const META_DATA = {
    title: {
        en: "1O1O Corporate Employee Solutions eShop",
        zh: "1O1O 企業員工計劃網上商店"
    },
    description: {
        en: "Shop now to enjoy special discounts exclusively for 1O1O’s corporate employee customers and experience secure payment options with PCI certification, ensuring the protection of your transactions. Plus, enjoy free door-to-door delivery on purchases over HK$1,000.",
        zh: "立即選購多項 1O1O 為企業員工客戶特選的人氣產品優惠，並體驗具有 PCI 認證的安全付款選項，確保您的交易受到保護。此外，購買滿 HK$1,000 還可免本地運費。"
    },
    ogImage: {
        en: "1o1o-corporate-employee-solutions-eshop-logo-en.png",
        zh: "1o1o-corporate-employee-solutions-eshop-logo-tc.png"
    },
    ogDescription: {
        en: "Shop now to enjoy special discounts exclusively for 1O1O’s corporate employee customers and experience secure payment options with PCI certification, ensuring the protection of your transactions. Plus, enjoy free door-to-door delivery on purchases over HK$1,000.",
        zh: "立即選購多項 1O1O 為企業員工客戶特選的人氣產品優惠，並體驗具有 PCI 認證的安全付款選項，確保您的交易受到保護。此外，購買滿 HK$1,000 還可免本地運費。"
    },
    ogTitle: {
        en: "Discover Exclusive Online Store Offers｜1O1O Corporate Employee Solutions eShop (eshop.1010corporate.com)",
        zh: "探索網店限定優惠｜1O1O 企業員工計劃網上商店(eshop.1010corporate.com)"
    }
}

const Metadata = (props) => {

    const { i18n } = useTranslation();
    const lang = i18n.language || "en";
    const langUrl = lang === "en" ? "en/" : "tc/";

    return (
        <Helmet htmlAttributes={{ lang: lang === "zh" ? "zh-Hant" : lang }}>
            <title>{META_DATA.title?.[lang]}</title>
            <meta name="title" content={META_DATA.title?.[lang]} />
            <meta name="description" content={META_DATA.description?.[lang]} />
            <meta property="og:url" content={`${BASE_URL}${langUrl}`} />
            <meta property="og:image" content={`${BASE_URL}${META_DATA.ogImage?.[lang]}`} />
            <meta property="og:description" content={META_DATA.ogDescription?.[lang]} />
            <meta property="og:title" content={META_DATA.ogTitle?.[lang]} />
        </Helmet>
    )
}

export default Metadata;