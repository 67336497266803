import styled from "styled-components";
import Coupon from "./Coupon";
import OrderItem from "./orderList/OrderItems";
import OrderListForm from "./orderList/OrderListForm";
import TotalPriceBar from "../../components/BottomBar/TotalPriceBar";
import SpecialOrder from "../profile/orderInfo/SpecialOrder";
import { updateCoupon } from "../../apis";

const OrderList = ({ data, step, setStep, refetch, setIsPreview, autoSave, setAutoSave }) => {
  return (
    <OrderListForm
      data={data}
      step={step}
      setStep={setStep}
      refetch={refetch}
      setIsPreview={setIsPreview}
      autoSave={autoSave}
      setAutoSave={setAutoSave}
    >
      <Container>
        <OrderItem />
        <Coupon data={data} refetch={refetch} />
      </Container>
    </OrderListForm>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default OrderList;
