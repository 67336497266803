import { useState, useEffect, useContext } from "react";
import CheckboxGroup from "../../../components/CheckboxGroup";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import theme from "../../../theme";
import styled from "styled-components";
import { breakpoint, WindowSize } from "../../../utils/responsive";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik, useField } from "formik";
import * as Yup from "yup";
import { getMobileNumYUP, getOnlyChineseEnglishStringYUP, getOnlyEnglishStringYUP } from "../../../utils/common";
import LoadingIndicator from "../../../components/LoadingIndicator";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 500px;

    @media ${breakpoint.mobile} {
        flex-direction: column;
        gap: 35px;
        width: 100%;
    }
`

const validationSchema = Yup.object().shape({
    // title: Yup.string().required('error:required'),
    engFullName: getOnlyEnglishStringYUP(),
    // chiFullName: getOnlyChineseEnglishStringYUP(),
    mobile: getMobileNumYUP(),
    email: Yup.string().email('error:invalidEmail').required('error:required'),
    reEnterEmail: Yup.string().oneOf([Yup.ref('email'), null], 'error:emailMustMatch').required('error:required'),
});

const getInitialValues = (data) => {
    return {
        // title: data.title,
        engFullName: data.name.en,
        // chiFullName: data.name.zh,
        email: data.email,
        mobile: data.mobile,
        reEnterEmail: data.email,
        // isMain: data.isMain ? [PermissionOptions[0]] : [],
    }
}

const getDirtyFieldName = (data, initialValues) => {
    const keyValues = Object.keys(data);
    return keyValues.filter((keyValue) => data[keyValue] !== initialValues[keyValue])
}

const EditContactForm = (props) => {
    const { data, setDialogIsOpen, onSubmit=null, } = props;

    const { t } = useTranslation(['profile', 'common', 'error']);
    const [dirtyEmail, setDirtyEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = WindowSize() === 'mobile';

    // const PermissionOptions = [
    //     { id: 1, label: t('accountProfile.admin'), value: true },
    // ]

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(data),
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (payload) => {
            console.log('==edit contact info', payload)

            const dirtyField = getDirtyFieldName(payload, formik.initialValues);
            if (dirtyField.length > 0) {
                const request = {
                    // title: payload.title,
                    fullName: {
                        en: payload.engFullName,
                        // zh: payload.chiFullName,
                    },
                    email: payload.email,
                    mobileNumber: payload.mobile,
                }

                onSubmit && onSubmit(request);
            }
            setDialogIsOpen && setDialogIsOpen(false);
        }
    });

    useEffect(() => {
        const dirtyEmail = getDirtyFieldName(formik.values, formik.initialValues).includes('email') ? true : false;
        setDirtyEmail(dirtyEmail ? true : false);
        formik.setFieldValue('reEnterEmail', dirtyEmail ? null : formik.values.email)
    }, [formik.values.email])

    const handleOnClickSubmit = () => {
        setIsLoading(true);
        formik.handleSubmit();
    }

    if (isLoading) return <LoadingIndicator />;
    else return (
        <FormikProvider value={formik}>
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '35px' }}>
                {/* <Row style={{ gap: isMobile ? '35px' : '50px' }}>
                    <div style={{ flex: 1 }}>
                        <CheckboxGroup
                            id="title"
                            title={t('accountProfile.title')}
                            options={[
                                { id: 1, label: t('mr', { ns: 'common' }), value: 'mr' },
                                { id: 2, label: t('ms', { ns: 'common' }), value: 'mrs' },
                                { id: 3, label: t('miss', { ns: 'common' }), value: 'miss' },
                            ]}
                            // viewOnly={false}
                            row
                            // titleStyle={{ fontSize: '16px' }}
                            // labelProps={{
                            //     style: {
                            //         fontSize: '18px',
                            //         fontWeight: '500',
                            //         margin: 0,
                            //     }
                            // }}
                        />
                    </div>
                    <div>
                        <CheckboxGroup 
                            title={t('accountProfile.permission')}
                            titleStyle={{ fontSize: '16px' }}
                            labelProps={{
                                style: {
                                    fontSize: '18px',
                                    fontWeight: '500',
                                }
                            }}
                            options={[
                                { id: 1, label: t('accountProfile.admin'), value: true },
                            ]}
                            row
                            data={formik.values.isMain}
                            disabled={data.isMain}
                        />
                    </div>
                </Row> */}
                <Row style={{ justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <TextField 
                            id="engFullName"
                            title={t('accountProfile.name')}
                            // subTitle={t('accountProfile.sameAsID')}
                            required
                            labelProps={{
                                style: {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: theme.lightBlack,
                                }
                            }}
                        />
                    </div>
                    {/* <div style={{ width: '100%' }}>
                        <TextField 
                            id="chiFullName"
                            title={t('accountProfile.chiFullName')}
                            subTitle={t('accountProfile.sameAsID')}
                            required
                            labelProps={{
                                style: {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: theme.lightBlack,
                                }
                            }}
                        />
                    </div> */}
                </Row>
                <Row>
                    <div style={{ flex: 1 }}>
                        <TextField
                            id="mobile"
                            title={t('accountProfile.phoneNo')}
                            required
                            labelProps={{
                                style: {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: theme.lightBlack,
                                }
                            }}
                        />
                    </div>
                    { !isMobile && <div style={{ flex: 1 }}></div> }
                </Row>
                <div>
                    <TextField 
                        id="email"
                        title={t('accountProfile.email')}
                        required
                        labelProps={{
                            style: {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: theme.lightBlack,
                            }
                        }}
                    />
                </div>
                {/* // TODO: confirm no need reenter email logic */}
                { dirtyEmail && (
                    <div>
                        <TextField 
                            id="reEnterEmail"
                            title={t('accountProfile.reEnterEmail')}
                            required
                            labelProps={{
                                style: {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: theme.lightBlack,
                                }
                            }}
                        />
                    </div>
                )}
                <div>
                    <Button style={{ width: '100%' }} onClick={() => handleOnClickSubmit()}>{t('accountProfile.saveBtn')}</Button>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default EditContactForm;