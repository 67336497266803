import styled from "styled-components";
import Typography from "../Typography";
import Divider from "../Divider";
import theme from "../../theme";
import Step from "../Step";
import MyTextButton from "../TextButton";
import EditIcon from "../../assets/ic-edit.svg";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { ReactComponent as DoneIcon } from "../../assets/ic-done.svg";
import { breakpoint, pageMargin } from "../../utils/responsive";

const CustomDivider = styled(Divider)`
  position: absolute;
  left: 50%;
  right: 50%;
  ${props => props.isFirst && 'margin-top: 20px !important;'}
  ${props => props.isLast && 'margin-bottom: 70px !important;'}
`;

const StepTitle = ({ title, stepId, currentStep, onClick, fullWidth=false, isLastStep=false }) => {
  const { t } = useTranslation("common");

  return (
    <Container currentStep={currentStep?.isActive} fullWidth={fullWidth}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", maxWidth: "1440px" }}>
        <Timeline>
          <TimelineIcon>
            <Step disabled={false} size="40px">
              {currentStep?.isCompleted ? <DoneIcon /> : <Typography bold fontSize={24}>{stepId}</Typography>}
            </Step>
            <CustomDivider orientation="vertical" isFirst={stepId === 1} isLast={isLastStep} />
          </TimelineIcon>
          <Typography bold fontSize={22}>
            {title}
          </Typography>
        </Timeline>
        {!currentStep?.isActive && (
          <MyTextButton
            textColor="#333"
            bold
            fontSize="18"
            onClick={() => onClick(stepId)}
          >
            <img src={EditIcon} style={{ marginRight: 5 }} />
            {t("edit")}
          </MyTextButton>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.currentStep ? "#fffbde" : "#fff")};
  // height: 60px;

  padding: ${props => props.fullWidth ? '0px 100px' : '0px 50px'};
  padding-right: 100px;

  @media ${breakpoint.mobile} {
    padding: 0 ${pageMargin.mobile};
  }
`;

const Timeline = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;

const TimelineIcon = styled.div`
  margin-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export default StepTitle;
