import styled from "styled-components";
import CustomerInformation from "./form/CustomerInformation";
import ContactInformation from "./form/ContactInformation";
import DeliveryInformation from "./form/DeliveryInformation";
import PaymentMethod from "./form/PaymentMethod";
import SupportingDoc from "./form/SupportingDoc";
import PersonalInformationStatement from "./form/PersonalInformationStatement";
import UserInfoForm from "./form/UserInfoForm";
import { useState, useEffect } from "react";
import _ from "lodash"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeliveryContactInformation from "./form/DeliveryContactInformation";

const TNC_KEYPATH = [
  "tnc.savePersonalInformation",
  "tnc.directSaleThisServices",
  "tnc.directSaleMyClubHKT",
];

const prefillTnc = (data, acceptedTNC, setAcceptedTNC) => {
  data?.map((item) => {
    if (TNC_KEYPATH.includes(item.keyPath)) {
      const key = item.keyPath.replace("tnc.", "");
      let temp = acceptedTNC[key];

      if(_.isEmpty(temp) && !_.isEmpty(item?.value)){
        temp.push({ tncId: item.value._id, accepted: false });
        setAcceptedTNC((prev) => ({ ...prev, [key]: temp }));
      }

    }
  });
};

const UserInfo = ({
  step,
  setStep,
  viewOnly,
  data,
  tncData,
  configData,
  calendarData,
  refetch,
  acceptedTNC,
  setAcceptedTNC,
  autoSave,
  setAutoSave,
}) => {
  const [skipValidate, setSkipValidate] = useState(false);

  useEffect(() => {
    if (tncData) {
      prefillTnc(tncData, acceptedTNC, setAcceptedTNC);
    }
  }, []);

  const theme = createTheme({
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.MuiGrid-item': {
              paddingTop: '36px',
            },
          },
        },
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <UserInfoForm
        data={data}
        configData={configData}
        step={step}
        setStep={setStep}
        refetch={refetch}
        skipValidate={skipValidate}
        setSkipValidate={setSkipValidate}
        autoSave={autoSave}
        setAutoSave={setAutoSave}
      >
        <Container>
          <CustomerInformation viewOnly={viewOnly} />
          {/* <ContactInformation viewOnly={viewOnly} /> */}
          {/* <DeliveryInformation
            viewOnly={viewOnly}
            setSkipValidate={setSkipValidate}
            configData={configData}
            calendarData={calendarData}
            allowDelivery={data?.isAllowedDelivery}
            allowPickup={data?.isAllowedPickup}
          /> */}
          <DeliveryContactInformation
            viewOnly={viewOnly}
            setSkipValidate={setSkipValidate}
            configData={configData}
            calendarData={calendarData}
            allowDelivery={data?.isAllowedDelivery}
            allowPickup={data?.isAllowedPickup}
          />
          <PaymentMethod viewOnly={viewOnly} />
          {/* <SupportingDoc /> */}
          <PersonalInformationStatement
            data={tncData}
            acceptedTNC={acceptedTNC}
            setAcceptedTNC={setAcceptedTNC}
          />
        </Container>
      </UserInfoForm>
    </ThemeProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default UserInfo;
