import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../theme";
import { breakpoint } from "../utils/responsive";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "@fontsource/roboto-condensed/300.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";

const Text = styled.div`
  color: ${(props) =>
    props?.color ? theme?.[`${props.color}`] || props.color : theme.primary};
  text-align: ${(props) => props.textAlign};
  ${(props) => props.header && `margin-bottom: 10px;`}
  ${(props) => props.bold && `font-weight: bold;`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.breakSpaces && `white-space: break-spaces;`}
  ${(props) => props.textShadow && `text-shadow: ${props.textShadow};`}
  ${(props) => props.breakWord && `word-break: break-word;`}
  font-family: ${(props) => props.condensed ? 'Roboto Condensed' : 'Roboto'};
  
  @media ${breakpoint.mobile} {
    font-size: ${(props) =>
      props.mFontSize
        ? `${props.mFontSize}px`
        : props.fontSize
        ? `${props.fontSize}px`
        : "16px"};
  }

  @media ${breakpoint.desktop} {
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  }
`;

const MyTypography = (props) => {
  return <Text {...props} />;
};

export default MyTypography;

MyTypography.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
};
