import { useState } from "react";
import styled from "styled-components";
import MyTypography from "../../../../components/Typography";
import Space from "../../../../components/Space";
import { useTranslation } from "react-i18next";
import IconAddMnp from "../../../../assets/ic-addMnp.svg";
import IconEdit from "../../../../assets/ic-edit.svg";
import IconDelete from "../../../../assets/ic-delete.svg";
import ExistingNumberDialog from "./ExistingNumberDialog";
import { useField } from "formik";

const AddNumberBox = ({ name }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);

  const _addNumber = () => {
    return (
      <Container>
        <MyTypography fontSize="18" color="#a7a7a7">
          {t("mobileNumber")}
        </MyTypography>
        <AddButton onClick={() => setIsOpen(true)}>
          <img src={IconAddMnp} />
          <Space width="5px" />
          <MyTypography fontSize="18" bold>
            {t("add")}
          </MyTypography>
        </AddButton>
      </Container>
    );
  };

  const _editNumber = () => {
    return (
      <Container>
        <MyTypography fontSize="18">{field?.value?.number}</MyTypography>
        <div style={{ display: "flex" }}>
          <IconButton onClick={() => setIsOpen(true)}>
            <img src={IconEdit} />
          </IconButton>
          <Space width="15px" />
          <IconButton onClick={() => setIsOpen(true)}>
            <img src={IconDelete} />
          </IconButton>
        </div>
      </Container>
    );
  };

  return (
    <div>
      {field?.value?.number ? _editNumber() : _addNumber()}
      <ExistingNumberDialog isOpen={isOpen} setIsOpen={setIsOpen} name={name}/>
    </div>
  );
};

const Container = styled.div`
  border-radius: 15px;
  box-shadow: 0 0 10px 0 #c9c9c9;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default AddNumberBox;
