import { useCallback, useContext, useState, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import MyButton from "../Button";
import MyTypography from "../Typography";
import { useTranslation, Trans } from "react-i18next";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { GlobalContextStore } from "../../context/GlobalProvider";
import { acceptCookieConsent, updateCookieConsentHeight } from "../../requests/globalRequest";

const StatementItem = (props) => {
    const { id, lang, t } = props;

    const statementLink = {
        "cookieStatement": {
            "zh": "https://customerservice.pccw.com/myhkt/cookieStatement(Chi).html",
            "en": "https://customerservice.pccw.com/myhkt/cookieStatement(Eng).html"
        },
        "privacyStatement": {
            "zh": "https://hkt.com/privacy-statement?locale=zh",
            "en": "https://hkt.com/privacy-statement?locale=en"
        },
    }

    if (id) return (<Link href={statementLink?.[id]?.[lang]} target="_blank">{t(id)}</Link>)
    else return null;
}

const CookieConsent = () => {
    const { t, i18n } = useTranslation("cookieConsent");

    const { globalState, globalDispatch } = useContext(GlobalContextStore);
    const consentRef = useRef(null);

    const isShow = globalState?.showCookieConsent || false;
    const isMobile = WindowSize() === "mobile";

    useEffect(() => {
        if (consentRef.current && isShow && isMobile) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const consentHeight = entry.target.offsetHeight;
                    // console.log("consentHeight", consentHeight)
                    updateCookieConsentHeight(globalDispatch, consentHeight);
                }
            });
    
            resizeObserver.observe(consentRef.current);
    
            return () => resizeObserver.disconnect();
        }
    }, [isMobile]);

    const handleAcceptCookieConsent = () => {
        acceptCookieConsent(globalDispatch);
    }

    if (isShow) {
        return (
            <Container ref={consentRef}>
                <Wrapper>
                    <MyTypography fontSize="14">
                        <Trans 
                            i18nKey="cookieConsent:description"
                            components={[<StatementItem id="cookieStatement" t={t} lang={i18n.language} />, <StatementItem id="privacyStatement" t={t} lang={i18n.language} />]}
                        />
                    </MyTypography>
                    <Button
                        type={"secondary"}
                        style={{ minWidth: 150, background: "#fff" }}
                        onClick={handleAcceptCookieConsent}
                    >
                        {t('accept')}
                    </Button>
                </Wrapper>
            </Container>
        )
    } else return null;
}

const Container = styled.div`
    position: relative;
    /* top: 0; */
    width: 100%;
    height: fit-content;
    background-color: #efefef;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 9999;

    box-shadow: rgb(0,0,0,0.25) 0px -3px 10px;

    @media ${breakpoint.mobile} {
        box-shadow: none;
    }
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1440px;
    padding: 20px 50px;

    gap: 30px;

    @media ${breakpoint.mobile} {
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        padding: 15px;
    }
`

const Button = styled(MyButton)`
    min-width: 150px;
    background: #fff;

    @media ${breakpoint.mobile} {
        max-height: 42px;
    }
`;

const Link = styled.a`
    font-weight: bold;
    color: inherit;
    text-decoration: none;
`

export default CookieConsent;