import styled from "styled-components";
import MyTypography from "../Typography";
import RemarkIcon from "../../assets/ic-remark.svg";
import MyButton from "../Button";
import { Link } from "react-router-dom";
import Space from "../Space";
import { useTranslation } from "react-i18next";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { Tooltip } from "@mui/material";
import { formatCurrency } from "../../utils/common";

const TotalPriceBar = ({ isCheckout, onClick, totalPrice, disabled }) => {
  const { t } = useTranslation(["common", "mobile", "checkout"]);
  const isMobile = WindowSize() === "mobile";

  return (
    <Container>
      <SubContainer>
        <Title>
          <MyTypography fontSize="24" bold>
            {t("common:totalPrice")}
          </MyTypography>
          <Space width="10px" />

          <TotalPrice>
            <MyTypography fontSize="24" condensed>HK$</MyTypography>
            <MyTypography fontSize="38" bold condensed>
              {formatCurrency(totalPrice) || 0}
            </MyTypography>
          </TotalPrice>
        </Title>
        <MyButton
          style={{ height: 48, width: isMobile ? "100%" : "auto" }}
          onClick={onClick}
          disabled={disabled}
        >
          {isCheckout ? t("checkout:payment") : t("common:addToCart")}
        </MyButton>
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  height: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  box-shadow: rgb(0,0,0,0.25) 0px -3px 10px;

  display: flex;
  justify-content: center;
`;

const SubContainer = styled.div`
  padding: 0 5%;
  height: 84px;
  width: 100%;
  max-width: 1440px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoint.desktop} {
    flex-direction: row;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    padding: 30px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    width: 100%;
    justify-content: space-between;
  }
`;

const TotalPrice = styled.div`
  margin-left: 50px;
  display: flex;
  align-items: center;
`;

export default TotalPriceBar;
