import Typography from "../../../components/Typography";
import theme from "../../../theme";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as PrintIcon } from "../../../assets/ic-print.svg";
import { ResponsiveUI, WindowSize, breakpoint } from "../../../utils/responsive";
import OrderStatusField from "./OrderStatusField";
import { formatDate } from "../../../utils/common";
import MyButton from "../../../components/Button";
import { isValidForPayAgain } from "../../../utils/common";

const TitleInfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 100px;
    row-gap: 10px;
    flex-wrap: wrap;

    @media ${breakpoint.desktop} {
        width: 100%;
    }

    @media ${breakpoint.mobile} {
        row-gap: 20px;
    }
`

const TitleInfoField = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`

const CollapseSubTitleProp = (props) => {
    const { data, onClickPayAgain } = props;

    const { t } = useTranslation(['profile', 'common']);
    const isMobile = WindowSize() === 'mobile';

    const handleOnPrint = (e) => {
        e.stopPropagation();    // Prevent trigger collapse onClick event

        // TODO: onClick download SA form
        console.log('onClickPrint')
    }

    const handleOnClickPayAgain = (e) => {
        e.stopPropagation();
        onClickPayAgain && onClickPayAgain();
    }

    const DateQtyField = ({ data }) => {
        return (
            <>
                <TitleInfoField style={{ flex: 2 }}>
                    <OrderStatusField label={t('profile:orderDetails.orderNo')} value={data?.orderNo} status={data?.status} onClickPayAgain={handleOnClickPayAgain} />
                </TitleInfoField>
                <TitleInfoField>
                    <Typography style={{ fontSize: '18px', color: theme.lightBlack }}>{t('profile:orderDetails.date')}</Typography>
                    <Typography style={{ fontSize: '20px', color: theme.lightBlack, fontWeight: 'bold' }}>{formatDate(data?.date, "DD/MM/YYYY HH:mm")}</Typography>
                </TitleInfoField>
                <TitleInfoField>
                    <Typography style={{ fontSize: '18px', color: theme.lightBlack }}>{t('profile:orderDetails.quantity')}</Typography>
                    <Typography style={{ fontSize: '20px', color: theme.lightBlack, fontWeight: 'bold' }}>{data?.quantity}</Typography>
                </TitleInfoField>
            </>
        )
    }

    const PrintButton = () => {
        return (
            <TitleInfoField onClick={(e) => handleOnPrint(e)} style={{ marginTop: isMobile ? '10px' : 0 }}>
                <PrintIcon />
                <Typography fontSize={theme.fontSize.button} color={theme.lightBlack} bold style={{ width: "max-content" }}>{t('profile:orderDetails.print')}</Typography>
            </TitleInfoField>
        )
    }

    return (
        <TitleInfoContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px', flexWrap: 'wrap' }}>
                <DateQtyField data={data} />
            </div>
            {isValidForPayAgain(data?.status) && (
                <MyButton
                    size="medium"
                    fontSize={theme.fontSize.textButton}
                    onClick={(e) => handleOnClickPayAgain(e)}
                    fullWidth
                >
                    {t("common:payAgain")}
                </MyButton>
            )}
            {/* <PrintButton /> */}
        </TitleInfoContainer>
    )
}

export default CollapseSubTitleProp;