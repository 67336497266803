import { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PetsIcon from "@mui/icons-material/Pets";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Divider from "@mui/material/Divider";
import TextButton from "../TextButton";
import Button from "../Button";
import Typography from "../Typography";
import withNavigate from "../../hoc/withNavigate";
import theme from "../../theme";
import { Link } from "react-router-dom";
import IconShoppingCart from "../../assets/ic-shoppingCart.svg";
import IconMyAccount from "../../assets/ic-myAccount.svg";
import Logo from "../Logo";
import IconHelp from "../../assets/ic-help.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GetWord } from "../../utils/common";
import ShoppingBagZh from "../../assets/ic-1010ShoppingBag-zh.svg";
import ShoppingBagEn from "../../assets/ic-1010ShoppingBag-en.svg";
import { ReactComponent as IconMobilePlan } from "../../assets/ic-mobilePlan.svg";
import { ReactComponent as IconRecommend } from "../../assets/ic-recommend.svg";
import { ReactComponent as IconElectronicDevice } from "../../assets/ic-electronicDevice.svg";
import { ReactComponent as IconLogin } from "../../assets/ic-login.svg";
import { ReactComponent as IconLogout } from "../../assets/ic-logout.svg";
import { ReactComponent as IconMyProfile } from "../../assets/ic-myProfile.svg";
import { GlobalContextStore } from "../../context/GlobalProvider";
import _ from "lodash";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 100%;
  position: relative;
  z-index: 999;
  ${(props) => props.isFloating && `height: 126px; margin-bottom: -126px;`}
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 66px;
  width: 100%;
  box-shadow: rgb(0, 0, 0, 0.25) 0px 3px 10px 0;
  position: sticky;
  top: 0;
  background-color: #fff;
  ${(props) => props.isFloating && `margin: 40px 50px;`}
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const IconButton = styled.img`
  cursor: pointer;
  margin-left: 20px;
`;

const CustomDivider = styled(Divider)`
  && {
    margin: 0px 10px;
  }
`;

const CustomTextButton = styled(TextButton)`
  && {
    white-space: break-spaces;
  }
`;

const CustomButton = styled(Button)`
  && {
    margin-right: 20px;
    width: 200px;
    height: 45px;
  }
`;

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    borderRadius: "14px",
    boxShadow: "0 3px 10px 0 #adadad",
  },
})

function MenuBar(props) {
  const {
    handleHelp, 
    handleCart,
    handleChangeLanguage, 
    handleClickMenuItem,
    handleClickMenuParentItem,
    handleClickMenuSubItem,
    menuItem=[],
    userMenuItem=[],
  } = props;

  const { t, i18n } = useTranslation(["navBar", "home"]);

  let location = useLocation();
  const isHome = location?.pathname === "/" ? true : false;

  const [anchorLogin, setAnchorLogin] = useState(null);

  const handleCloseAllMenuItem = () => {
    menuItem.forEach((item) => {
      item?.onClose && item.onClose();
    });
    setAnchorLogin(null);
  }

  return (
    <Wrapper isFloating={isHome}>
      <Container isFloating={isHome}>
        <div style={{ flex: 2, marginLeft: 30, marginTop: 3 }}>
          <Logo 
            onClick={() => {
              handleCloseAllMenuItem();
              handleClickMenuItem({ id: 'website-logo', href: '/' })
            }} 
          />
        </div>

        <MenuContainer>
          {
            menuItem.map((parent) => {
              if (parent?.child && parent?.child?.length > 0) {
                return (
                  <>
                    <TextButton
                      key={parent?.id}
                      color={theme.lightBlack}
                      endIcon={<ExpandMoreIcon />}
                      onClick={(event) => {
                        handleCloseAllMenuItem();
                        handleClickMenuParentItem(parent)
                        parent?.onExpand && parent?.onExpand(event)
                      }}
                    >
                      {t(`topBar.${parent.label}`)}
                    </TextButton>
                    <StyledMenu
                      sx={{ mt: isHome ? "60px" : "55px" }}
                      id={`menu-${parent.id}`}
                      anchorEl={parent?.anchorElRef}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(parent?.anchorElRef)}
                      onClose={() => parent?.onClose && parent?.onClose()}
                    >
                      {parent.child.map((item) => (
                        <MenuItem 
                          key={item.id} 
                          onClick={() => {
                            handleClickMenuSubItem(parent, item);
                            parent?.onClose && parent?.onClose();
                          }}
                        >
                          <TextButton color={theme.lightBlack} startIcon={item.icon}>
                            {t(`home:${item.id}`)}
                          </TextButton>
                        </MenuItem>
                      ))}
                    </StyledMenu>
                  </>
                )
              } else {
                return (
                  <TextButton 
                    color={theme.lightBlack} 
                    onClick={() => {
                      handleCloseAllMenuItem();
                      handleClickMenuItem(parent)
                    }}
                  >
                    {t(`topBar.${parent.label}`)}
                  </TextButton>
                )
              }
            })
          }

          <CustomDivider orientation="vertical" flexItem />

          <IconButton 
            src={IconShoppingCart} 
            onClick={() => {
              handleCloseAllMenuItem();
              handleCart();
            }} 
          />

          <IconButton
            src={IconMyAccount}
            onClick={(e) => {
              handleCloseAllMenuItem();
              handleClickMenuParentItem({ id: "my-account", child: userMenuItem })
              setAnchorLogin((prev) => prev !== null ? null : e.currentTarget);
            }}
          />
          <StyledMenu
            sx={{ mt: "53px" }}
            id="menu-appbar"
            anchorEl={anchorLogin}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorLogin)}
            onClose={() => {
              setAnchorLogin(null);
            }}
          >
            {
              userMenuItem.map((item) => {
                return (
                  <MenuItem
                    key={item?.id}
                    onClick={() => {
                      setAnchorLogin(null);
                      handleClickMenuSubItem({ id: "my-account", child: userMenuItem }, item)
                    }}
                  >
                    <TextButton
                      color={theme.lightBlack}
                      startIcon={item?.icon || null}
                    >
                      {t(`topBar.${item?.label}`)}
                    </TextButton>
                  </MenuItem>
                )
              })
            }
          </StyledMenu>

          <TextButton color={theme.lightBlack} onClick={handleChangeLanguage}>
            {t("topBar.lang")}
          </TextButton>

          {
            <CustomButton
              startIcon={<img src={IconHelp} />}
              onClick={handleHelp}
            >
              {t("topBar.contactUs")}
            </CustomButton>
          }
        </MenuContainer>
      </Container>
    </Wrapper>
  );
}

export default withNavigate(MenuBar);
