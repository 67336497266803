import AlertDialog from "./AlertDialog";
import { GlobalContextStore } from "../../context/GlobalProvider";
import { useState, useContext } from "react";
import { disappearGlobalError } from "../../requests/globalRequest";
import { useTranslation } from "react-i18next";
import { GetWord } from "../../utils/common";

const GlobalErrorDialog = () => {
  const { globalState, globalDispatch } = useContext(GlobalContextStore);
  const { t } = useTranslation("common");

  return (
    <AlertDialog
      isOpen={globalState.globalError != null}
      handleClose={() => {
        globalState.globalError?.options?.onClose && globalState.globalError?.options?.onClose();
        disappearGlobalError(globalDispatch)
      }}
      title={globalState.globalError?.title || ""}
      content={typeof globalState.globalError?.message === 'object' ? GetWord(globalState.globalError?.message) : globalState.globalError?.message || ""}
      btnLabel={globalState.globalError?.options?.btnLabel ? globalState.globalError?.options?.btnLabel : t("ok")}
      type={globalState.globalError?.options?.type || "alert"}
      onClick={globalState.globalError?.options?.onClick}
    />
  );
};

export default GlobalErrorDialog;
