import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalProvider, { GlobalContextStore } from "./context/GlobalProvider";
import Routes from "./Routes";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GlobalErrorDialog from "./components/CommonDialog/GlobalErrorDialog";
import _ from "lodash";
import { initGA } from "./utils/ga";
import { initGTM } from "./utils/gtm";

import "@fontsource/roboto";
import ScrollToTop from "./utils/ScrollToTop";
import CookieConsent from "./components/CookieConsent";
import Metadata from "./components/Metadata";

const queryClient = new QueryClient();

function App() {
  /* Google Analytics & Google Tag Manager */
  useEffect(() => {
    initGA();
    initGTM();
  }, [])

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 9999,
        }}
      >
        <CookieConsent />
        <NavBar />
      </div>
      <ScrollToTop />
      <Metadata />
      <Routes/>
      <Footer />
      <GlobalErrorDialog />
    </div>
  );
}

const AppWrapper = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default AppWrapper;
