import styled from "styled-components";
import NumberInput from "../../../../components/NumberInput";
import AddNumberBox from "./AddNumberBox";
import MyTypography from "../../../../components/Typography";
import Space from "../../../../components/Space";
import ExistingNumberDialog from "./ExistingNumberDialog";
import { useState, useEffect } from "react";
import { useFormikContext, useField } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const INITIAL_PORTIN = {
  number: "",
  networkProvider: "",
  simType: "postpaid",
  effectiveDate: dayjs(Date.now()).format("DD/MM/YYYY"),
  effectiveTime: "",
};

const ExistingNumber = ({ name, idx, totalPlanNum }) => {
  const { t } = useTranslation("checkout");
  const { values } = useFormikContext();
  const [field, meta, helpers] = useField(`${name}.order_mnpNumbers`);

  useEffect(() => {
    const mnpNumbers = values?.order_list[idx]?.mnpNumbers;
    if(mnpNumbers) {
      helpers.setValue(mnpNumbers); 
    }
    if (!field.value) helpers.setValue([]);
  }, [field?.value]);

  const handleNumberOnChange = (number) => {
    if (number > field.value.length) {
      helpers.setValue([...field.value, INITIAL_PORTIN]);
    } else {
      helpers.setValue(field?.value?.slice(0, -1));
    }
  };

  return (
    <Container>
      <Quantity>
        <MyTypography bold>{t("mobilePortin")}</MyTypography>
        <NumberInput
          min={0}
          max={totalPlanNum}
          value={field?.value?.length}
          onChange={handleNumberOnChange}
        />
      </Quantity>
      {field?.value?.length > 0 && (
        <MyTypography fontSize="18">{t("mobileNumberPorted")}</MyTypography>
      )}
      <Space size="15px" />
      {field?.value?.map((item, idx) => {
        return <AddNumberBox name={`${name}.order_mnpNumbers[${idx}]`} />;
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export default ExistingNumber;
