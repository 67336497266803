import { Grid } from "@mui/material";
import Typography from "../../../components/Typography";
import theme from "../../../theme";
import Divider from '../../../components/Divider';
import { formatDate } from "../../../utils/common";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AddressSection from "./common/AddressSection";
import InfoSection from "./common/InfoSection";

const PADDING_VALUE = '38px'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 3px 8px 0 #c9c9c9;
    // padding: 30px;
    overflow: hidden;
`;

const FieldItem = (props) => {
    const { label, value, xs=2, md=2 } = props;

    return (
        <Grid item container xs={xs} md={md} gap={"11.5px"} direction={'column'}>
            <Typography color={theme.lightBlack}>{label || ``}</Typography>
            <Typography color={theme.lightBlack} fontSize={18}>{value || ``}</Typography>
        </Grid>
    )
}

const ClientInfo = (props) => {
    const { data, onAfterAction=null } = props;

    const { t } = useTranslation(['profile', "common"]);

    const handleEditMailingAddress = async (payload) => {
        // TODO
        console.log('==handleEditMailingAddress', payload)
        // onAfterAction && onAfterAction();
    }

    const handleDeleteMailingAddress = async (item) => {
        // TODO
        console.log('==handleDeleteMailingAddress', item)
        // onAfterAction && onAfterAction();
    }

    const handleEditClientInfo = async (payload) => {
        // TODO
        console.log('==handleEditClientInfo', payload)
        // onAfterAction && onAfterAction();

    }

    const handleDeleteClientInfo = async (item) => {
        // TODO
        console.log('==handleDeleteClientInfo', item)
        // onAfterAction && onAfterAction();
    }

    return (
        <Container style={{ boxShadow: theme.outerContainerShadow }}>
            <Grid container style={{ padding: PADDING_VALUE }} rowGap={'38px'} direction={{ xs: 'column', md: 'row' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>{t('profile:accountProfile.clientInfo')}</Typography>
                <InfoSection 
                    type={"clientInfo"}
                    data={[data]}
                    containerStyle={{ padding: 0 }}
                    // onEdit={handleEditClientInfo}
                    // onDelete={handleDeleteClientInfo}
                />
            </Grid>
            <Divider flexItem />
            <Grid container style={{ padding: `${PADDING_VALUE} 0` }}>
                <AddressSection 
                    data={data?.mailingAddress} 
                    title={t('profile:accountProfile.mailingAddress')} 
                    // onEdit={handleEditMailingAddress} 
                    // onDelete={handleDeleteMailingAddress} 
                />
            </Grid>
        </Container>
    )
}

export default ClientInfo;