import { useState } from "react";
import { Grid } from "@mui/material";
import ClientInfo from "./ClientInfo";
import PrepaidNumber from "./PrepaidNumber";
import AlertDialog from "../../../components/CommonDialog/AlertDialog";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import NoDocumentIcon from "../../../assets/ic-noDocument.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const getInitialValue = (data) => {
  return [
    {
      clientInfo: { 
        name: '陳大文',
        idType: 'hkid',
        idNo: 'A123456(7)',
        document: [
          {
            type: "HKIDCopy",
            uploadDate: undefined,
            status: "pending"
          },
          {
            type: "passportCopy",
            uploadDate: undefined,
            status: "reupload"
          },
        ],
      },
      prepaidNumber: {
        mobileNumber: '56655266',
        effectiveDate: Date.now(),
        document: [
          {
            type: "simProof",
            uploadDate: undefined,
            status: "lost"
          },
          {
            type: "portinForm",
            uploadDate: Date.now(),
            status: "uploaded"
          },
        ],
      },
    }
  ]
}

const PendingDocument = (props) => {
  const { data } = props;

  const { t } = useTranslation("profile");
  const [hintDialogIsOpen, setHintDialogIsOpen] = useState(true);
  const initialValue = getInitialValue(data);

  if (initialValue.length === 0) return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={6} justifyContent={'center'} style={{ padding: '100px 0' }}>
        <img src={NoDocumentIcon} alt="No Document" />
      </Grid>
    </Grid>
  )
  
  else return (
    <>
      {hintDialogIsOpen && (
        <AlertDialog
          isOpen={hintDialogIsOpen}
          handleClose={() => setHintDialogIsOpen(false)}
          title={t("pendingDocument.dialogTitle", { time: 24 })}
          titleStyle={{ textAlign: "center" }}
          content={t("pendingDocument.dialogContent")}
          btnLabel={t("title.myAccount")}
          type="upload"
        />
      )}
      <Container>
        { initialValue.map((item, index) => (
          <>
            <ClientInfo key={`clientInfo-${index}`} data={item.clientInfo} />
            <PrepaidNumber key={`prepaidNumbr-${index}`} data={item.prepaidNumber} />
          </>
        ))}
      </Container>
    </>
  );
};

export default PendingDocument;
