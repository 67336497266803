import styled from "styled-components";
import Form from "./Form";
import { Grid } from "@mui/material";
import MyTypography from "../../../components/Typography";
import TNCItem from "../tnc/TncItem";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import theme from "../../../theme";

const PreviewTermAndCondition = ({ data, acceptedTNC, setAcceptedTNC }) => {
  const { t } = useTranslation("checkout");
  const { errors } = useFormikContext();

  return (
    <Form title={t("termsAndConditions")}>
      {data?.allProductOverallTncs?.map((item) => {
        return (
          <Grid item xs>
            <TNCItem
              id={item?._id}
              keyPath="productTncs"
              title={item?.title}
              buttonLabel={item?.shortDescription}
              detail={item?.description}
              acceptedTNC={acceptedTNC}
              setAcceptedTNC={setAcceptedTNC}
              detailsTitle={item?.shortDescription}
            />
          </Grid>
        );
      })}

      {errors.accepted_tnc && (
        <Grid item xs={12}>
          <MyTypography color={theme.error2}>
            {t(errors.accepted_tnc)}
          </MyTypography>
        </Grid>
      )}
    </Form>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default PreviewTermAndCondition;
