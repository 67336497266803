import styled from "styled-components";
import { Divider, Grid } from "@mui/material";
import PlanContent from "../../../../components/OrderItems/PlanContent";
import ItemTableRow from "../../../../components/OrderItems/ItemTableRow";
import Space from "../../../../components/Space";
import Typography from "../../../../components/Typography";
import theme from "../../../../theme";
import PlanNumberComponent from "./PlanNumberContainer";
import RemarkFieldItem from "../RemarkFieldItem";
import RemarkSectionContainer from "../RemarkSectionContainer";
import { useTranslation } from "react-i18next";
import { ResponsiveUI, WindowSize, breakpoint } from "../../../../utils/responsive";

const DividerContainer = styled.div`
    display: flex;
    margin: 20px 0px 30px 0px;
    justify-content: flex-end;

    @media ${breakpoint.mobile} {
        margin: 0;
    }
`;

const PlanDetail = () => {
    const isMobile = WindowSize() === 'mobile';

    const row = ["流動電話計劃", "300M", "24個月", "1", "HK$ 415", "HK$ 415"]
    const colWidth = ["40%", "12%", "12%", "12%", "12%", "12%"]

    return (
        <div>
            <ResponsiveUI
                desktop={
                <DividerContainer>
                    <Divider style={{ width: 'calc(100% - 170px)' }} />
                </DividerContainer>
                }
                mobile={
                <>
                    <Divider />
                    <Space size="20px" />
                </>
                }
            />

            {/* TODO - change to loop ItemTableRow */}
            <div style={{ padding: isMobile && '30px 0'}}>
                <ItemTableRow row={row} colWidth={colWidth} />
                <Space size="30px" />
                <ItemTableRow row={row} colWidth={colWidth} />
            </div>
            { isMobile && (
                <DividerContainer>
                    <Divider style={{ width: "100%" }} />
                </DividerContainer>
            )}
        </div>
    )
}

const RemarkSection = (props) => {
    const { t } = props;

    const isMobile = WindowSize() === 'mobile';

    return (
        <RemarkSectionContainer>
            <PlanNumberComponent t={t} />
            { isMobile && <RemarkFieldItem label={t('orderDetails.expectedValidDateTime') } value={"04/07/2022 01:00-04:00"} /> }
            <ResponsiveUI 
                desktop={<RemarkFieldItem label={t('orderDetails.autoRenewal')} value={t('yes', {ns: 'common'})} />}
                mobile={<RemarkFieldItem value={t('orderDetails.autoRenewal')} />}
            />
            <RemarkFieldItem value={t('orderDetails.autoInflate')} noDivider={true} valueFontsize={18} />
        </RemarkSectionContainer>
    )
}

const PlanOrder = (props) => {
    const { data, index, cardBaseStyle=null } = props;

    const { t } = useTranslation(['profile', 'common']);

    //TODO - external remark
    return (
        <PlanContent
            idx={index}
            renderDetail={<PlanDetail />}
            remarkProps={<RemarkSection t={t} />}
            orderRecord={[
                { id: 'MOB1234564890', status: 'pending', phoneNumber: "98765432" },
                { id: 'MOB1234564890', status: 'pending', phoneNumber: "98765432" },
            ]}
            // iconNeedDotDecoration={true}
            cardBaseStyle={cardBaseStyle}
        />
    )
}

export default PlanOrder;