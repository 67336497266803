import { useState } from "react";
import { Divider, Typography, Grid } from "@mui/material";
import styled from "styled-components";
import theme from "../../../../theme";
import NumberBlock from "./NumberBlock";
import { breakpoint, WindowSize } from "../../../../utils/responsive";

const Container = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${breakpoint.mobile} {
        flex-direction: column;
    }
`;

const PlanNumberComponent = (props) => {
    const { data, t } = props;

    const isMobile = WindowSize() === 'mobile';

    return (
        <>
            <Container>
                <NumberBlock 
                    type="new"
                    title={t('orderDetails.newMobileNumber')}
                    description={t('orderDetails.chooseNewNumber')}
                    data={{
                        numberList: ["98765432", "98765431", "99999399"],
                        expectedDate: "04/07/2022 03:00am",
                    }}
                    viewOnly
                    t={t}
                />
                <Divider orientation={isMobile ? 'horizontal' : "vertical"} flexItem />
                <NumberBlock 
                    type="transfer"
                    title={t('orderDetails.mobilePortIn')}
                    description={t('orderDetails.portedNumber')}
                    data={{
                        numberList: ["98765432"]
                    }}
                    viewOnly
                    t={t}
                />
            </Container>
            <Divider style={{ width: "100%" }} />
        </>
    )
}

export default PlanNumberComponent;