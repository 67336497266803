import { Grid } from "@mui/material";
import Typography from "../../../components/Typography";
import Link from "../../../components/Link";
import UploadIcon from "./UploadIcon";
import styled from "styled-components";
import theme from "../../../theme";
import FileUpload from "../../../components/FileUpload";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/common";
import { breakpoint } from "../../../utils/responsive";

const BodyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media ${breakpoint.mobile} {
        flex-direction: column;
    }
`

const Container = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    
    @media ${breakpoint.desktop} {
        width: 25%;
    }

    @media ${breakpoint.mobile} {
        width: 100%;
    }
`

const FieldContainer = styled(Container)`
    @media ${breakpoint.mobile} {
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
    }
`

const OldDocumentUploadSection = (props) => {
    const { data } = props;

    const { t } = useTranslation('profile', { keyPrefix: 'pendingDocument' });

    return (
        <BodyContainer>
            <Container className="main-container">
                <Container>
                    <UploadIcon />
                    <Typography style={{ fontSize: '20px', color: '#000', fontWeight: 'bold' }}>
                        {data?.type ? t(data.type) : ""}
                    </Typography>
                    { data?.type === "portinForm" && (
                        <>
                            <Typography style={{ fontSize: '20px', color: '#000', fontWeight: 'bold' }}>{"/"}</Typography>
                            <Link href="#" style={{ fontSize: '20px', fontWeight: 'bold' }}>{`${t('download')}`}</Link>
                        </>
                    )}
                </Container>
                <Container className="field-container">
                    <FieldContainer>
                        <Typography style={{ fontSize: '18px', color: '#333' }}>{t('uploadDate')}</Typography>
                        <Typography style={{ fontSize: '18px', color: '#000', fontWeight: 500 }}>{formatDate(data?.uploadDate)}</Typography>
                    </FieldContainer>
                    <FieldContainer>
                        <Typography style={{ fontSize: '18px', color: '#333' }}>{t('status')}</Typography>
                        <Typography style={{ fontSize: '18px', color: theme.mandatory, fontWeight: 500 }}>{t(data?.status)}</Typography>
                    </FieldContainer>
                </Container>
            </Container>
            <Container>
                <FileUpload
                    accept={'image/*'}
                    multiple={true}
                    data={null}
                    disabled={["uploaded", "lost"].includes(data?.status)}
                />
            </Container>
        </BodyContainer>
    )
}

const DocumentUploadSection = (props) => {
    const { data } = props;

    const { t } = useTranslation('profile', { keyPrefix: 'pendingDocument' });

    return (
        <BodyContainer>
            <Container>
                <UploadIcon />
                <Typography style={{ fontSize: '20px', color: '#000', fontWeight: 'bold' }}>
                    {data?.type ? t(data.type) : ""}
                </Typography>
                { data?.type === "portinForm" && (
                    <>
                        <Typography style={{ fontSize: '20px', color: '#000', fontWeight: 'bold' }}>{"/"}</Typography>
                        <Link href="#" style={{ fontSize: '20px', fontWeight: 'bold' }}>{`${t('download')}`}</Link>
                    </>
                )}
            </Container>
            <Container>
                <Typography style={{ fontSize: '18px', color: '#333' }}>{t('uploadDate')}</Typography>
                <Typography style={{ fontSize: '18px', color: '#000', fontWeight: 500 }}>{formatDate(data?.uploadDate)}</Typography>
            </Container>
            <Container>
                <Typography style={{ fontSize: '18px', color: '#333' }}>{t('status')}</Typography>
                <Typography style={{ fontSize: '18px', color: theme.mandatory, fontWeight: 500 }}>{t(data?.status)}</Typography>
            </Container>
            <Container>
                <FileUpload 
                    accept={'image/*'}
                    multiple={true}
                    data={null}
                    disabled={["uploaded", "lost"].includes(data?.status)}
                />
            </Container>
        </BodyContainer>
    )
}

export default DocumentUploadSection;