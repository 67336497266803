import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  UPDATE_USER_INFO,
  SHOW_COOKIE_CONSENT,
  HIDE_COOKIE_CONSENT,
  UPDATE_COOKIE_CONSENT_HEIGHT,
} from "../actions/globalAction";
import { fetchUserInfo, getAccessToken, otpLogout } from "../apis";
import { setCookie } from "../utils/common";

export const appearGlobalError = (dispatch, title, message, options) => {
  dispatch({ type: APPEAR_GLOBAL_ERROR, title, message, options });
};

export const disappearGlobalError = (dispatch) => {
  dispatch({ type: DISAPPEAR_GLOBAL_ERROR });
};

export const updateUserInfo = async (dispatch, userInfo) => {
  try {
    const accessToken = await getAccessToken();

    if (accessToken) {
      const result = await fetchUserInfo();
      dispatch({ type: UPDATE_USER_INFO, userInfo: result?.data });
    } else {
      dispatch({ type: UPDATE_USER_INFO, userInfo: {} });
    }
  } catch (e) {
    console.log("get user info error", e);
    dispatch({ type: UPDATE_USER_INFO, userInfo: {} });
    logout();
  }
};

export const logout = async () => {
  try {
    const result = await otpLogout();

    if(result){
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      window.location = "/";
    }
  } catch (e) {
    console.log("error signing out: ", e);
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    window.location = "/";
  }
}

export const appearCookieConsent = (dispatch) => {
  dispatch({ type: SHOW_COOKIE_CONSENT });
}

export const acceptCookieConsent = (dispatch) => {
  const date = new Date();
  setCookie({ key: "cookieConsent", value: date.toString(), maxAge: 365 * 24 * 60 * 60 * 1000 });
  dispatch({ type: HIDE_COOKIE_CONSENT });
}

export const updateCookieConsentHeight = (dispatch, height) => {
  dispatch({ type: UPDATE_COOKIE_CONSENT_HEIGHT, cookieConsentHeight: height });
}