import MyTypography from "../../../components/Typography";
import Space from "../../../components/Space";
import { Divider } from "@mui/material";
import theme from "../../../theme";
import SwitchButton from "../../../components/SwitchButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DetailButton from "../../../components/Buttons/DetailButton";
import CardWrapper from "../../../components/Card/CardWrapper";
import { GetWord } from "../../../utils/common";
import _ from "lodash";
import { ResponsiveUI, breakpoint } from "../../../utils/responsive";
import { WindowSize } from "../../../utils/responsive";
import Checkbox from "../../../components/Checkbox";

const TNCItem = ({
  title,
  detail,
  buttonLabel,
  id,
  keyPath,
  acceptedTNC,
  setAcceptedTNC,
  disableDivider,
  detailsTitle=null,
}) => {
  const { t } = useTranslation("common");
  const isMobile = WindowSize() === "mobile";

  const isChecked = () => {
    const current = _.find(acceptedTNC[keyPath], ["tncId", id]);

    if (current) {
      return current.accepted;
    } else {
      return false;
    }
  };

  const handleClick = (id, keyPath) => {
    const key = keyPath.replace("tnc.", "");
    let temp = acceptedTNC[key];
    const current = _.find(temp, ["tncId", id]);

    if (current) {
      temp = temp.map((item) => {
        if (item.tncId === id) {
          return { tncId: id, accepted: !item.accepted };
        } else {
          return item;
        }
      });
    } else {
      temp.push({ tncId: id, accepted: true });
    }

    setAcceptedTNC((prev) => ({ ...prev, [key]: temp }));
  };

  return (
    <div>
      <Container>
        {title && (
          <div style={{ marginBottom: 20 }}>
            <MyTypography fontSize="20" bold>
              {GetWord(title)}
            </MyTypography>
          </div>
        )}
        {
          <ResponsiveUI
            mobile={<TrimText>{GetWord(buttonLabel)}</TrimText>}
            desktop={<div />}
          />
        }
        <ItemFooter>
          {/* <SwitchButton
            checked={isChecked()}
            title={buttonLabel && !isMobile ? GetWord(buttonLabel) : t("agree")}
            onClick={() => handleClick(id, keyPath)}
          /> */}
          <Checkbox
            id={id}
            label={buttonLabel && !isMobile ? GetWord(buttonLabel) : t("agree")}
            onClick={() => handleClick(id, keyPath)}
            checked={isChecked()}
            typographyProps={{
              style: { fontWeight: 'normal', }
            }}
          />
          <DetailButton title={detailsTitle || title} content={detail} />
        </ItemFooter>
      </Container>
      {!disableDivider && <Divider />}
    </div>
  );
};

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TrimText = styled(MyTypography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
`;

const Container = styled.div`
  padding: 0 0 30px 5px;

  @media ${breakpoint.mobile} {
    padding: 0px 10px 10px 10px;
  }
`;

export default TNCItem;
