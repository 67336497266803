import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  UPDATE_USER_INFO,
  UPDATE_INIT,
  SHOW_COOKIE_CONSENT,
  HIDE_COOKIE_CONSENT,
  UPDATE_COOKIE_CONSENT_HEIGHT,
} from "../actions/globalAction";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case APPEAR_GLOBAL_ERROR:
      return {
        ...state,
        globalError: {
          title: action.title,
          message: action.message,
          options: { ...action.options },
        },
      };
    case DISAPPEAR_GLOBAL_ERROR:
      return { ...state, globalError: null };
    case UPDATE_USER_INFO:
      return { ...state, userInfo: action.userInfo, init: action.init || true };
    case UPDATE_INIT:
      return { ...state, init: action.init };
    case SHOW_COOKIE_CONSENT:
      return { ...state, showCookieConsent: true };
    case HIDE_COOKIE_CONSENT:
      return { ...state, showCookieConsent: false };
    case UPDATE_COOKIE_CONSENT_HEIGHT:
      return { ...state, cookieConsentHeight: action.cookieConsentHeight };
    default:
      return state;
  }
};
export default GlobalReducer;
