import { useEffect, useState } from 'react';
import MuiCollapse from '@mui/material/Collapse';
import { Typography } from '@mui/material';
import styled from "styled-components";
import theme from "../theme";
import ExpandMoreIcon from '../assets/ic-expandMore.svg';
import ExpandLessIcon from '../assets/ic-expandLess.svg';

const Button = styled.div`
  border-radius: 50%;
  color: #333;
  background-color: ${theme.yellow};
  cursor: pointer;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0px;
`

const Collapse = (props) => {
    const { 
        children, 
        isOpen=false,
        title,
        titleProp,
        description,
        descriptionProp,
        titleChildrenGap='15px',
        autoCollapseBaseOnWidth=false,
        onExpand,
    } = props;

    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        if (autoCollapseBaseOnWidth) setOpen(isOpen);
    }, [autoCollapseBaseOnWidth, isOpen])

    const handleExpand = () => {
        setOpen((e) => !e)

        onExpand && onExpand();
    } 

    return (
        <>
            <HeaderContainer onClick={handleExpand}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                        { titleProp ? (typeof titleProp === 'function' ? titleProp() : titleProp)
                            : <Typography style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{title || ""}</Typography>
                        }
                    </div>
                    <Button style={{ marginLeft: "20px" }}>{<img src={open ? ExpandMoreIcon : ExpandLessIcon} alt={open ? 'Expand Less' : 'Expand More'} />}</Button>
                </div>
                { (description || descriptionProp) && (
                    description ? 
                        <div style={{ marginTop: "20px" }}><Typography style={{ fontSize: '14px', color: '#333333' }}>{description}</Typography></div>
                        : descriptionProp ? 
                            <div style={{ marginTop: "20px" }}>{typeof descriptionProp === 'function' ? descriptionProp() : descriptionProp}</div>
                            : null
                )}
            </HeaderContainer>
            <MuiCollapse in={open} {...props}>
                <div style={{ marginTop: titleChildrenGap }}>
                    {children}
                </div>
            </MuiCollapse>
        </>
    )
}

export default Collapse;